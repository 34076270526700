
import React from "react";
import { IconProps } from ".";



export const Bars: React.FC<IconProps> = ({
  className = "w-6 h-6",
  strokeWidth = "2",
  color = "#000"
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={`${strokeWidth}`}
      stroke={`${color}`}
      className={`${className}`}>
      <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
    </svg>
  )
}
