import {SET_AUTH} from "./constants";

export type AuthType = {
	isAuthenticated: boolean;
	accessToken: string | null;
}

export const initialAuthType: AuthType = {
	isAuthenticated: false,
	accessToken: null
}


export type AuthActionType =
	| { type: typeof SET_AUTH; payload: AuthType; }

export const authReducer = (state: AuthType = initialAuthType, action: AuthActionType): AuthType => {
	switch (action.type) {
		case SET_AUTH:
			return action.payload;
		default:
			return state;
	}
}
