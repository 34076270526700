import React from 'react';
import {NewsListType, NewsMinimumType} from "../../store/post";


interface News extends NewsListType {
  className?: string;
  hideCategory?: boolean;
}

export const NewsList: React.FC<(News)> = ({ slug, title, hideCategory, category_name, created_date, className }) => {
  return (
    <li className="border-b py-4">
      <a href={`/news/${slug}`} className={`flex ${className}`}>
        <div>
          {!hideCategory && (<span className="bg-grey-bg py-1 px-3 mr-3 rounded-3xl text-xs">{category_name}</span>)}
          <time className="text-xs pr-3">{created_date}</time>
        </div>
        <span>{title}</span>
      </a>
    </li>
  );
};


const newsPerPage = 3;


export const NewsHome: React.FC<{ posts: NewsMinimumType[] }> = ({posts}) => {

  return (
    <div className="w-full sm:w-2/4">
      <ul className="border-t">
        {posts.map((data, index) => (
          <NewsList
            key={index}
            hideCategory={true}
            className="flex-row"
            {...data} />
        ))}
      </ul>
    </div>
  );
};
