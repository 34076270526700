import React, {useState} from 'react';
import {SigninLayout} from "../../components/layouts/SigninLayout";
import useForm, {FormHook} from "../../hooks/useForm";
import {errorConverter, FastapiErrorT} from "../../utils/fastapi";
import {Form} from "../../components/ui/Form";
import {TextField} from "../../components/ui/inputs/TextField";
import {Button} from "../../components/ui/Button";
import {DividerWIthText} from "../../components/ui/DividerWIthText";
import {Google} from "../../components/icons/Google";
import AuthService from "../../infrastructure/api/users/auth";
import {PasswordField} from "../../components/ui/inputs/PasswordField";
import {Link} from 'react-router-dom';
import useAxios from "../../hooks/useAxios";
import {accountTypeChoice, defaultEmailSignUp, EmailSignupT} from "../../store/user";
import RadioList from "../../components/ui/inputs/Radio";
import {InputLayout} from "../../components/layouts/InputLayout";

const EmailForm: React.FC<FormHook<EmailSignupT, FastapiErrorT>> = ({
                                                                      inputValues,
                                                                      handleChange,
                                                                      handleSubmit,
                                                                      errors
                                                                    }) => {

  return (
    <Form errors={errors} handleSubmit={handleSubmit}>
      <TextField
        type="email"
        name="email"
        value={inputValues.email}
        label="メールアドレス"
        onChange={handleChange}
        required={true}
        errors={errorConverter(errors?.detail, "email")}
      />
      <PasswordField
        name="password"
        value={inputValues.password}
        label="パスワード"
        onChange={handleChange}
        required={true}
        helpText="※半角英数字、8文字以上で入力してください。"
        errors={errorConverter(errors?.detail, "password")}
      />
      <PasswordField
        name="password2"
        value={inputValues.password2}
        label="パスワード（確認用）"
        onChange={handleChange}
        required={true}
        helpText="※もう一度入力してください。"
        errors={errorConverter(errors?.detail, "password2")}
      />
      <RadioList label="登録種別" name="account_type" checkboxes={accountTypeChoice} value={inputValues.account_type}
                 handleChange={handleChange}/>
      <div className="flex justify-center">
        <InputLayout label='' errors={errorConverter(errors?.detail, 'agree')}>
          <div>
            <input
              type="checkbox"
              name="agree"
              required
              value="privacy_policy"
              checked={inputValues.agree.includes("privacy_policy")}
              onChange={handleChange}
            />
             &nbsp;
            <Link to="/privacy-policy" className="text-accent-main underline">個人情報保護方針</Link>
            &nbsp;/&nbsp;
            <Link to="" className="text-accent-main underline">利用規約</Link>に同意する。
          </div>
        </InputLayout>
      </div>
      <Button type="submit" className="p-3 bg-accent-main text-primary-text font-semibold">会員登録する</Button>
    </Form>
  )
}

const EmailSignup: React.FC = () => {
  const authService = new AuthService();
  const {
    inputValues,
    handleChange,
    errors,
    handleSubmit,
  } = useForm<EmailSignupT, FastapiErrorT>(
    defaultEmailSignUp,
    (values, setErrors) => {
      authService.emailRegistration(values)
        .then((data) => {
          // TODO: Redirect to `Thanks page`
          console.log(data);
        })
        .catch((errors) => {
          setErrors(JSON.parse(errors));
        })
    }
  );

  return (
    <SigninLayout title="会員登録">
      <EmailForm
        inputValues={inputValues}
        handleChange={handleChange}
        errors={errors}
        handleSubmit={handleSubmit}
      />
      <DividerWIthText text="また"/>
      <a href={`${process.env.REACT_APP_GATEWAY_URL}/google/login`}
         className="bg-white text-grey-text font-medium p-3 border flex items-center justify-center gap-2">
        <Google/>
        Googleで登録する
      </a>
      <div className="flex flex-col">
        <small className="text-center pt-4">※事前に「mitsubachi-fund.com」ドメインの受信を</small>
        <small className="text-center"> 許可するようご設定をお願いいたします。</small>
        <small className="text-right mt-5">既に会員の方は<Link to="/signin"
                                                               className="font-semibold text-primary-main underline">こちら</Link>からログイン</small>
      </div>

    </SigninLayout>
  );
};

export default EmailSignup;
