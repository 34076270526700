import React, {useState, useEffect, useRef} from "react";
import {convertToNumber, numberWithCommas} from "../../../utils/numbers";

interface SimulationProps {
  totalDays: number;
  percent: number;
}

export const Simulation: React.FC<SimulationProps> = ({totalDays, percent}) => {
  const [amount, setAmount] = useState<number>(0);
  const tdRefs = useRef<(HTMLTableDataCellElement | null)[]>(Array(6).fill(null));
  useEffect(() => {
    const revenueBefore = Math.round(totalDays * (amount * 10000 * (percent * 0.01) / 365));
    const revenueTax = Math.round(revenueBefore * 0.2042);
    const revenueAfter = Math.round(revenueBefore - revenueTax);
    const bankBefore = Math.round(totalDays * (amount * 10000 * (0.002 * 0.01) / 365));
    const bankTax = Math.round(bankBefore * 0.2042);
    const bankAfter = Math.round(bankBefore - bankTax);
    if (tdRefs.current[0])
      tdRefs.current[0].innerHTML = `${(bankBefore) || '0'}円`;
    if (tdRefs.current[1])
      tdRefs.current[1].innerHTML = `${numberWithCommas(revenueBefore || '0')}円`;
    if (tdRefs.current[2])
      tdRefs.current[2].innerHTML = `△${numberWithCommas(bankTax) || '0'}円`;
    if (tdRefs.current[3])
      tdRefs.current[3].innerHTML = `△${numberWithCommas(revenueTax) || '0'}円`;
    if (tdRefs.current[4])
      tdRefs.current[4].innerHTML = `${numberWithCommas(bankAfter) || '0'}円`;
    if (tdRefs.current[5])
      tdRefs.current[5].innerHTML = `${numberWithCommas(revenueAfter) || '0'}円`;

  }, [amount]);

  return (
    <div>
      <div className='mb-2 overflow-hidden'>
        <div id='simulation' className='lg:col-span-2'>
          <div className='flex bg-[#F7F8F9] rounded py-3 mb-2 px-3 flex-wrap items-end justify-end'>
            <label htmlFor='input flex items-end font-bold text-bottom'> 投資予定額
              <input
                value={numberWithCommas(amount)}
                onChange={(e) => {
                  setAmount(convertToNumber(e.target.value) || 0);
                }}
                className='mx-1 border border-gray-300 px-2 py-1 w-[120px] sm:w-[200px]'
                type='text'
                maxLength={19}
                name='input'
              />
              <span className='text-base'>万円</span>
            </label>
          </div>
          <table className='w-full border text-center bg-white border-[#8D8D8D] text-xs sm:text-base lg:text-lg'>
            <tbody>
            <tr>
              <td className='border border-gray-400 px-1 py-1.5'></td>
              <td className='bg-gray-400 text-white border border-gray-400 px-1 py-1.5'>SoukenFund</td>
              <td
                className='border border-gray-400 px-1 py-1.5'>銀行
              </td>
            </tr>
            <tr>
              <td
                className='border border-gray-400 px-1 py-1.5'>税引前収益
              </td>
              <td className='border bg-gray-200 border-gray-400 px-1 text-right py-1.5' ref={(e) => {
                tdRefs.current[0] = e;
              }}>
              </td>
              <td
                className='border border-gray-400 px-1 text-right py-1.5' ref={(e) => {
                tdRefs.current[1] = e;
              }}>
              </td>
            </tr>
            <tr>
              <td
                className='border border-gray-400 px-1 py-1.5'>△税金(源泉徴収)
              </td>
              <td className='border bg-gray-200 border-gray-400 px-1 text-right py-1.5' ref={(e) => {
                tdRefs.current[2] = e;
              }}>
              </td>
              <td
                className='border border-gray-400 px-1 text-right py-1.5' ref={(e) => {
                tdRefs.current[3] = e;
              }}>
              </td>
            </tr>
            <tr>
              <td className='border border-gray-400 px-1 py-1.5'>税引後収益</td>
              <td className='border bg-gray-200 border-gray-400 px-1 text-right text-accent-main py-1.5' ref={(e) => {
                tdRefs.current[4] = e;
              }}>
              </td>
              <td
                className='border border-gray-400 px-1 text-right py-1.5' ref={(e) => {
                tdRefs.current[5] = e;
              }}>
              </td>
            </tr>
            </tbody>
          </table>
          <ul className='mt-2 ml-3 list-disc text-sm'>
            <li><p>試算数値はあくまでもシミュレーションであり、将来の成果を約束するものではありません。</p></li>
            <li><p>銀行定期はメガバンク数行の定期預金(1年)のデータをもとに比較しています。</p></li>
            <li><p>シミュレーションの結果は概算値です。</p></li>
            <li><p>2037年12月までの税金(源泉税)には、復興特別所得税が含まれます。</p></li>
          </ul>
        </div>
      </div>
    </div>
  );
};
