import React, { FormEvent } from "react";
import { FormHook } from "../../hooks/useForm";
import { errorConverter, FastapiErrorT } from "../../utils/fastapi";
import { Form } from "./Form";
import { TextField } from "./inputs/TextField";
import { PasswordField } from "./inputs/PasswordField";
import { Button } from "./Button";
import Stepper from "./Stepper/Stepper";
import { Link } from "react-router-dom";

export type RegistrationT = {
	last_name: string;
	first_name: string;
	last_name_kana: string;
	first_name_kana: string;
	email: string;
	password: string;
	password2: string;
	type: "submit" | "validate";
}

export const REGISTRATION_INITIAL_STATE: RegistrationT = {
	last_name: "",
	first_name: "",
	last_name_kana: "",
	first_name_kana: "",
	email: "",
	password: "",
	password2: "",
	type: "validate"
}

type RegistrationFormType = {
	handleBack: () => void;
} & FormHook<RegistrationT, FastapiErrorT>

type RegistrationInputsType = {
	inputValues: RegistrationT;
	handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	errors: FastapiErrorT | undefined;
	disabled?: boolean;
}

const RegistrationInputs: React.FC<RegistrationInputsType> = (props) => {
	const { inputValues, handleChange, errors, disabled = false } = props;
	return (
		<>
			<TextField
				type="email"
				name="email"
				value={inputValues.email}
				className="p-2"
				placeholder="メール"
				label="メール"
				onChange={handleChange}
				required={true}
				disabled={true}
				errors={errorConverter(errors?.detail, "email")}
			/>
			<div className="flex gap-2 flex-col sm:flex-row">
				<TextField
					type="text"
					name="last_name"
					value={inputValues.last_name}
					className="p-2"
					placeholder="姓"
					label="姓"
					onChange={handleChange}
					required={true}
					disabled={disabled}
					errors={errorConverter(errors?.detail, "last_name")}
				/>
				<TextField
					type="text"
					name="first_name"
					value={inputValues.first_name}
					className="p-2"
					placeholder="名"
					label="名"
					onChange={handleChange}
					required={true}
					disabled={disabled}
					errors={errorConverter(errors?.detail, "first_name")}
				/>
			</div>
			<div className="flex gap-2 flex-col sm:flex-row">
				<TextField
					type="text"
					name="last_name_kana"
					value={inputValues.last_name_kana}
					className="p-2"
					placeholder="姓(カナ)"
					label="姓(カナ)"
					onChange={handleChange}
					required={true}
					disabled={disabled}
					errors={errorConverter(errors?.detail, "last_name_kana")}
				/>
				<TextField
					type="text"
					name="first_name_kana"
					value={inputValues.first_name_kana}
					className="p-2"
					placeholder="名(カナ)"
					label="名(カナ)"
					onChange={handleChange}
					required={true}
					disabled={disabled}
					errors={errorConverter(errors?.detail, "first_name_kana")}
				/>
			</div>
			<PasswordField
				name="password"
				value={inputValues.password}
				className="p-2"
				placeholder="パスワード"
				label="パスワード"
				onChange={handleChange}
				required={true}
				disabled={disabled}
				errors={errorConverter(errors?.detail, "password")}
			/>
			<PasswordField
				name="password2"
				value={inputValues.password2}
				className="p-2"
				placeholder="パスワードの確認"
				label="パスワードの確認"
				onChange={handleChange}
				required={true}
				disabled={disabled}
				errors={errorConverter(errors?.detail, "password2")}
			/>
		</>
	)
}
export const RegistrationForm: React.FC<RegistrationFormType> = (props) => {
	const { inputValues, handleChange, handleSubmit, handleClick, errors, handleBack } = props;
	return (
		<div>
			<Stepper>
				<Stepper.Steps>
					<Stepper.Step id="input" name="入力">
						<Form errors={errors} >
							<RegistrationInputs inputValues={inputValues} handleChange={handleChange} errors={errors} />
							<div className="text-right flex flex-col gap-6">
								<Button type="button" className="p-4 bg-accent-main text-primary-text font-semibold" onClick={handleClick}>確認</Button>
							</div>
						</Form>
					</Stepper.Step>
					<Stepper.Step id="submit" name="確認">
						<Form handleSubmit={handleSubmit} errors={errors} >
							<RegistrationInputs inputValues={inputValues} handleChange={handleChange} errors={errors} disabled={true} />
							<div className="text-right flex flex-col gap-6">
								<Button type="button" onClick={handleBack} className="p-4 bg-gray-200 border-gray-400 text-black font-semibold">戻る</Button>
								<Button type="submit" className="p-4 bg-accent-main text-primary-text font-semibold">登録する</Button>
							</div>
						</Form>
					</Stepper.Step>
					<Stepper.Step id="final" name="完了">
						<div className="flex flex-col gap-10">
							<div className="flex flex-col gap-2">
								<p>{inputValues.last_name} {inputValues.first_name}様</p>
								<p>GROW FUNDINGへご登録いただき誠にありがとうございます。</p>
							</div>
							<div className="text-right flex flex-col gap-6">
								<Link to="/signin" className="bg-black text-white font-medium p-4 border flex items-center justify-center gap-2">
									ログインへ移動
								</Link>
							</div>
						</div>
					</Stepper.Step>
				</Stepper.Steps>
			</Stepper>
		</div>
	)
}
