import React from "react";

type InputLayoutType = {
	label: string;
	errors?: string[];
	children: React.ReactNode;
  required?: boolean
}

export const InputLayout: React.FC<InputLayoutType> = ({ label, errors, children, required = true }) => {
	return (
		<div className="w-full relative">
			{label ? (<div className="flex items-end justify-between mb-1">
        <label className="">
          {label}
          {required ? (
          <span className="items-end text-red-500 text-xl">*</span>
        ) : null}
        </label>
      </div>) : null}
			{children}
			<ul className="text-red-500 text-sm">
				{errors?.map((val, i) => (
					<li key={i}>{val}</li>
				))}
			</ul>
		</div>
	)
}
