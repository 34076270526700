import React from "react";
import { IconProps } from ".";


export const LinkIcon: React.FC<IconProps> = ({
  className = "w-6 h-6",
  strokeWidth = "1.5",
  stroke = "#000",
  fill = "#000000"
}) => (
  <svg xmlns="http://www.w3.org/2000/svg"
    fill={`${fill}`}
    viewBox="0 0 24 24"
    strokeWidth={`${strokeWidth}`}
    stroke={`${stroke}`}
    className={`${className}`}>
   <path d="M21 13v10h-21v-19h12v2h-10v15h17v-8h2zm3-12h-10.988l4.035 4-6.977 7.07 2.828 2.828 6.977-7.07 4.125 4.172v-11z"/>
  </svg>

)
