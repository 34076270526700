import React, {useEffect, useRef} from 'react';
import { Wrapper } from "@googlemaps/react-wrapper";
import {GoogleMapType} from "../../../store/fund";
import { Status } from "@googlemaps/react-wrapper";

export const renderMap = (status: Status): React.ReactElement => {
  if (status === Status.FAILURE) return <>Error</>;
  return <>Loading</>;
};

interface GoogleMapProps {
  width: string;
  height: string;
  googleData: GoogleMapType;
}

const GoogleMapComponent: React.FC<GoogleMapProps> = ({
  width,
  height,
  googleData
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const map = useRef<google.maps.Map | null>(null);
  const marker = useRef<google.maps.Marker | null>(null);

  useEffect(() => {
    if (ref.current) {
      map.current = new window.google.maps.Map(ref.current, {
        streetViewControl: false,
        mapTypeControl: false,
        center: googleData.center,
        zoom: googleData.zoom,
      });
      marker.current = new window.google.maps.Marker({
        map: map.current,
        position: googleData.center,
      });
    }
  }, []);
  console.log("map");
  return (
    <div ref={ref} id="map" style={{width: width, height: height}}/>
  );
};

export default function GoogleMap(props: GoogleMapProps) {
  return (
    <Wrapper apiKey="AIzaSyD_twS9lHZSXoPvga-N1rHMO4qa_UkSN08" render={renderMap}>
      <GoogleMapComponent {...props} />
    </Wrapper>
  )
};
