import React from 'react';
import { Link } from 'react-router-dom';
import { PostListType } from '../../../store/post';
import noImage from '../../../assets/noimage.jpg'

interface ArticleProps extends PostListType {
  fundName?: string;
  index?: number;
  totalPosts?: number;
}

export const Article: React.FC<ArticleProps> = ({
  title,
  excerpt,
  slug = '',
  created_date,
  category_name,
  fundName,
  media_url = noImage,
  index = 0,
  totalPosts = 1
}) => {
  const isLastPost = index >= totalPosts - 2;
  const borderClass = index % 2 === 0 ? 'sm:border-r sm:pr-5' : 'sm:pl-5';
  const maxLength = 43; // Maximum number of characters
  const truncatedTitle = title ? (title.length > maxLength ? title.substring(0, maxLength) + '...' : title) : '';

  return (
    <li className={`bg-white py-3 overflow-hidden ${isLastPost ? 'border-b-2' : ''}`}>
      <div className={borderClass}>
        {category_name ? (<span className="bg-grey-bg p-2 rounded-3xl text-xs ">{category_name}</span>) : null}
        {fundName ? (<span className="p-2 rounded-3xl text-xs ">{fundName}</span>) : null}
        <Link to={slug}>
          <h3 className="py-2 text-base font-semibold">{truncatedTitle}</h3>
        </Link>
        <div className="flex w-full flex-cols xs:flex-row">
          <figure className="w-2/6 h-28 border">
            <img className="w-full h-28 object-cover float-left" src={media_url} alt={title} />
          </figure>
          <div className="w-3/5 col-span-2 flex flex-col justify-between text-xs  ml-3">
            <p className="w-full">{excerpt}</p>
            <span className="text-right mt-2">{created_date}</span>
          </div>
        </div>
      </div>
    </li>
  );
};





