import React from "react";
import { HeaderLayout } from '../components/ui/HeaderLayout';
import { Breadcrumb } from '../components/ui/Breadcurmb';
import { ContentLayout } from "../components/layouts/ContentLayout";
import { ContainerLayout } from "../components/layouts/ContainerLayout";
import { MainContainerLayout } from "../components/layouts/MainContainerLayout";


const breadcrumbItems = [
  { url: '/', name: 'HOME', index: 0, isLast: false },
  { url: '/business-manager', name: '業務管理者名簿', index: 1, isLast: true },
];

type TableProps = {
  data: {
    title: string | React.ReactElement,
    desc: string | React.ReactNode,
    info?: string
  }[];
  className?: string;
};

const Table: React.FC<TableProps> = ({ data, className = "text-grey-text" }) => {
  return (
    <table className={`${className}`}>
      <tbody>
        {data.map((item, key) => (
          <tr key={key} className="justify-items-start border-b border-grey-boarder">
            <th className="font-normal text-start px-3 py-1 self-start w-2/4">{item.title}</th>
            <td className=" px-3 py-1 w-2/4">
              {typeof item.desc === "string" ? <p>{item.desc}</p> : item.desc}
            </td>
          </tr>
        ))}
      </tbody>
    </table >
  )
}


const TableData = [
  { title: "氏名", desc: "山田　太郎（2人目）" },
  { title: "住所", desc: "千葉県千葉市中央区末広" },
  { title: "宅地建物取引士登録番号／登録年月日", desc: "千葉県知事 第xxxxxx号 / xxxx年xx月xx日" },
  { title: "主務大臣が指定する講習を修了したこと 又は登録証明事業による証明を受けていることを示す事項", desc: "登録証明事業：不動産証券化協会認定マスター 認定者番号：Mxxxxxxx" },
  { title: "事務所の業務管理者となった年月日", desc: "xxxx年xx月xx日" },
  { title: "事務所の業務管理者でなくなった年月日", desc: "-" }
]

export const BusinessManager: React.FC = () => {
  return (
    <MainContainerLayout>
      <HeaderLayout
        title="業務管理者名簿"
        subTitle="business managers"
      />
      <Breadcrumb
        items={breadcrumbItems}
      />
      <ContainerLayout>
        <ContentLayout>
          <Table data={TableData} />
        </ContentLayout>
      </ContainerLayout>
      <ContainerLayout>
        <ContentLayout>
          <Table data={TableData} />
        </ContentLayout>
      </ContainerLayout>
    </MainContainerLayout>
  )
}