export function getPaginationItems(
  currentPage: number,
  lastPage: number,
  maxLength: number
) {
  const res: Array<number> = [];


  const firstPage = 1;
  const confirmedPagesCount = 2;
  // handle ellipsis in the middle
  if (
    currentPage >= confirmedPagesCount && currentPage - confirmedPagesCount < 2 && maxLength < lastPage
  ) {
    res.push(1);
    res.push(NaN);

    for (
      let l = currentPage - confirmedPagesCount;
      l < currentPage;
      l++
    ) {
      res.push(l);
    }
  } else if (Math.ceil(lastPage / 2) <= currentPage && currentPage - confirmedPagesCount == 2) {
    res.push(1);
    res.push(NaN);
  }
  else {
    for (let i = 1; i < currentPage; i++) {
      res.push(i)
    }
  }

  if (
    lastPage > maxLength
  ) {
    let c = currentPage;
    while (true) {
      res.push(c);
      c++;
      if (res.length > maxLength - 2) break;
    }
    res.push(NaN);
    res.push(lastPage);
  }
  else {
    for (let i = currentPage; i <= lastPage; i++) {
      res.push(i)
    }
  }
  console.log(res)
  return res;
}
