import React from 'react';
import {Link} from "react-router-dom";

type TableProps = {
  data: {
    title: string
    desc: string
    info?: string
  }[];
  className?: string;
};


export const TableLayout: React.FC<TableProps> = ({data, className = ''}) => {
  return (
    <table className={`${className}`}>
      <tbody>
      {data.map((item, key) => (
        <tr key={key} className="grid border sx:grid-cols-2 md:grid-cols-3 gap-0 ">
          <th
            className="bg-grey-bg font-normal text-start w-4/5 xs:w-1/2 md:w-full h-full px-3 py-1 md:py-2 self-start">{item.title}</th>
          <td className="md:border-l px-3 py-2 md:col-span-2 self-start w-full">
            {item.desc ? (
              <div dangerouslySetInnerHTML={{__html: item.desc}}></div>
            ) : null}
          </td>
        </tr>
      ))}
      </tbody>
    </table>
  );
};

export type TableLayoutProp = {
  name?: string
  title?: string;
  className?: string;
  link?: string;
  border?: string;
  children?: React.ReactNode
}
export const TableData: React.FC<TableLayoutProp> = ({name, border, children}) => {
  return (
    <tr
      className={`${border} border border-b-0 flex flex-row items-start last:border-b items-stretch`}>
      <th
        className={`${border} border-r border-b font-normal tracking-widest sm:border-b-0 px-2 text-start w-28 sm:w-44 py-3 bg-grey-bg text-sm sm:text-base`}>{name}</th>
      <td className='flex-1 px-2 text-base text-grey-text py-3 font-normal leading-normal'>
        {children}
      </td>
    </tr>
  );
};
export const TableHeader: React.FC<TableLayoutProp> = ({title, link = '', className, children}) => {
  return (
    <React.Fragment>
      <div className={`${className} flex justify-between items-center`}>
        <h2 className='py-4 text-2xl font-bold'>{title}</h2>
        {link &&
            <Link to={link}
                  className='flex items-center justify-center rounded border px-2 text-sm font-bold text-primary-dark border-primary-main h-[35px] text-base w-[80px] rounded max-[90px]:w-full'>
                変更
            </Link>
        }
      </div>
      <table className='w-full bg-white'>
        <tbody>
        {children}
        </tbody>
      </table>
    </React.Fragment>
  );
};
