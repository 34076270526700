import React from "react";
import { Close } from "../icons/Close";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  children?: React.ReactNode;
  width?: string;
};

const Popup: React.FC<Props> = ({ isOpen, onClose, width, children }) => {
  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 z-50 flex justify-center items-center">
          <div className="fixed inset-0 bg-black opacity-50"></div>
          <div className={`${width} absolute z-10 flex flex-col items-end`}>
            <button
              className="relative top-9 right-4 text-gray-400 hover:text-gray-500"
              onClick={onClose}
            >
              <Close />
            </button>
            {children}
          </div>
        </div>
      )}
    </>
  );
};

export default Popup;
