import { ReactNode, useEffect, useRef} from 'react';
import {ContentLayout} from './ContentLayout';
import {useLocation, useNavigate} from "react-router-dom";

type TabProps = {
	tabs: string[];
	children: ReactNode[];
};

const Tab = ({tabs, children}: TabProps) => {
	const { hash } = useLocation();
	const activeTab = parseInt(hash.slice(1));
	const navigate = useNavigate();

	const tabListRef = useRef<HTMLUListElement>(null);
	useEffect(() => {
		if (tabListRef.current) {
			const tabList = tabListRef.current;
			const activeButton = tabList.children[activeTab || 0] as HTMLElement;
			if (activeButton) {
				const containerWidth = tabList.clientWidth;
				const buttonWidth = activeButton.offsetWidth;
				const buttonLeft = activeButton.offsetLeft;
				const buttonRight = buttonLeft + buttonWidth;

				if (buttonLeft < tabList.scrollLeft || buttonRight > tabList.scrollLeft + containerWidth) {
					tabList.scrollTo({
						left: buttonLeft - (containerWidth - buttonWidth) / 2,
						behavior: 'smooth',
					});
				}
			}
		}
	}, [activeTab]);

	const handleButtonClick = (index: number) => {
		navigate(`#${index}`);
		if (tabListRef.current) {
			const tabList = tabListRef.current;
			const activeButton = tabList.children[index] as HTMLElement;
			if (activeButton) {
				const containerWidth = tabList.clientWidth;
				const buttonWidth = activeButton.offsetWidth;
				const buttonLeft = activeButton.offsetLeft;

				tabList.scrollTo({
					left: buttonLeft - (containerWidth - buttonWidth) / 2,
					behavior: 'smooth',
				});
			}
		}
	};

  return (
    <div className="w-full">
      <div className="border-b-4 border-primary-light h-14">
        <div className="container mx-auto xl:w-[1280px]">
          <div className="flex">
            <ul ref={tabListRef} className="flex w-full overflow-x-scroll no-scrollbar h-full">
              {tabs.map((tab, index) => (
                <li key={index} className="flex-shrink-0">
                  <button
                    className={`${(activeTab || 0) === index ? "relative text-secondary-light border-b-4 border-primary-main w-[165px]" : 'text-grey-boarder'
                      } py-4 font-bold text-lg leading-5 focus:outline-none w-[165px]`}
                    onClick={() => handleButtonClick(index)}
                  >
                    {tab}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <ContentLayout className="container xl:w-[1280px]">
        {children[activeTab || 0]}
      </ContentLayout>
    </div>
  );
};

export default Tab;
