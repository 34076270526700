import React from "react";
import { InputLayout } from "../../layouts/InputLayout";
import { TextInputType } from "./types";

export const TextField: React.FC<TextInputType> = ({
	name,
	value,
	type = "text",
	className = "",
	label = "",
	errors = [],
	placeholder = "",
	onChange = () => { },
	required = false,
	...props
}) => {
	return (
		<InputLayout label={label} errors={errors}>
			<input
				type={type}
				name={name}
				value={value || ''}
				onChange={onChange}
				id={`id_${name}`}
				placeholder={placeholder}
				required={required}
				className={(errors?.length > 0 ? "border-error " : "") + "w-full border border-grey-boarder p-2 rounded " + className}
				{...props}
			/>
		</InputLayout>
	)
}
