
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom'
import { Breadcrumb } from '../../components/ui/Breadcurmb';
import { ContentLayout } from "../../components/layouts/ContentLayout";
import { ContainerLayout } from "../../components/layouts/ContainerLayout";
import NoImage from '../../assets/noimage.jpg'
import { CampaignSidebar } from './campaignSidebar';
import { PromotionDetailType } from '../../store/fund';
import FundService from '../../infrastructure/api/funds/funds';
import { OutputData } from "../../store/editor";
import { editorJSParser } from '../../utils/editor';
import useAxios from "../../hooks/useAxios";



const breadcrumbItems = [
  { url: '/', name: 'HOME', index: 0, isLast: false },
  { url: '/campaign', name: '里帰りインフォメーション', index: 1, isLast: false },
  { url: '', name: 'みつばちファンド1周年記念！100万円以上の出資で《Amazonギフト券5, 000円分》プレゼント', index: 2, isLast: true },
];

interface CampaignLinkProp {
  slug: string;
}

interface CampaignSingleProps extends PromotionDetailType {
  children?: React.ReactNode;
  content?: OutputData;
}

const CampaignLink: React.FC<CampaignLinkProp> = ({
  slug,
}) => {
  return (
    <div className="flex flex-col justify-center items-center pt-20 pb-5">
      <small>＼最短10分で登録できる／</small>
      <Link
        to={slug}
        className="px-10 py-3 border border-grey-boarder rounded text-center text-2xl font-semibold" >会員登録はこちらから</Link>
    </div>
  );
};


const Details: React.FC<{ content: OutputData }> = ({ content }) => {
  const htmlArray: string[] = editorJSParser.parse(content);
  return (
    <div className="py-5 border-b-4 border-grey-boarder">
      {
        htmlArray.map((val, index) => (
          <div key={index} dangerouslySetInnerHTML={{ __html: val }} className="my-7" />
        ))
      }
      <CampaignLink slug="" />
    </div>
  );
};

const CampaignDetails: React.FC<CampaignSingleProps> = ({
  title,
  media_url = NoImage,
  status,
  content,
  start,

}) => {
  return (
    <div className="my-5 sm:my-0">
      {status ? (<span className=" text-sm">{status}</span>) : null}
      <div className="flex border-b-4 border-grey-boarder pb-5 mt-1">
        <img src={media_url} alt={title} className="h-28 w-28 object-cover" />
        <div className="w-full ml-4 flex flex-col justify-between">
          <h2 className="mb-1 text-2xl font-bold">{title}</h2>
          {start ? (
            <time className=" text-sm text-right items-end">開催日｜{start}</time>
          ) : null}
        </div>
      </div>
      <div className="py-5 border-b-4 border-grey-boarder">
        {content && <Details content={content} />}
      </div>
    </div>
  );
}

export const CampaignDetail: React.FC = () => {
  const { pk } = useParams();
  const [currentPost, setCurrentPost] = useState<PromotionDetailType | undefined>();
  const fundService = new FundService(useAxios());
  useEffect(() => {
    if (pk) {
      fundService
        .getPromotion(Number(pk))
        .then((data) => {
          setCurrentPost(data);
        })
        .catch(() => {
          // TODO: redirect to 404 page
        });
    }
  }, [pk]);
  return (
    <div className="mt-20">
      <Breadcrumb items={breadcrumbItems} />
      <ContainerLayout>
        <ContentLayout className="grid grid-cols-1 auto-cols-max sm:grid-cols-2 sm:gap-8 md:gap-12 md:grid-flow-col lg:gap-18">
          <div className="col-span-10">
            <CampaignDetails {...currentPost} />
          </div>
          <CampaignSidebar />
        </ContentLayout>
      </ContainerLayout>
    </div>
  );
};
