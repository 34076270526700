import React from "react";
import { IconProps } from ".";


export const LeftArrow: React.FC<IconProps> = ({
  className = "w-3 h-3",
  strokeWidth = "1.5",
  color = "#000"
}) => (
  <svg xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={`${strokeWidth}`}
    stroke={`${color}`}
    className={`${className}`}>
    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
  </svg>
)
