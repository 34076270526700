import { DECREMENT_ACTIVE_LOADING, INCREMENT_ACTIVE_LOADING, START_LOADING, STOP_LOADING } from './constants';

export type LoadingActionType =
  | { type: typeof START_LOADING; }
  | { type: typeof  STOP_LOADING; }
  | { type: typeof  INCREMENT_ACTIVE_LOADING; }
  | { type: typeof  DECREMENT_ACTIVE_LOADING; }


export type LoadingType = {
  loading: boolean;
  activeLoading: number;
}

export const defaultLoading = {
  loading: false,
  activeLoading: 0
}
export const loadingReducer = (state: LoadingType = defaultLoading, action: LoadingActionType): LoadingType => {
  switch (action.type) {
    case START_LOADING:
      return {...state, loading: true}
    case STOP_LOADING:
      if (state.activeLoading <= 0)
        return {...state, loading: false}
      return {...state, loading: true}
    case INCREMENT_ACTIVE_LOADING:
      return {...state, activeLoading: state.activeLoading + 1}
    case DECREMENT_ACTIVE_LOADING:
      return {...state, activeLoading: state.activeLoading - 1}
  }
};
