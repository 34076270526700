import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ContainerLayout } from "../components/layouts/ContainerLayout";
import { ContentLayout } from "../components/layouts/ContentLayout";
import { TitleCenterLayout } from "../components/layouts/TitleCenterLayout";
import { TitleLayout } from "../components/layouts/TitleLayout";
import FundListData  from "../components/ui/funds/FundListItem";
import { BlogListItem } from "../components/ui/blogs/BlogListItem";
import { YoutubeIframe } from "../components/ui/sns/YoutubeIframe";
import Images from '../assets/noimage.jpg';
import { RightArrowRound } from "../components/icons/RightArrowRound";
import { NewsHome } from "../components/layouts/NewsLayout";
import { HomePostsType } from "../store/post";
import PostService from "../infrastructure/api/posts/post";
import FundService from "../infrastructure/api/funds/funds";
import {FundListType, FundPaginationType} from "../store/fund";
import useAxios from "../hooks/useAxios";
import FundListComponent from "../components/ui/funds/FundListItem";

const Home: React.FC = () => {
	const [posts, setPosts] = useState<HomePostsType>()
	const [fundData, setFundData] = useState<FundPaginationType>({ count: 0, data: [] });
	const axios = useAxios();
	const fundService = new FundService(axios);
	const postService = new PostService(axios);

	useEffect(() => {
		fetchFunds();
		fetchPosts();
	}, []);

	const fetchFunds = () => {
		fundService.getFundList(1, 3).then((data) => { setFundData(data); })
	}

	const fetchPosts = () => {
		postService.getHomePost().then((data) => { setPosts(data); })
	}

	return (
		<div className="mt-20">
			<div className="bg-primary-main h-[70px] py-2.5">
				<div className="container mx-auto text-center">
					<p className="text-2xl font-bold text-primary-text">キャンペーン名をここに表示 </p>
					<span className="text-sm text-primary-text -mt-2">*入金額に応じてポイント還元などのファンドに依存しないキャンペーン</span>
				</div>
			</div>

			<div className="w-full">
				<div className="px-2 flex flex-col justify-center bg-primary-bg h-[628px] mb-5 ">
					<div className="container mx-auto">
						<div className="w-full sm:w-[628px] flex flex-col justify-center items-center">
							<span className="max-[500px]:text-base text-lg font-bold text-center text-secondary-main">＼千葉県初の不動産クラウドファンディング事業者／</span>
							<h1 className="text-[30px] sm:text-[50px] text-secondary-main leading-normal font-bold mb-9 text-center">みつばちファンドで <br/> 里帰り投資</h1>
							<p className="text-lg font-bold mb-9">テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト</p>
							<Link to="#" className="bg-accent-main w-4/5 sm:w-auto py-3 px-10 text-center text-primary-text rounded-md" >いますぐ始める</Link>
						</div>
					</div>
				</div>
			</div>

			<div className="w-full">
				<div className="py-10">
					<div className="flex flex-col justify-center container mx-auto w-full py-5 text-grey-text sm:flex-row">
						<div className="w-full flex flex-row justify-between content-center mb-5 sm:w-56 sm:flex-col sm:justify-center sm:content-center sm:mb-0 ">
							<div>
								<span className="text-lg select-none text-grey-boarder">information</span>
								<h2 className="text-3xl font-bold mb-3">お知らせ</h2>
							</div>
							<Link to="/news" className="flex  items-center text-lg font-semibold">
								<span className="mr-1">お知らせ一覧</span>
								<RightArrowRound className="w-7 h-7" stroke="#ffffff" fill="#FCD21D" />
							</Link>
						</div>
						<NewsHome posts={posts?.news || []} />
					</div>
				</div>
			</div>

			<ContainerLayout>
				<TitleLayout subTitle="funds" title="最新ファンド情報" link={{ to: "fund", label: "ファンド一覧" }} />
				<ContentLayout >
					<FundListComponent funds={fundData.data} />
				</ContentLayout>
			</ContainerLayout>

			<ContainerLayout>
				<TitleLayout subTitle="about" title="みつばちファンドとは？" />
				<ContentLayout>
					<div className="flex flex-col justify-between md:flex-row">
						<div className="flex flex-col justify-center items-center w-full md:w-2/4 md:pr-8 ">
							<p>
								みつばちファンドは千葉県初の不動産クラウドファンディング事業者です。不動産クラウドファンディングは、
								「不動産特定共同事業法」の電子取引認可を受けた事業者だけが提供できるサービスです。
								これはダミーテキストですこれはダミーテキストですこれはダミーテキストです、これはダミーテキストです
								これはダミーテキストですこれはダミーテキストですこれはダミーテキストですこれはダミーテキストです。
							</p>
							<Link
								to="beginners"
								className="border border-grey-boarder w-full py-3 text-center text-grey-text-main text-base sm:text-lg font-bold rounded-md mt-5 hidden md:block"
							>
								みつばちファンドをもっと詳しく知る
							</Link>
						</div>
						<div className="w-full h-full md:pl-3 mt-5 md:w-2/4 md:mt-0 lg:pl-8">
							<img className="w-full object-cover border sm:h-72 xl:h-60" src={Images} alt="みつばちファンドとは？" />
						</div>
						<Link
							to="beginners"
							className="border border-grey-boarder w-full py-3 text-center text-grey-text-main font-bold rounded-md mt-5 md:hidden"
						>
							みつばちファンドをもっと詳しく知る
						</Link>
					</div>
				</ContentLayout>
			</ContainerLayout>

			<ContainerLayout>
				<TitleCenterLayout title="みつばちファンドとは？" className="text-center" />
				<ContentLayout>
					<YoutubeIframe
						embedId="_x7E38ULU-Y"
						width="100%"
						height="auto"
					/>
				</ContentLayout>
			</ContainerLayout>

			<ContainerLayout className="bg-grey-bg">
				<TitleCenterLayout title="CTA" className="text-center" />
				<ContentLayout className="flex justify-center">
					<Link to="" className="grid items-center bg-grey-text-light w-60 h-12 mt-4 text-center text-primary-text rounded-md" >新規会員登録はこちら</Link>
				</ContentLayout>
			</ContainerLayout>

			<ContainerLayout width="w-full">
				<TitleLayout
					subTitle="Blog"
					title="投資に役立つ知識"
					link={{ to: "blog", label: "ブログ一覧" }}
					className="container"
				/>
				<ContentLayout>
					<BlogListItem posts={posts?.blogs || []} />
				</ContentLayout>
			</ContainerLayout>
		</div>
	);
};

export default Home;
