import React from 'react';


interface ContainerLayoutProps {
  className?: string;
  width?: string;
  children: React.ReactNode;
}

export const ContainerLayout: React.FC<ContainerLayoutProps> = ({
  className = "w-full ",
  width = "container mx-auto",
  children
}) => {
  return (
    <div className={`${className} py-5 md:py-10`}>
      <div className={`${width}`}>
        {children}
      </div>
    </div >
  )
}
