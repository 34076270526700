import React from "react";
import { TextInputType } from "./types";


export const Input: React.FC<TextInputType> = ({
  label,
  name,
  value,
  type = "text",
  className = "",
  errors = [],
  placeholder = "",
  onChange = () => { },
  required = false,
  options = [],
  ...props
}) => {

  return (
    <div className="flex flex-col">
      {label ? (<div className="flex items-end justify-between mb-1">
        <label className="">{label}</label>
        {required ? (
          <small className="items-end bg-grey-bg text-grey-text-light px-2 py-0.5">*</small>
        ) : null}
      </div>) : null}
      {type === "textarea" ? (
        <textarea
          className={(errors?.length > 0 ? "border-error " : "") +
            "w-full border border-grey-boarder rounded h-32 p-2" +
            className
          }
          value={value}
          {...props} />
      ) : type === "date" ? (
        <input
          id={`id_${name}`}
          type="date"
          className={
            (errors?.length > 0 ? "border-error " : "") +
            "w-full border border-grey-boarder p-2 rounded " +
            className
          }
          value={value}
          onChange={onChange}
          {...props}
        />
      ) : (
        <input
          id={`id_${name}`}
          className={
            (errors?.length > 0 ? "border-error " : "") +
            "w-full border border-grey-boarder p-2 rounded " +
            className
          }
          value={value}
          {...props}
        />
      )}
    </div>
  )
}
