import React, {useEffect} from "react";
import {SigninLayout} from "../layouts/SigninLayout";
import useForm from "../../hooks/useForm";
import {FastapiErrorT} from "../../utils/fastapi";
import {
	REGISTRATION_INITIAL_STATE,
	RegistrationForm,
	RegistrationT,
} from "./RegistrationForm";
import {useStepper} from "../../hooks/useStepper";


type BaseAuthProps = {
	service: (inputValues: RegistrationT, token?: string) => Promise<RegistrationT>;
	callback: (handleInitial: (data: RegistrationT) => void) => void;
	token?: string;
}

const BaseAuth:React.FC<BaseAuthProps> = ({service, callback, token}) => {
	const {incrementCurrentStep, decrementCurrentStep} = useStepper();
	const {
		inputValues,
		handleChange,
		errors,
		handleSubmit,
		handleInitial,
		handleClick
	} = useForm<RegistrationT, FastapiErrorT>(
		REGISTRATION_INITIAL_STATE,
		(values, setErrors) => {
			handleClickAndSubmit(values, setErrors);
		},
		(values, setErrors) => {
			handleClickAndSubmit(values, setErrors);
		}
	);

	useEffect(() => {
		callback(handleInitial);
	}, []);


	const handleClickAndSubmit = (values:RegistrationT, setErrors: (errors: FastapiErrorT) => void) => {
		const cpService = token ? service(inputValues, token) : service(inputValues);
			cpService
				.then((data) => {
					incrementCurrentStep();
					if (handleInitial !== undefined) {
						handleInitial(data);
					}
					setErrors(null!);
				})
				.catch((error) => {
					setErrors(JSON.parse(error));
				})
	}

	const handleBack = () => {
		handleInitial({...inputValues, type: "validate"});
		decrementCurrentStep();
	}

	return (
		<SigninLayout title={"新規会員の登録"}>
			<RegistrationForm
				inputValues={inputValues}
				handleChange={handleChange}
				errors={errors}
				handleSubmit={handleSubmit}
				handleClick={handleClick}
				handleBack={handleBack}
			/>
		</SigninLayout>
	)
}

export default BaseAuth;
