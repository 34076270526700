import React from 'react';
import { Link } from 'react-router-dom';

export const Risk: React.FC = () => {

  return (
    <div className="my-10">
      <div className="tracking-widest leading-8">
        <p className="py-2">本ファンドに出資していただく際は、「契約成立前書面」に記載された事項(※)を参照いただいたうえ、慎重な判断により投資を行っていただけますようお願いします。</p>
        <p className="py-2 mb-10"> ※不動産特定共同事業法の実施により予想される損失発生要因に関する事項があります。
          （不動産特定共同事業法施行規則第４３条第１項第３１号） 本欄では想定されるリスクの主要なポイントについて記載します。</p>
      </div>
      <div className="tracking-widest leading-7 my-10">
        <h2 className="font-bold text-2xl">出資元本に関するリスク</h2>
        <p className="py-2">本事業者の業務又は財産の状況等の変化(本事業者の破産等)によっては、これを直接又は間接の原因として元本欠損が生じ、出資者に元本が返還されないおそれがあります。</p>
        <p className="py-2">また、匿名組合においては商法上の規定、又は出資法との関係もあり出資金全部又は一部の返還保証はされておりません。
          本事業への出資金は有価証券、預貯金や保険契約とは異なり、投資家保護基金・貯金保険機構・預金保険機構・保険契約者保護機構の保護の対象ではありません。
          ※不動産特定共同事業法の実施により予想される損失発生要因に関する事項があります。（不動産特定共同事業法施行規則第４３条第１項第３１号） 本欄では想定されるリスクの主要なポイントについて記載します。</p>
      </div>
      <div className="tracking-widest leading-7 my-10">
        <h2 className="font-bold text-2xl">不動産に関するリスク</h2>
        <p className="pt-2">①賃料単価の下落による減収</p>
        <p className="pb-2">本事業の損益は、対象不動産の滞納、滞納状態解消費用の発生、賃料単価の下落により、賃貸収益が悪化した場合には損失が発生し、元本欠損が生じるおそれがあります。</p>
        <p> ②売却損の発生</p>
        <p className="pb-2"> 対象不動産を売却した場合、不動産市況等により売却損が発生し、これにより契約終了時点で出資者(事業参加者)が受領できる出資金について元本欠損が生じるおそれがあります。</p>
      </div>
      <div className="tracking-widest leading-7 my-10">
        <h2 className="font-bold text-2xl">テナント及び施設運営リスク</h2>
        <p className="pt-2">本事業では対象不動産をテナント(賃借人)に賃貸借契約で貸すことになります。
          テナントその他関係者の業績や財政状態の悪化、運営業務委託契約による受託者(存在する場合)の施設運営の状況によっては、本事業者が受け取るべき賃料または売上を受け取れず、投資家に対しての配当を行えないおそれがあります。
          また、かかる事態が発生した場合には不動産価値も大きく毀損するため、売却時に大きな損失が生じるおそれがあります。</p>
      </div>
      <div className="flex flex-col items-end justify-start">
        <div className="flex flex-col text-grey-boarder">
          <details>参考サイト</details>
          <Link to="">https://creal.jp/funds/risks/80</Link>
          <Link to="">https://cozuchi.com/system/funds/65695</Link>
        </div>
      </div>
    </div >
  );
};