import React, {useState, useEffect} from 'react';
import Title from '../../../../components/layouts/Title';
import GoogleMap from '../../../../components/ui/sns/GoogleMap';
import {ImagesType, InformationType} from "../../../../store/fund";
import {TableData} from "../../../../components/layouts/TableLayout";
import {OutputData} from "../../../../store/editor";
import FundService from "../../../../infrastructure/api/funds/funds";
import {editorJSParser} from "../../../../utils/editor";

interface PropertyInformationProps {
  data: InformationType[] | null;
  fundService: FundService;
  setData: (data: InformationType[]) => void;
  fundId: string;
}

export const PropertyInformation: React.FC<PropertyInformationProps> = ({data, fundService, setData, fundId}) => {
  const [selectedObjectName, setSelectedObjectName] = useState("");

  useEffect(() => {
    if(!data) {
      fundService.getFundObjectInformation(fundId)
        .then((resData) => {
          setData(resData);
          setSelectedObjectName(resData[0].object_name);
        })
    } else if(data.length > 0) {
      setSelectedObjectName(data[0].object_name);
    }
  }, []);

  if (data === null) return <></>;

  const selectedObject = data.find(
    (obj) => obj.object_name === selectedObjectName
  );

  return (
      <div className="py-10">
        <Title name="募集概要"/>
        <div className={"mr-20"}>
          <select
            className={" p-2 border rounded w-full lg:w-1/2"}
            onChange={(e) => setSelectedObjectName(e.target.value)}
          >
            {data.map((obj, index) => (
              <option key={index} value={obj.object_name}>
                {obj.object_name}
              </option>
            ))}
          </select>
        </div>
        {selectedObject && (
          <PropertyInformationBody {...selectedObject} />
        )}
      </div>
  )
}

const PropertyInformationBody: React.FC<InformationType> = (props) => {
  const { object_data,google_map, neighborhood_images, neighborhood_information, object_images} = props;

  return (
    <div>
      <div className="grid lg:grid-cols-2 lg:gap-20">
        <div className="w-full">
          <table className="text-grey-text w-full mb-10 lg:mb-0">
            <div className={"mt-3"}>
              {object_data.map((data, index) => (
                <TableData key={index} name={data.label}>
                  <p>{data.value}</p>
                </TableData>
              ))}
            </div>
          </table>
        </div>
        <div className="w-full">
          {
            google_map && google_map.center && <GoogleMap height="300px" width="100%" googleData={google_map} />
          }
          {object_images.length > 0 && <ImageListComponent images={object_images}/>}
        </div>
      </div>
      <NeighborhoodInformationBody information={neighborhood_information} images={neighborhood_images} />
    </div>
  )
}

interface NeighborhoodInformationBodyProps {
  information: OutputData;
  images: ImagesType[];
}

const NeighborhoodInformationBody: React.FC<NeighborhoodInformationBodyProps> = ({information, images}) => {
const htmlArray: string[] = editorJSParser.parse(information);
  return (
    <div className="grid lg:grid-cols-2 lg:gap-20 my-10">
     <div>
       <Title name="周辺情報"/>
       {images.length > 0 && <ImageListComponent images={images} />}
     </div>
     <div>
      {
        htmlArray.map((val, index) => (
          <div key={index} dangerouslySetInnerHTML={{ __html: val }} className="my-7" />
        ))
      }
     </div>
    </div>
  )
}

interface ImageListComponentProps {
  images: ImagesType[];
}


const ImageListComponent: React.FC<ImageListComponentProps> = ({images}) => {
  const [mainImage, setMainImage] = useState<string>(() => {
    if (images.length > 0) return images[0].media_url;
    return "";
  });

  const handleLiClick = (newImageSrc: string) => {
    setMainImage(newImageSrc);
  };

  return (
    <div className="mt-5">
      <div className="main">
        <figure className="w-full h-[300px] overflow-hidden">
          <img className="border h-full w-full object-center object-cover" src={mainImage} alt={"image"} />
        </figure>
      </div>
      <div className="my-5 overflow-x-scroll">
        <ul className="flex flex-row gap-5">
          {images.map((imageSrc, index) => (
            <li key={index} onClick={() => handleLiClick(imageSrc.media_url)}>
              <figure className="w-[200px] h-[100px] overflow-hidden">
                <img className="border h-full w-full object-center object-cover" src={imageSrc.media_url} alt={"image"} />
              </figure>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
