import {Axios} from "axios";

class BaseService {
	axios: Axios

	constructor(axios: Axios) {
		this.axios = axios;
	}

}

export default BaseService;
