import { getPaginationItems } from './PaginationItems'
import { LeftArrow } from '../../icons/LeftArrow';
import { RightArrow } from '../../icons/RightArrow';
import PageLink from './PageLink';

export type Props = {
  currentPage: number;
  maxLength: number;
  className?: string;
  total: number;
  perPage: number;
  setCurrentPage: (page: number) => void;
};

export default function Pagination({
  currentPage,
  maxLength,
  className,
  total,
  perPage,
  setCurrentPage,
}: Props) {

  const lastPage = Math.ceil(total / perPage);
  const pageNums = getPaginationItems(currentPage, lastPage, maxLength);
  return (
    <nav className={`${className} flex flex-col items-center`} aria-label="Pagination">
      <ul className="inline-flex items-center -space-x-px">
        <li>
          <PageLink
            disabled={currentPage === 1}
            onClick={() => setCurrentPage(currentPage - 1)}
          >
            <LeftArrow className="w-5 h-5" />
          </PageLink>
        </li>
        {pageNums.map((pageNum, idx) => (
          <li key={idx}>
            <PageLink
              active={currentPage === pageNum}
              disabled={isNaN(pageNum)}
              onClick={() => setCurrentPage(pageNum)}
              className="px-3 py-2 border-2 m-1 cursor-pointer"
            >
              {!isNaN(pageNum) ? pageNum : '...'}
            </PageLink>
          </li>
        ))}
        <li>
          <PageLink
            disabled={currentPage === lastPage}
            onClick={() => setCurrentPage(currentPage + 1)}
          >
            <RightArrow className="w-5 h-5" />
          </PageLink>
        </li>
      </ul>
    </nav>
  );
}


