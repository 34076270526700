import React, { useState } from "react";

import logo from '../../assets/logo.png';
import { Nav } from "./nav/Nav";
import { Outlet } from "react-router-dom";
import { Entry } from "../ui/Entry";
import { Close } from "../icons/Close";
import { Bars } from "../icons/Bars";
import { Link } from "react-router-dom";


export const Header: React.FC = () => {
	const [isMenuOpen, setMenuOpen] = useState(false);

	const toggleMenu = () => {
		setMenuOpen(!isMenuOpen);
	};

	const closeMenu = () => {
		setMenuOpen(false);
	};

	return (
		<React.Fragment>
			<header className="fixed z-50 top-0 left-0 right-0 bg-white h-20 py-4 px-6 flex items-center justify-between">
				<div className="flex justify-start">
					<Link className="h-full relative" to="/"><img className="h-16" src={logo} alt="logo" /></Link>
				</div>
				<button className="lg:hidden f-right" onClick={toggleMenu}>
					<Bars className="h-10 w-10" />
				</button>
				<div
					className={`fixed top-0 left-0 right-0 bg-white h-auto w-full px-6 pt-4 pb-10 flex flex-col items-center transition-all duration-1000 ease-in-out transform lg:hidden ${isMenuOpen ? "translate-y-0" : "-translate-y-full"
						}`}
				>
					<button className="w-full flex justify-end items-end content-end" onClick={closeMenu}><Close className="h-10 w-10" color="#000" /></button>
					<div className="w-full flex flex-col items-center gap-20">
						<Nav />
						<Entry />
					</div>
				</div>

				<div className="hidden lg:block">
					<div className="flex justify-start items-center gap-4">
						<Nav />
						<Entry />
					</div>
				</div>
			</header>
			<Outlet />
		</React.Fragment >
	)
}
