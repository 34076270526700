
import React from "react";
import { Link } from 'react-router-dom'
import { HeaderLayout } from '../components/ui/HeaderLayout';
import { Breadcrumb } from '../components/ui/Breadcurmb';
import { ContentLayout } from "../components/layouts/ContentLayout";
import { ContainerLayout } from "../components/layouts/ContainerLayout";
import { Input } from '../components/ui/inputs/Input';
import { Button } from '../components/ui/Button';
import { Form } from '../components/ui/Form';
import { MainContainerLayout } from "../components/layouts/MainContainerLayout";


const breadcrumbItems = [
  { url: '/', name: 'HOME', index: 0, isLast: false },
  { url: '/contact', name: 'お問い合わせ', index: 1, isLast: true },
];


export const CustomerSupport: React.FC = () => {

  return (
    <MainContainerLayout>
      <HeaderLayout
        title="お問い合わせ"
        subTitle="customer support"
      />
      <Breadcrumb
        items={breadcrumbItems}
      />
      <ContainerLayout>
        <ContentLayout className="w-full sm:w-4/5 lg:w-2/4 py-10">
          <p>お問い合わせの回答には数日いただく場合がございます。<br />
            また、お問い合わせ内容によっては、返信を差し控えさせていただく場合もございますのであらかじめご了承ください。
          </p>
          <br />
          <Form >
            <Input label="| お名前" required={true} name="" value="" type="text" placeholder="" />
            <Input label="| メールアドレス" required={true} name="" value="" type="email" />
            <Input label="| お問い合わせ内容" required={true} name="" value="" type="textarea" />
            <div className="flex justify-center">
              <input type="checkbox" name="" />&nbsp;<Link to="/privacy-policy" className="text-accent-main underline">個人情報保護方針</Link>&nbsp;/&nbsp;<Link to="" className="text-accent-main underline">利用規約</Link>に同意する。
            </div>
            <Button type="submit" className="w-2/4 self-center bg-grey-text-light text-primary-text p-2 text-xl">入力内容を確認</Button>
            <small className="text-center -mb-8">※事前に以下のドメインの受信を許可するようご設定をお願いいたします。</small>
            <small className="text-center">mitsubachi-fund.com / souken-j.co.jp</small>
          </Form>
        </ContentLayout>
      </ContainerLayout>
    </MainContainerLayout>
  )
}
