import axios from 'axios';
import { BACKEND_URL } from '../../store/constants';


export default axios.create({ baseURL: BACKEND_URL });

export const authAxios = axios.create({
  baseURL: BACKEND_URL,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true,
});
