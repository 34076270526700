import React from "react";
import { Link } from "react-router-dom"
import { HeaderLayout } from '../components/ui/HeaderLayout';
import { Breadcrumb } from '../components/ui/Breadcurmb';
import Title from "../components/layouts/Title";
import Tab from '../components/layouts/TabLayout';
import Images from '../assets/loginImage.jpg'
import { ImageLayout } from "../components/layouts/ImageLayout";
import GoogleMap from "../components/ui/sns/GoogleMap";
import { TableLayout } from '../components/layouts/TableLayout';
import { MainContainerLayout } from "../components/layouts/MainContainerLayout";

const breadcrumbItems = [
  { url: '/', name: 'HOME', index: 0, isLast: false },
  { url: '/company', name: '運営会社', index: 1, isLast: true },
];

const Tabs = [
  '私たちについて',
  '会社概要',
  'グループ会社'
]

const About: React.FC = () => {
  return (
    <div>
      <div className="pt-5">
        <span className="text-xl font-bold">みつばちファンドの思い</span>
        <Title name="なぜ「みつばちファンド」にしたのか見出し" />
        <p>これはダミーテキストですこれはダミーテキストですこれはダミーテキストです、これはダミーテキストですこれはダミーテキストですこれはダミーテキストですこれはダミーテキストですこれはダミーテキストですこれはダミーテキストです。</p><br />
        <p>これはダミーテキストですこれはダミーテキストですこれはダミーテキストです、これはダミーテキストですこれはダミーテキストですこれはダミーテキストですこれはダミーテキストですこれはダミーテキストですこれはダミーテキストです。これはダミーテキストですこれはダミーテキストですこれはダミーテキストです、これはダミーテキストですこれはダミーテキストですこれはダミーテキストですこれはダミーテキストですこれはダミーテキストですこれはダミーテキストです。</p>
      </div>
      <div className="py-5 grid grid-cols-1 md:grid-cols-2 gap-5 md:gap-10 mt-5 md:mt-10">
        <div>
          <span className="text-xl font-bold">代表挨拶</span>
          <Title name="代表挨拶見出しをここに表示" className="-mb-0.5 text-3xl" />
          <Title name="これはダミーテキストです。" className=" text-3xl" />
          <p>これはダミーテキストですこれはダミーテキストですこれはダミーテキストです、これはダミーテキストですこれはダミーテキストですこれはダミーテキストですこれはダミーテキストです
            これはダミーテキストですこれはダミーテキストです。 これはダミーテキストですこれはダミーテキストですこれはダミーテキストです、これはダミーテキストですこれはダミーテキストです
            これはダミーテキストですこれはダミーテキストですこれはダミーテキストですこれはダミーテキストです。これはダミーテキストですこれはダミーテキストですこれはダミーテキストです、
            これはダミーテキストですこれはダミーテキストですこれはダミーテキストですこれはダミーテキストですこれはダミーテキストですこれはダミーテキストです。</p>
        </div>
        <figure className="md:h-80 w-full md:mt-10">
          <ImageLayout src={Images} alt="" className="object-cover w-full" />
        </figure>
      </div>
      <div className="py-5 grid grid-cols-1 md:grid-cols-2 gap-5 md:gap-10">
        <figure className="md:h-80 w-full md:mt-10">
          <ImageLayout src={Images} alt="" className="w-full object-cover" />
        </figure>
        <div>
          <p>これはダミーテキストですこれはダミーテキストですこれはダミーテキストです、これはダミーテキストですこれはダミーテキストですこれはダミーテキストですこれはダミーテキストです
            これはダミーテキストですこれはダミーテキストです。 これはダミーテキストですこれはダミーテキストですこれはダミーテキストです、これはダミーテキストですこれはダミーテキストです
            これはダミーテキストですこれはダミーテキストですこれはダミーテキストですこれはダミーテキストです。これはダミーテキストですこれはダミーテキストですこれはダミーテキストです、
            これはダミーテキストですこれはダミーテキストですこれはダミーテキストですこれはダミーテキストですこれはダミーテキストですこれはダミーテキストです。
          </p>
          <div className="float-right text-right font-xl" >
            <p className="font-bold">創建住販株式会社 <br />
              代表取締役 <span className="text-2xl">安達 勉</span></p>
          </div>
        </div>
      </div>
      <div className="pt-5 mt-10">
        <span className="text-xl font-bold">みつばちファンドのこれから</span>
        <Title name="これからの計画や未来創造の見出し" className="text-3xl" />
        <p>これはダミーテキストですこれはダミーテキストですこれはダミーテキストです、これはダミーテキストですこれはダミーテキストですこれはダミーテキストですこれはダミーテキストです
          これはダミーテキストですこれはダミーテキストです。 これはダミーテキストですこれはダミーテキストですこれはダミーテキストです、これはダミーテキストですこれはダミーテキストです
          これはダミーテキストですこれはダミーテキストですこれはダミーテキストですこれはダミーテキストです。これはダミーテキストですこれはダミーテキストですこれはダミーテキストです、
          これはダミーテキストですこれはダミーテキストですこれはダミーテキストですこれはダミーテキストですこれはダミーテキストですこれはダミーテキストです。
        </p>
        <div className="flex justify-end mt-3">
          <Link to="/fund" className="grid items-center bg-grey-text-light w-60 h-12 mt-4 text-center text-primary-text rounded-md">ファンド一覧はこちら</Link>
        </div>
      </div>
    </div >
  )
}
const data = [
  { title: "会社名", desc: "創建住販株式会社" },
  { title: "代表者", desc: "安達 勉" },
  { title: "資本金", desc: "10,000万円" },
  { title: "設立", desc: "1989年10月" },
  { title: "所在地", desc: "千葉県千葉市中央区末広3-3-9 創建第一ビル" },
  { title: "免許", desc: "不動産特定共同事業許可番号：千葉県知事第1号 宅地建物取引業者免許 国土交通大臣（1）第9408号 建設業許可 千葉県知事（特-2）第38632号 2級建築士事務所 千葉県知事登録第2-1408-6993号 住宅性能保証制度登録店 住宅完成保証制度業者登録 住宅完成保証制度代替履行業者登録 トステムスーパーウォール工法軸組加盟店" },
  { title: "事業内容", desc: "不動産（仲介・売買・賃貸・管理業務） 建築業（設計・施工・建築・監理業務）", info: "" },
];
const HistoryData = [
  { title: "1989", desc: "会社設立" },
  { title: "1998", desc: "「創建住販」に社名変更" },
  { title: "1999", desc: "本社を千葉市中央区末広へ移転 「建設部」発足" },
  { title: "2001", desc: "都町に中央店、ショールームを開設" },
  { title: "2004", desc: "1都賀支店を開設" },
  { title: "2013", desc: "ISO9001認証取得" },
  { title: "2018", desc: "福岡支店を開設", info: "" },
  { title: "2019", desc: "ウィッシュホームプラス株式会社を開設" },
  { title: "2022", desc: "ウィッシュホーム株式会社を開設 創建管理株式会社を開設" },
  { title: "2022", desc: "不動産特定共同事業許可取得" },
];
const CompanyProfile: React.FC = () => {
  return (
    <div>
      <div className="py-5">
        <Title name="会社概要" className="text-3xl" />
        <div className="grid grid-cols-1 md:grid-cols-2 md:gap-10 lg:gap-20">
          <div>
            <TableLayout data={data} className="w-full mb-5" />
          </div>
          <div>
            <ImageLayout src={Images} alt="" className="h-80 w-full mb-10 md:h-60  lg:h-80" />
            {/*<GoogleMap height="300" width="100%" />*/}
          </div>
        </div>
      </div>
      <div>
        <Title name="沿革" className="text-3xl" />
        <table className="mb-5">
          <tbody>
            {HistoryData.map((item, key) => (
              <tr key={key} className="justify-items-start ">
                <th className="border-r-2 border-grey-boarder font-normal text-start w-20  py-1 self-start">{item.title}年</th>
                <td className=" border-grey-boarder px-3 py-1 w-auto">
                  <p>{item.desc}</p>
                  <span className="text-[10px] text-grey-text-light">{item.info}</span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

const GroupCompany: React.FC = () => {
  return (
    <div>
      <div className="py-5">
        <Title name="グループ会社" className="text-3xl" />
        <ImageLayout src={Images} alt="" className="h-80 w-full" />
      </div>
    </div>
  )
}

export const Company: React.FC = () => {
  return (
    <MainContainerLayout>
      <HeaderLayout
        title="運営会社"
        subTitle="company"
      />
      <Breadcrumb
        items={breadcrumbItems}
      />
      <div className="w-full pt-10 md:pb-10">
        <Tab tabs={Tabs} >
          <About />
          <CompanyProfile />
          <GroupCompany />
        </Tab>
      </div>
    </MainContainerLayout>
  )
}
