import React from 'react';
import { SidebarTitle, SidebarFundLink, SidebarLatestFund, SidebarFundRecord } from '../../components/ui/sidebar/SidebarLayout';
import noImage from "../../assets/noimage.jpg";

export const ReportSidebar: React.FC = () => {
  return (
    <aside className="w-80">
      <SidebarLatestFund image={noImage} title="最新ファンド情報" />
      <SidebarTitle title="これまでの記録">
        <SidebarFundRecord title="2023.1.1" slug="" />
        <SidebarFundRecord title="2023.1.1" slug="" />
      </SidebarTitle>
      <br />
      <SidebarFundLink title="○○号ファンド" slug="" />
      <SidebarFundLink title="○○号ファンド" slug="" />
      <SidebarFundLink title="○○号ファンド" slug="" />
      <SidebarFundLink title="○○号ファンド" slug="" />
    </aside>
  )
}