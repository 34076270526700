import React from 'react';
import { ArticleTitle } from './ArticleTitleSingle';
import { ArticleSingleProps } from './article';
import { OutputData } from "../../../store/editor";
import { editorJSParser } from '../../../utils/editor';

const Details: React.FC<{ content: OutputData }> = ({ content }) => {
  const htmlArray: string[] = editorJSParser.parse(content);
  return (
    <div className="py-5 border-b-4 border-grey-boarder">
      {
        htmlArray.map((val, index) => (
          <div key={index} dangerouslySetInnerHTML={{ __html: val }} className="my-7" />
        ))
      }
    </div>
  );
};


export const ArticleContain: React.FC<ArticleSingleProps> = ({
  title,
  category_name,
  tags,
  media_url,
  content,
  created_date,
  updated_date,
}) => {
  return (
    <ArticleTitle
      title={title}
      category_name={category_name}
      tags={tags}
      media_url={media_url}
      created_date={created_date}
      updated_date={updated_date}
    >
      {content && <Details content={content} />}
    </ArticleTitle>
  );
};
