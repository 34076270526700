import React from 'react';
import { RightArrow } from '../icons/RightArrow';
import {Link} from "react-router-dom";

type BreadcrumbItemType = {
  url: string;
  name: string;
};

interface BreadcrumbItemProps extends BreadcrumbItemType {
  index: number;
}

interface BreadcrumbProps {
  items: BreadcrumbItemType[];
  className?: string;
}


const BreadcrumbItem: React.FC<BreadcrumbItemProps> = ({ url, name, index }) => {
  return (
      <li
        className="flex h-5 items-center group"
        itemScope
        itemType="https://schema.org/ListItem"
        itemProp="itemListElement"
      >
        <span className="group-first:hidden"><RightArrow /></span>
        <Link to={url} className="text-xs hover:text-gray-700" itemProp="item">
          <span itemProp="name">{name}</span>
        </Link>
        <meta itemProp="position" content={index.toString()} />
      </li>
  );
};


export const Breadcrumb: React.FC<BreadcrumbProps> = ({ items, className = "bg-primary-light" }) => (
  <div className={className}>
    <nav className="container mx-auto px-2 h-5 xl:w-[1280px]">
      <ol className="flex items-center" itemScope itemType="https://schema.org/BreadcrumbList">
        {items.map((item, index) => (
          <BreadcrumbItem key={index} {...item} index={index + 1} />
        ))}
      </ol>
    </nav>
  </div>
);
