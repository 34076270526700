import React from "react";
import { IconProps } from ".";


export const Close: React.FC<IconProps> = ({
  className = "w-6 h-6",
  color = "red"
}) => (
  <svg xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill={color}
    className={className}
    stroke={color}>
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M6 18L18 6M6 6l12 12"
    />
  </svg>
); 
