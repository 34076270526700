import React from "react";
import { StepperHeader } from "./StepperHeader";
import { StepperStep, StepperSteps } from "./StepperSteps";
import {useStepper} from "../../../hooks/useStepper";

interface StepperType {
	children: React.ReactNode;
}

interface StepperComponent extends React.FC<StepperType> {
	Step: typeof StepperStep;
	Steps: typeof StepperSteps;
}

const Stepper: StepperComponent = ({ children }) => {
	const {
		currentStep,
		steps
	} = useStepper();
	return (
		<div className="flex flex-col">
			<StepperHeader steps={steps} currentStep={currentStep} />
			<section className="my-8">
				{children}
			</section>
		</div>
	)
}

Stepper.Step = StepperStep;
Stepper.Steps = StepperSteps;

export default Stepper;
