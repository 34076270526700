import bee from '../../../assets/gauge-icon.png';

interface Props {
  percent?: number;
  size?: number;
  strokeWidth?: number;
}

export const CircularProgressbar: React.FC<Props> = ({
  percent = 36,
  size = 100,
  strokeWidth = 10
}) => {

  const radius = (size - strokeWidth) / 2;
  const center = size / 2;
  const circumference = 2 * Math.PI * radius

  const progress = circumference - (percent / 100) * circumference;

  const offsetAngle = (360 * (circumference - progress)) / circumference;
  const radians = (offsetAngle - 90) * (Math.PI / 180);
  const newX = center + radius * Math.cos(radians) - 10;
  const newY = center + radius * Math.sin(radians) - 15;

  return (
    <div className="flex items-center justify-center relative">
      <svg width={size} height={size} className="transform" style={{ overflow: 'visible' }}>
        <circle
          cx={center}
          cy={center}
          r={radius}
          fill="transparent"
          stroke="#F7F8F9"
          strokeWidth={strokeWidth}
          transform={`rotate(-90 ${center} ${center})`}
        />
        <circle
          className="after:content-['AAA'] after:text-cyan-400 after:h-4 after:w-4 after:absolute"
          cx={center}
          cy={center}
          r={radius}
          fill="none"
          stroke="#FEF29D"
          strokeWidth={strokeWidth}
          strokeDasharray={`${circumference} ${circumference}`}
          strokeDashoffset={progress}
          strokeLinecap="round"
          transform={`rotate(-90 ${center} ${center})`}
        />
        <text className="font-bold text-xl" x={center + 3} y={center + 4} textAnchor="middle" fill="#836334">{percent}%</text>
        <image href={bee} className='h-6 w-6' x={newX} y={newY} />
      </svg>
    </div>
  );
};

export default CircularProgressbar;
