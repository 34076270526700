import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import NoImage from '../../assets/noimage.jpg'
import { SidebarBlogList, SidebarCategoryList, SidebarLatestFund, SidebarTitle } from '../../components/ui/sidebar/SidebarLayout';
import PostService from '../../infrastructure/api/posts/post';
import { PostListType, CategoryListType } from '../../store/post';
import CategoryService from '../../infrastructure/api/posts/category';
import useAxios from "../../hooks/useAxios";

export const BlogSidebar: React.FC = () => {
  const [posts, setPosts] = useState<PostListType[]>([]);
  const [category, setCategory] = useState<CategoryListType[]>([]);
  const axios = useAxios();
  const postService = new PostService(axios);
  const categoryService = new CategoryService(axios);

  useEffect(() => {
    postService
      .getPosts("blog", 1, 10)
      .then((data) => { setPosts(data.data.slice(0, 3)) });

    categoryService
      .getCategoryList("blog")
      .then((data) => { setCategory(data.slice(0, 3)) });
  }, []);

  return (
    <aside className="w-full col-span-2 sm:col-span-1">
      <Link to="" className="my-5 sm:my-0">
        <SidebarLatestFund title="最新ファンド情報" image={NoImage} />
      </Link>
      <SidebarTitle title="人気の記事">
        {posts.map((data, index) => (
          <SidebarBlogList key={index} {...data} />
        ))}
      </SidebarTitle>
      <SidebarTitle title="カテゴリー">
        {category.map((data, index) => (
          <SidebarCategoryList key={index} {...data} />
        ))}
      </SidebarTitle>
    </aside>
  )
}
