
import React, { useEffect, useState } from 'react';
import { Breadcrumb } from '../../components/ui/Breadcurmb';
import { ContentLayout } from "../../components/layouts/ContentLayout";
import { ContainerLayout } from "../../components/layouts/ContainerLayout";
import { ArticleContain } from '../../components/ui/blogs/ArticleContain';
import { PrevNext } from '../../components/ui/pagination/PrevNext'
import { BlogListItem } from '../../components/ui/blogs/BlogListItem';
import { BlogSidebar } from './blogSidebar';
import { useParams } from 'react-router-dom';
import PostService from '../../infrastructure/api/posts/post';
import { PostType, PostListType, PostPaginationType } from '../../store/post';
import { MainContainerLayout } from '../../components/layouts/MainContainerLayout';
import useAxios from "../../hooks/useAxios";

export const BlogDetail: React.FC = () => {
  const { slug } = useParams();
  const [currentPost, setCurrentPost] = useState<PostType | undefined>();
  const [allPosts, setAllPosts] = useState<PostPaginationType>();
  // TODO: fetch with blog next and previous posts
  const postService = new PostService(useAxios());
  const currentIndex = 1
  const previousPost = {
    title: "",
    slug: "",
    media_url: ""
  }
  const nextPost = {
    title: "",
    slug: "",
    media_url: ""
  }

  useEffect(() => {
    if (slug) {
      postService
        .getPost("blog", slug)
        .then((data) => {
          setCurrentPost(data);
        })
        .catch(() => {
          // TODO: redirect to 404 page
        });
    }
    postService
      .getPosts("blog", 1, 2) // Fetch all posts
      .then((data) => {
        setAllPosts(data);
      })
      .catch(() => {
        // TODO: handle error
      });

  }, [slug]);

  //the breadcrumb items
  const breadcrumbItems = [
    { url: '/', name: 'HOME', index: 0, isLast: false },
    { url: '/blog', name: 'ブログ', index: 1, isLast: false },
    {
      url: '',
      name: currentPost?.title || '', // post title
      index: 2,
      isLast: true,
    },
  ];
  return (
    <MainContainerLayout>
      <Breadcrumb items={breadcrumbItems} />
      <ContainerLayout>
        <ContentLayout className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 sm:gap-8 md:gap-12 lg:gap-18">
          <div className="col-span-2">
            <ArticleContain key={currentIndex} {...currentPost} />
            <PrevNext previousPost={previousPost} nextPost={nextPost} />
            <div className="pt-10 pb-18">
              <div className="flex content-center items-center -10">
                <p className="w-24 text-lg font-bold">関連記事</p>
                <div className="h-1 bg-grey-boarder w-full"></div>
              </div>
              <BlogListItem posts={[]} />
            </div>
          </div>
          <BlogSidebar />
        </ContentLayout>
      </ContainerLayout>
    </MainContainerLayout>
  );
};

