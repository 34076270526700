import React from "react";

interface RightArrowProps {
  width: number;
  step?: string;
  borderTop?: number;
  borderBottom?: number;
  top?: number;
  bottom?: number;
}

export const Ribbon: React.FC<RightArrowProps> = ({
  width,
  step,
  borderTop = '0',
  borderBottom = '0',
  top = '0',
  bottom = '0'

}) => {
  const half = width / 2;
  const quarter = half / 2;
  const hypotenuse = Math.round(half * Math.SQRT2 * 10) / 10;
  const x = Math.round(hypotenuse / 2 * 100) / 100 - quarter;

  return (
    <div className="relative">
      <div className={`relative h-full border-l-[1px] border-r-[1px] border-grey-text-light`} style={{ width: `${width}px`, borderTop: `${borderTop}px solid`, borderBottom: `${borderBottom}px solid` }}>
        <div className={` absolute bg-grey-text-light`} style={{ height: `${top}px`, top: `${quarter}px`, right: `-${x + 0.8}px`, width: `${hypotenuse}px`, rotate: "z -45deg" }}></div>
        <div className={` absolute bg-grey-text-light`} style={{ height: `${top}px`, top: `${quarter}px`, left: `-${x + 0.8}px`, width: `${hypotenuse}px`, rotate: "z 45deg" }}></div>
        <div className={` absolute bg-grey-text-light`} style={{ height: `${bottom}px`, bottom: `-${quarter}px`, right: `-${x + 0.8}px`, width: `${hypotenuse}px`, rotate: "z -45deg" }}></div>
        <div className={`absolute bg-grey-text-light`} style={{ height: `${bottom}px`, bottom: `-${quarter}px`, left: `-${x + 0.8}px`, width: `${hypotenuse}px`, rotate: "z 45deg" }}></div>
        <div className="flex flex-col items-center leading-6 pt-8">
          <p className="text-grey-text-light text-xs uppercase">step</p>
          <p className="font-bold text-2xl leading-5">{step}</p>
        </div>
      </div >
    </div >
  );
};