import React, { useEffect } from 'react';
import { StepperStepsProps } from "../../../store/stepper";
import {useStepper} from "../../../hooks/useStepper";


export const StepperSteps = function ({ children }: { children: React.ReactNode }) {
  const { currentStep, steps, setSteps } = useStepper();

  useEffect(() => {
    const stepperSteps = React.Children.toArray(children)
      .filter(step => {
        return (
          React.isValidElement(step) &&
          (step.type as React.JSXElementConstructor<StepperStepsProps>).name === 'StepperStep'
        );
      })
      .map(step => (step as React.ReactElement<StepperStepsProps>).props);
    setSteps(stepperSteps);
  }, [setSteps]);

  return (
    <div>
      {children &&
        React.Children.map(children, (child) => {
          if (steps.length) {
            return React.isValidElement(child) && child?.props.id === steps[currentStep].id
              ? child
              : null;
          } else {
            return null;
          }
        })}
    </div>
  );
};

export const StepperStep = function ({ children }: StepperStepsProps) {
  return <>{children}</>;
};
