import React from "react";
import axios from "axios";
import {
	REGISTRATION_INITIAL_STATE,
	RegistrationT,
} from "../../components/ui/RegistrationForm";
import AuthService from "../../infrastructure/api/users/auth";
import BaseAuth from "../../components/ui/BaseAuth";

const SSOAuth:React.FC = () => {

	const authService = new AuthService();

	const initialCallback = (handleInitial: (data: RegistrationT) => void) => {
		axios.get(`${process.env.REACT_APP_GATEWAY_URL}/sso`, {
			withCredentials: true
		})
			.then((res) => {
				if (handleInitial) {
					handleInitial({...REGISTRATION_INITIAL_STATE, ...res.data});
				}
			})
			.catch((error) => {
				if (error.response.status === 401){
					window.location.assign(`${process.env.REACT_APP_GATEWAY_URL}/sso/google`);
				}else {
					console.error(error);
				}
			});
	};
	
	return (
		<BaseAuth service={authService.googleRegistration} callback={initialCallback} />
	)
}

export default SSOAuth;
