import React from 'react';
import { Link } from 'react-router-dom'
import CircularProgressbar from './CircularProgressbar';
import { Calender } from '../../../components/icons/Calender';
import { ShareIcon } from '../../../components/icons/ShareIcon';
import { Envelope } from '../../../components/icons/Envelope';
import {durationChoice, FundDetailsType} from "../../../store/fund";
import {convertToJapaneseDate} from "../../../utils/date";
import {numberWithCommas} from "../../../utils/numbers";

interface FundDataProps{
  fund:FundDetailsType
}

export const FundSidebar: React.FC<FundDataProps> = (props) => {
  const { fund } = props;
  const totalInterest = fund
    ? (fund.capital_interest || 0) + (fund.income_interest || 0)
    : 0;
  if (!fund) {
    return null;
  }
  return (
    <aside className="w-full col-span-1 md:col-span-1">
      <div className="border-t-2 flex justify-between py-3">
        <div className="px-2 flex flex-col justify-between">
          <p className="text-sm">募集金額</p>
          <div>
            <p className="font-bold text-lg">{numberWithCommas(fund.total_applied_units * (fund.investment_amount || 0) / 10000) || 0}万円 / <span className="text-xs font-normal">{numberWithCommas(fund.amount_raised)}万円</span></p>
            <span className="text-xs">(1口{(fund.investment_amount || 0) / 10000}万円 *{fund.min_num_units}口〜{`${fund.max_num_units}口` || "上限なし"})</span>
          </div>
        </div>
        <div className="mt-5">
          <CircularProgressbar  />
        </div>
      </div>
      <div className="border-t-2 py-3">
        <div className="px-2 flex justify-between">
          <p className="text-sm">想定利回り <br />（年利）</p>
          <p className="text-3xl font-bold text-primary-main">{totalInterest}<span>%</span></p>
        </div>
        <div className="bg-grey-bg p-2 text-center rounded text-xs">
          <p className="border-b-2 p-1">キャピタルゲイン相当： <span>{fund.capital_interest}%</span></p>
          <p className="p-1">インカムゲイン相当：<span>{fund.income_interest}%</span></p>
        </div>
      </div>
      <div className="border-t-2 py-3">
        <div className="px-3 flex justify-between">
          <p className="text-sm">想定運用期間</p>
          <span className="text-end">
            <p className="font-bold text-sm">{fund.duration}{durationChoice[fund.duration_type]}</p>
            <p className="text-[10px] text-grey-text-light font-normal -mt-1.5">{convertToJapaneseDate(fund.operation_start)}-{convertToJapaneseDate(fund.operation_end)}</p>
          </span>
        </div>
      </div>
      {/* TODO:recruitment */}
      <div className="border-t-2 py-3">
        <div className="px-3 flex justify-between">
          <p className="text-sm">募集期間</p>
          <span className="text-end font-bold text-sm flex flex-col items-end">
            <p className="  text-secondary-main p-1 border border-secondary-main w-24">抽選＆先着</p>
            <p>2023年7月1日 12:00</p>
            <p>〜 2023年7月10日 17:00</p>
          </span>
        </div>
      </div>

      <div className="border-t-2 py-3">
        <div className="grid grid-cols-2">
          <Link to="" className="flex items-center border-r pr-2"><Calender /><small>カレンダーに追加 ▼</small></Link>
          <Link to="" className="flex items-center pl-2"><ShareIcon /><small>シェアする ▼</small></Link>
        </div>
      </div>
      <div className="border-t-2">
        <div className="py-3">
          <Link to="" className="flex items-center"><Envelope /><small className="text-sx">クーリングオフによる契約解除について</small></Link>
          <Link
            to=""
            className=" grid items-center border-4 border-accent-main w-full h-12 mt-4 text-center text-accent-main font-bold rounded-md"
          >キャンペーンの詳細はこちら</Link>
        </div>
      </div>
    </aside >
  )
}
