import {RegistrationT} from "../../../components/ui/RegistrationForm";
import {SigninStateType} from "../../../pages/entry/signin";
import {getCookie} from "../../../utils/cookies";
import {initialAuthType} from "../../../store/auth";
import axios from "../axios";
import {EmailSignupT} from "../../../store/user";

class AuthService{
	login(data: SigninStateType) {
		return axios.post(
			`/api/v1/user/users/session`,
			data,
			{
				withCredentials: true,
				headers: {
					"Content-Type": "application/json"
				}
			}
			)
			.then((res) => res.data)
			.catch((error) => {
				throw error.request.response;
			})
	}
	googleRegistration(data: RegistrationT) {
		return axios.post(
			`/api/v1/user/users/signup/sso`,
			data,
			{
				withCredentials: true,
				headers: {
					"Content-Type": "application/json"
				}
			}
			)
			.then((res) => res.data)
			.catch((error) => {
				throw error.request.response;
			})
	}
	emailRegistration(data: EmailSignupT) {
		return axios.post(
			`/api/v1/user/users/signup/email`,
			data,
			{
				withCredentials: true,
				headers: {
					"Content-Type": "application/json"
				}
			}
			)
			.then((res) => res.data)
			.catch((error) => {
				throw error.request.response;
			})
	}
	userRegistrationByEmail(data: RegistrationT, token?: string) {
		return axios.post(
			`/api/v1/user/users/signup/email/${token}`,
			data,
			{
				withCredentials: true,
				headers: {
					"Content-Type": "application/json"
				}
			}
			)
			.then((res) => res.data)
			.catch((error) => {
				throw error.request.response;
			})
	}

	refreshToken() {
		return axios.post(`/api/v1/user/users/session/refresh`,
			{},
			{
				withCredentials: true,
				headers: {
					'X-CSRF-Token': getCookie('csrf_refresh_token')
				}
			}
			)
			.then((res) => res.data)
			.catch((err) => { return initialAuthType })
	}
}

export default AuthService;
