import { OutputData } from "./editor";
import {PostListType} from "./post";

interface PromotionInitType {
  id?: string;
  title?: string;
  status?: string;
}

interface PromotionDateType {
  start?: string;
  end?: string;
}
interface MediaType {
  media_url?: string;
}
export interface RuleType {
  startFrom?: string;
  point?: number;
  pointPercentage: string;
  other?: string;
}
interface contentType {
  content?: OutputData;
  rules?: RuleType[]
}
export interface PromotionListType extends PromotionInitType, PromotionDateType, MediaType {
}

export interface PromotionPaginationType {
  count: number;
  data: PromotionListType[];
}

export interface PromotionDetailType extends contentType, PromotionListType {
  data?: PromotionListType[];
}

export type RecruitmentType = {
  recruitment_type: 'first_come_basis' | 'drawing';
  start: string;
  end: string;
  result_date?:string;
}
type ObjectData = {
  label: string;
  value: string;
}

export type ImagesType ={
  id: number;
  media_url: string;
}

export interface GoogleMapType {
  center: google.maps.LatLngLiteral | null;
  zoom: number | null;
}

export type InformationType = {
  object_name: string;
  object_data: ObjectData[];
  neighborhood_information: OutputData;
  neighborhood_images: ImagesType[];
  object_images: ImagesType[];
  google_map: GoogleMapType;
}

export type OperatorType = {
  id?: number;
  name?: string;
  url: string;
  body: ObjectData[];
}
export type DistributionType = {
  order: string;
  start?: string;
  end?: string;
  receive_date?: string;
}

export type FundListType = {
  slug?: string;
  name: string;
  amount_raised: number;
  operation_start: string;
  operation_end: string;
  income_interest: number;
  capital_interest?: number;
  current_status: string;
  duration: string;
  duration_type: 'yearly' | 'monthly';
  thumbnail?:string;
  total_applied_units: number;
  current_recruitment?: RecruitmentType;
  next_recruitment?: RecruitmentType;
}

export interface FundPaginationType {
  count: number;
  data: FundListType[];
}

export type FundDetailsType = FundListType & {
  id: string;
  investment_amount?: number | null;
  subordinated_investment?: number | null;
  total_investment?: number | null;
  number_of_units?: number | null;
  max_num_units?: number | null;
  min_num_units?: number | null;
  total_distributions?: number | null;
  fund_overview: OutputData | null;
  recruitment?: RecruitmentType[];
  images: ImagesType[];
  total_invested_amount: number;
}

export interface FundDataListProps {
  fund: FundDetailsType
}

export const durationChoice = {
  yearly: '年間',
  monthly: 'ヶ月'
}

export const recruitmentTypeChoice = {
  first_come_basis: '先着',
  drawing: '抽選'
}
