import React from "react";
import {Link, Outlet} from "react-router-dom";
import {Twitter} from "../icons/Twitter";
import {Instagram} from "../icons/Instagram";
import {Facebook} from "../icons/Facebook";


interface FooterLinkType {
  name?: string;
  url: string;
}

const navLinks: FooterLinkType[] = [
  {name: "トップページ", url: "/"},
  {name: "初めての方へ", url: "/beginners"},
  {name: "ファンド一覧", url: "/fund"},
  {name: "ブログ", url: "/blog"},
  {name: "運用レポート", url: "/report"},
  {name: "お知らせ", url: "/news"},
  {name: "よくある質問", url: "/faq"},
  {name: "お問い合わせ", url: "/contact"},
  {name: "運営会社", url: "/company"},
  {name: "業務管理者名簿", url: "/business-manager"},
  {name: "個人情報保護方針", url: "/privacy-policy"},
  {name: "サービス利用規約", url: "#"},
  {name: "ポイント利用規約", url: ""},
  {name: "反社会勢力に対する基本方針", url: "#"},
  {name: "電子情報処理組織の管理に関する基本方針", url: "#"},
  {name: "誘致方針", url: "#"},
  {name: "クーリング・オフ及び中途解約の流れ", url: "#"}
]

const FooterLink: React.FC<FooterLinkType> = ({name, url}) => {
  return (
    <Link className="text-sm" to={url}>
      - {name}
    </Link>
  )
}

export const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();
  return (
    <React.Fragment>
      <Outlet/>
      <footer className="w-full pt-10">
        <div className="container mx-auto px-2 py-5 xl:w-[1280px]">
          <div className="flex flex-col lg:flex-row justify-between">
            <div className="pr-10 w-full mb-5 sm:mb-0 lg:w-3/5">
              <div className="py-4">
                <span className="text-xs txt-grey-text-main">運営会社</span>
                <h4 className="text-base font-bold">創建住販株式会社</h4>
                <p className="text-sm py-0.5 txt-grey-text-main">〒260-0843 千葉市中央区末広3-3-9 創建第一ビル</p>
                <p className="text-sm py-0.5 txt-grey-text-main">TEL:043-262-7767(土日祝・年末年始を除く10:00-17:00
                  )</p>
                <p className="text-sm py-0.5 pb-1 txt-grey-text-main"> 代表取締役：安達 勉 / 業務管理者：山田 太郎</p>
                <p className="text-sm py-0.5 txt-grey-text-main">不動産特定共同事業許可番号 : 千葉県知事第1号</p>
                <p className="text-sm py-0.5 txt-grey-text-main">当社は、不動産特定共同事業者(第1号及び第2号)です。</p>
                <p className="text-sm py-0.5 txt-grey-text-main">また、電子取引業務を行います。</p>
              </div>
              <div className="flex py-2 justify-center items-center">
                <Link to="">
                  <Twitter/>
                </Link>
                <Link to="">
                  <Instagram/>
                </Link>
                <Link to="">
                  <Facebook/>
                </Link>
              </div>
            </div>
            <div className="w-full mt-10 lg:w-2/5 md:mt-0">
              <div className="flex justify-between w-full mb-2">
                <ul className="flex flex-col w-52">
                  {navLinks.slice(0, 8).map((value, index) => (
                    <li key={index}><FooterLink {...value} /></li>
                  ))}
                </ul>
                <ul className="flex flex-col w-80">
                  {navLinks.slice(8).map((value, index) => (
                    <li key={index}><FooterLink {...value} /></li>
                  ))}
                </ul>
              </div>
              <div
                className="border-t-2 border-grey-border flex flex-col pt-3 sm:pt-0 sm:flex-row items-center lg:block">
                <ul className="flex max-[415px]:flex-col justify-center items-center w-full">
                  <li className="m-5">
                    <Link to="/signin"
                          className="flex w-[160px] items-center justify-center py-2 border-2 border-accent-main text-accent-main rounded font-bold w-full">ログイン</Link>
                  </li>
                  <li className="my-5 ">
                    <Link to="/signup"
                          className="flex w-[160px] items-center justify-center py-2 bg-accent-main border-accent-main border-2 text-primary-text rounded ">会員登録</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="flex flex-row justify-between mt-2">
            <p className="pr-10 w-3/4 text-xs text-grey-text-light">
              © {currentYear} MitsubachiFund All Rights Reserved.
            </p>
            <div className="pr-0 w-3/4 text-right text-xs text-grey-text-light">
              退会をご希望の方は <Link to="" className="text-inherit underline">こちら</Link>から。
            </div>
          </div>
        </div>
      </footer>
    </React.Fragment>
  )
}
