  import React, {useEffect, useState} from "react";
import { Link, useNavigate } from 'react-router-dom'
import { Button } from "../../components/ui/Button";
import { TextField } from "../../components/ui/inputs/TextField";
import { PasswordField } from "../../components/ui/inputs/PasswordField";
import { DividerWIthText } from "../../components/ui/DividerWIthText";
import { Google } from "../../components/icons/Google";
import AuthService from "../../infrastructure/api/users/auth";
import { errorConverter, FastapiErrorT } from "../../utils/fastapi";
import { SigninLayout } from "../../components/layouts/SigninLayout";
import { Form } from "../../components/ui/Form";
import useForm, { FormHook } from "../../hooks/useForm";
import {useAuth} from "../../hooks/useAuth";
import {deleteCookie, getCookie} from "../../utils/cookies";
import {useToast} from "../../hooks/useToast";


export type SigninStateType = {
	email: string;
	password: string;
};

const LoginForm: React.FC<FormHook<SigninStateType, FastapiErrorT>> = ({
	inputValues,
	handleChange,
	handleSubmit,
	errors
}) => {
	return (
		<Form errors={errors} handleSubmit={handleSubmit}>
			<TextField
				type="email"
				name="email"
				value={inputValues.email}
				className="p-2"
				label="メール"
				onChange={handleChange}
				required={true}
				errors={errorConverter(errors?.detail, "email")}
			/>
			<PasswordField
				name="password"
				value={inputValues.password}
				label="パスワード"
				onChange={handleChange}
				required={true}
				errors={errorConverter(errors?.detail, "password")}
			/>
			<div className="text-right flex flex-col gap-2">
				<Button type="submit" className="p-4 bg-accent-main text-primary-text font-semibold">ログイン</Button>
				<a href="src/pages/entry/signin#" className="text-xs text-secondary-light">パスワードを忘れた場合</a>
			</div>
		</Form>
	)
}

const Signin: React.FC = () => {
	const { isAuthenticated, setAuth } = useAuth();
  const toast = useToast();
  const [emailValidation, setEmailValidation] = useState<boolean | null>(null);
	const navigate = useNavigate();
	const authService = new AuthService();

	const {
		inputValues,
		handleChange,
		errors,
		handleSubmit
	} = useForm<SigninStateType, FastapiErrorT>({ email: '', password: '' }, (values, setErrors) => {
		authService.login(values)
			.then((data) => {
				setAuth(data);
			})
			.catch((error) => {
				setErrors(JSON.parse(error));
			})
	});

  useEffect(() => {
    const emailCookie =  getCookie('_email_validated');
    if (emailCookie){
      setEmailValidation(JSON.parse(emailCookie));
      deleteCookie('_email_validated');
    }
  }, []);

  if (emailValidation) {
    toast.open("メールアドレスの検証に成功しました！続行できます。", 'success');
    setEmailValidation(false);
  }

	if (isAuthenticated) navigate('/');

	return (
		<SigninLayout title={"ログイン"}>
			<LoginForm
				inputValues={inputValues}
				handleChange={handleChange}
				handleSubmit={handleSubmit}
				errors={errors}
			/>
			<DividerWIthText text="また" />
			<a href={`${process.env.REACT_APP_GATEWAY_URL}/sso/google`} className="bg-white text-grey-text font-medium p-4 border flex items-center justify-center gap-2 rounded">
				<Google />
				Googleでサインイン
			</a>
			<p className="text-xs w-full text-center my-6">
				会員登録がお済みでない方は<Link to="/signup" className="font-semibold text-primary-main underline">こちら</Link>から。
			</p>
		</SigninLayout>
	)
}

export default Signin;
