import React, {useEffect, useMemo, useState} from 'react';
import { HeaderLayout } from '../../components/ui/HeaderLayout';
import { Breadcrumb } from '../../components/ui/Breadcurmb';
import { ContainerLayout } from '../../components/layouts/ContainerLayout';
import { ContentLayout } from '../../components/layouts/ContentLayout';
import Pagination from '../../components/ui/pagination/Pagination';
import { MainContainerLayout } from '../../components/layouts/MainContainerLayout';
import FundService from "../../infrastructure/api/funds/funds";
import {FundPaginationType} from "../../store/fund";
import useAxios from "../../hooks/useAxios";
import {useSearchParams} from "react-router-dom";
import FundListComponent from "../../components/ui/funds/FundListItem";


const breadcrumbItems = [
  { url: '/', name: 'HOME'},
  { url: '/fund', name: 'ファンド一覧' },
];

const fundPerPage = 6;

export const FundList: React.FC = () => {
  const [params, setParams] = useSearchParams()
  const [funds, setFunds] = useState<FundPaginationType>({count: 0, data: []})
  const fundService = new FundService(useAxios());
  const currentPage = useMemo(() => parseInt(params.get("page") || '1'), [params])

  useEffect(() => {
    fetchFunds();
  }, [currentPage]);

  const fetchFunds = () => {
    fundService.getFundList(currentPage, fundPerPage).then((data) => { setFunds(data); })
	}

  const setCurrentPage = (page: number) => {
    setParams({ page: page.toString() });
  }

  return (
    <MainContainerLayout>
      <HeaderLayout title="ファンド一覧" subTitle="Funds" />
      <Breadcrumb items={breadcrumbItems} />
      <ContainerLayout>
        <ContentLayout>
            <FundListComponent funds={funds.data} />
        </ContentLayout>
        <Pagination
          currentPage={currentPage}
          perPage={fundPerPage}
          total={funds.count}
          maxLength={7}
          setCurrentPage={setCurrentPage}
          className="mt-10"
        />
      </ContainerLayout>
    </MainContainerLayout>
  )
}
