import React from "react";
import { HeaderLayout } from '../components/ui/HeaderLayout';
import { Breadcrumb } from '../components/ui/Breadcurmb';
import { ContainerLayout } from "../components/layouts/ContainerLayout";
import { ContentLayout } from "../components/layouts/ContentLayout";
import { MainContainerLayout } from "../components/layouts/MainContainerLayout";

const breadcrumbItems = [
  { url: '/', name: 'HOME', index: 0, isLast: false },
  { url: '/privacy-policy', name: '個人情報保護方針', index: 1, isLast: true },
];

export const PrivacyPolicy: React.FC = () => {
  return (
    <MainContainerLayout>
      <HeaderLayout
        title="個人情報保護方針"
        subTitle="privacy policy"
      />
      <Breadcrumb
        items={breadcrumbItems}
      />
      <ContainerLayout>
        <ContentLayout>
          <ul className="flex flex-col items-end text-xs">
            <li>制定年月日　2023年xx月xx日 </li>
            <li>最終改正年月日　2023年xx月xx日</li>
            <li> 創建住販株式会社 </li>
            <li> 代表取締役　安達 勉</li>
          </ul>
          <br></br>
          <div className="pb-3 text-sm">
            <p>創建住販株式会社（以下「当社」といいます）は、皆様の個⼈情報を適切に保護することが社会的責務として重要と考えております。当社は、当社が運営するウェブサイト「みつばちファンド」（以下「本ウェブサイト」といいます）上で提供するサービス（以下「本サービス」といいます）の全ての利⽤者（以下「ユーザー」といいます）の個⼈情報を、このプライバシーポリシーに基づき取り扱います。 </p>
            <p>ユーザーが本サービス利⽤するためには、このプライバシーポリシーの内容に同意していただく必要があります。</p>
          </div>
          <div className="py-5">
            <h3 className="border-b-2 border-grey-border mb-2 pb-2 font-bold text-lg">1. 法令遵守</h3>
            <p className="text-sm">当社は、個⼈情報保護の重要性を認識し、個⼈情報保護の実現のため、個⼈情報の保護に関する法律（以下「個⼈情報保護法」といいます）、同法にかかるガイドラインその他関連する法令等を遵守します。</p>
          </div>
          <div className="py-5">
            <h3 className="border-b-2 border-grey-border mb-2 pb-2 font-bold text-lg">2. 法令遵守</h3>
            <p className="text-sm">当社は、個⼈情報保護の重要性を認識し、個⼈情報保護の実現のため、個⼈情報の保護に関する法律（以下「個⼈情報保護法」といいます）、同法にかかるガイドラインその他関連する法令等を遵守します。</p>
          </div>


        </ContentLayout>
      </ContainerLayout>
    </MainContainerLayout>
  )
}