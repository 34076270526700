import React, {useEffect} from 'react';
import Title from '../../../../components/layouts/Title';
import {Simulation} from "../Simulation";
import {DistributionType, durationChoice, OperatorType} from "../../../../store/fund";
import FundService from "../../../../infrastructure/api/funds/funds";
import {TableData, TableHeader} from "../../../../components/layouts/TableLayout";
import {convertToJapaneseDate, getDifferenceInDays, parseJapaneseDateTime} from "../../../../utils/date";

interface DistributionProps {
  data: DistributionType[] | null;
  fundService: FundService;
  setData: (data: DistributionType[]) => void;
  fundId: string;
  durations: string;
  durationTypes: 'yearly' | 'monthly';
  operationStart: string;
  operationEnd: string;
  percentage: number;
}

export const ReturnValue: React.FC<DistributionProps> = ({
                                                           data,
                                                           fundService,
                                                           setData, fundId,
                                                           durations,
                                                           durationTypes,
                                                           percentage,
                                                           operationStart,
                                                           operationEnd
}) => {
  useEffect(() => {
    if (!data) {
      fundService
        .getFundDistribution(fundId)
        .then((responseData) => {
          setData(responseData);
        })
    } else if (data.length > 0) {
    }
  }, [data]);
  if (data === null) return <>loading</>;
  return (
    <div>
      <div className="py-10">
        <Title name="投資シミュレーション（運用満了後）"/>
        <div className="grid lg:grid-cols-2 lg:gap-20">
          <div>
            <Simulation percent={percentage} totalDays={getDifferenceInDays(operationStart, operationEnd)}/>
          </div>
          <div><p>同じようなファンドに定期的に投資した場合の資産の増え方</p></div>
        </div>
      </div>
      <div className="py-10">
        <Title name="配当スケジュール"/>
        <div className="grid lg:grid-cols-2 lg:gap-20">
          <div className="w-full">
            <TableHeader>
              <TableData name={"運用期間"}>
                {durations}{durationChoice[durationTypes]}
                <p
                  className="text-xs">{convertToJapaneseDate(operationStart || "")}~{convertToJapaneseDate(operationEnd || "")}</p>
              </TableData>
              <TableData name={"配当日"}>
                {data.map((obj, index) => (
                  <div className="border-b last:border-0 border-dashed p-1">
                    <p className="text-xs">{obj.order + 1}回目 {index === data.length - 1 ? '+ 元本償還' : ''} </p>
                    <p>{convertToJapaneseDate(obj.receive_date || "")}</p>
                  </div>
                ))}
              </TableData>
            </TableHeader>
          </div>
          <div className="w-full">
            <p>ただし、不動産市況その他の社会経済的情勢を踏まえ、
              運用期間の終了予定日以前に対象物件の売却を完了し不動産取引を終了することが妥当と考えられる場合には、
              本事業者の判断で運用期間終了日を早める可能性があります。
              また、運用期間内に対象不動産の売却等が完了しない場合に、本事業者は、運用期間を延長することがあります。</p>
          </div>
        </div>
      </div>
      <div className="py-10">
        <Title name="注意事項"/>
        <h3 className="font-semibold">確定申告が必要になる場合は、税理士または所轄の税務署へお問い合わせください。</h3>
        <ul>
          <li>※元本、配当金は1口当たり1円未満切り捨てとなります。</li>
          <li>※記載の金額は、源泉税控除前の金額となります。実際に投資家の皆様へ支払う金額は、源泉徴収税20.42%（所得税+復興特別所得税）を控除した金額となり
            ます。
          </li>
          <li> ※記載する数値に関しては現在の想定であり、数値は変更となる可能性があります。</li>
          <li> ※利益が生ずることが確実であることを保証するものではございません。</li>
        </ul>
      </div>
    </div>
  )
}
