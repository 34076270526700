import React, { useState } from "react";
import { Eye, EyeSlash } from "../../icons";
import { PasswordInputType } from "./types";
import { InputLayout } from "../../layouts/InputLayout";

export const PasswordField: React.FC<PasswordInputType> = ({
	name,
	value,
	className = "",
	label = "",
	errors = [],
	placeholder = "",
	onChange = () => { },
	required = false,
	helpText,
	...props
}) => {
	const [show, setShow] = useState(false);

	return (
		<InputLayout label={label} errors={errors} >
			<div className="relative">
				<input
					type={show ? "text" : "password"}
					name={name}
					value={value}
					onChange={onChange}
					id={`id_${name}`}
					placeholder={placeholder}
					required={required}
					className={(errors?.length > 0 ? "border-error " : "") + "w-full border border-grey-boarder p-2 rounded " + className}
					{...props}
				/>
				<span
					onClick={() => setShow(prev => !prev)}
					className="cursor-pointer absolute inset-y-0 right-0 flex items-center px-2 text-gray-500 hover:text-gray-700 focus:outline-none focus:text-gray-700"
				>
					{show ? <EyeSlash /> : <Eye />}
				</span>
			</div>
			<p className="text-xs text-grey-text-light">{helpText}</p>
		</InputLayout>
	)
}
