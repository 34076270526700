export const japaneseWeekAbbreviations = [
    '日',
    '月',
    '火',
    '水',
    '木',
    '金',
    '土',
];

export const convertNumberWeekday = (strDate: string) => {
    /*
     Will convert weekday number in brackets to Japanese week abbreviation.
     For example: 2023-07-14(5) -> 2023-07-14(金)
    */
    // Regular expression to match a number between parentheses
    const regex = /\((\d+)\)/;

    // Find first match
    const match = regex.exec(strDate);

    if (match) {
        const numberWeekday = parseInt(match[1]);
        if (numberWeekday > 6)
            return strDate;

        return strDate.replace(regex, `(${japaneseWeekAbbreviations[numberWeekday]})`);
    }

    return strDate;
};


export const getMonthsUntilNextYear = () => {
    const today = new Date();
    const yearWithMonths: { [key: string]: number[] } = {};

    for (let i = 0; i < 12; i++) {
        const date = new Date(today.getFullYear(), today.getMonth() + i, 1);
        const month = date.getMonth() + 1;
        const year = date.getFullYear().toString();
        if (!yearWithMonths[year])
            Object.assign(yearWithMonths, {[year]: []});
        yearWithMonths[year].push(month);
    }

    return yearWithMonths;
};


export const extractYear = (date: string) => {
    const splitDate = date.split('-');
    return splitDate[0];
};

export const extractMonth = (date: string) => {
    const splitDate = date.split('-');
    if (splitDate.length === 1)
        return date;
    return parseInt(splitDate[1]);
};


export const parseJapaneseDateTime = (datetimeStr: string) => {
    // Remove "年", "月", and "日" characters
    const cleanedStr = datetimeStr.replace(/[年月日]/g, '-');

    // Split into date and time parts
    const [datePart, timePart] = cleanedStr.split(' ');

    // Split the date part into year, month, and day
    const [year, month, day] = datePart.split('-').map(Number);

    // Split the time part into hours and minutes
    const [hours, minutes] = timePart.split(':').map(Number);
    // Create a new Date object
    // Note: Months in JavaScript Date are 0-based, so we subtract 1 from the month value
    return new Date(year, month - 1, day, hours, minutes);
};


export const isValidDateString = (dateTimeString: any) => {
  if (typeof dateTimeString === "string") {
    const dateTimeRegex = /^\d{4}-\d{2}-\d{2}$/;

    return dateTimeRegex.test(dateTimeString);
  }
  return false;
};

export const isValidDateTimeString = (datetimeString: any) => {
  const regex =
    /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(.\d{1,6}){0,2}\+\d{2}:\d{2}$/;
  return regex.test(datetimeString);
};

export const convertToJapaneseDate = (dateStr: any) => {
  if (isValidDateString(dateStr)) {
    const date = new Date(dateStr);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}年${month}月${day}日`;
  } else if (isValidDateTimeString(dateStr)) {
    const utcDatetime = new Date(dateStr);
    const tokyoDatetime = new Date(
      utcDatetime.toLocaleString("en-US", { timeZone: "Asia/Tokyo" })
    );

    const year = tokyoDatetime.getFullYear();
    const month = String(tokyoDatetime.getMonth() + 1).padStart(2, "0");
    const day = String(tokyoDatetime.getDate()).padStart(2, "0");
    const hour = String(tokyoDatetime.getHours()).padStart(2, "0");
    const minute = String(tokyoDatetime.getMinutes()).padStart(2, "0");
    const second = String(tokyoDatetime.getSeconds()).padStart(2, "0");

    return `${year}年${month}月${day}日 ${hour}:${minute}:${second}`;
  }
  return dateStr;
};

export const getDifferenceInDays = (start: string, end: string) => {
    const date1 = new Date(start);
    const date2 = new Date(end);

    const diffTimes = date2.getTime() - date1.getTime();

    return diffTimes / (1000 * 3600 * 24) + 1;
}
