import { useState, useEffect } from 'react';
import { HeaderLayout } from '../components/ui/HeaderLayout';
import { Breadcrumb } from '../components/ui/Breadcurmb';
import { ContentLayout } from "../components/layouts/ContentLayout";
import { ContainerLayout } from "../components/layouts/ContainerLayout";
import { Search } from "../components/icons/Search";
import { Close } from '../components/icons/Close';
import NoImage from '../assets/noimage.jpg'
import Popup from '../components/layouts/Popup';
import { Input } from '../components/ui/inputs/Input';
import { Button } from '../components/ui/Button';
import { MainContainerLayout } from '../components/layouts/MainContainerLayout';


const breadcrumbItems = [
  { url: '/', name: 'HOME', index: 0, isLast: false },
  { url: '/faq', name: 'よくある質問', index: 1, isLast: true },
];

const SearchBar = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchData, setSearchData] = useState<string[]>([]);

  useEffect(() => {
    const savedData = localStorage.getItem('searchData');
    if (savedData) {
      setSearchData(savedData.split(','));
    }

    window.onbeforeunload = () => {
      localStorage.removeItem('searchData');
    };
  }, []);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && searchTerm.trim() !== '') {
      e.preventDefault();
      const newData = `#${searchTerm}`;
      setSearchData(prevData => [...prevData, newData]);
      setSearchTerm('');
      updateLocalStorage([...searchData, newData]);
    }
  };

  const handleTagClose = (tag: string) => {
    const updatedData = searchData.filter(item => item !== tag);
    setSearchData(updatedData);
    updateLocalStorage(updatedData);
  };

  const handleClearAll = () => {
    setSearchData([]);
    updateLocalStorage([]);
  };

  const updateLocalStorage = (data: string[]) => {
    localStorage.setItem('searchData', data.join(','));
  };

  return (
    <div className="relative py-8 px-2 sm:p-10 bg-grey-bg">

      <span className="inset-y-0 left-0 flex items-center pr-3 mb-1">
        <Search className="w-5 h-5 text-gray-400" />
        <p className="ml-1">キーワードで検索する</p>
      </span>
      <div className="px-3 bg-white">
        <input
          type="text"
          value={searchTerm}
          onChange={handleSearch}
          onKeyDown={handleKeyDown}
          placeholder="検索したいキーワードを入力してください"
          className="w-full py-2 pr-10 pl-4 text-gray-700 placeholder-grey-light focus:outline-none"
        />
        {searchData.length > 0 && (
          <div className="text-grey-text-light border-t text-sm bg-white py-3 px-2 flex items-start justify-between w-full">
            <div>
              {searchData.map(tag => (
                <span
                  key={tag}
                  className="inline-flex items-center px-2.5 py-1 bg-grey-bg my-2 mx-2"
                >
                  {tag}
                  <button
                    type="button"
                    onClick={() => handleTagClose(tag)}
                    className="ml-1 hover:text-grey-text"
                  >
                    <Close className="h-3 w-3" color="grey" />
                  </button>
                </span>
              ))}
            </div>
            <div>
              <button
                type="button"
                onClick={handleClearAll}
                className="mx-2 my-2 p-1 text-sm w-[100px] border bg-grey-bg text-gray-500 hover:text-gray-700 focus:outline-none "
              >
                全てクリア
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const ModelForm: React.FC = () => {
  return (
    <form className="w-full flex flex-col gap-2">
      <Input label="お名前" required={true} name="" value="" type="text" />
      <Input label="メールアドレス" required={true} name="" value="" type="email" />
      <Input label="お問い合わせ内容" required={true} name="" value="" type="textarea" />
      <Button type="submit" className="w-2/4 self-center bg-grey-text-light text-primary-text p-2 text-xl">送信</Button>
    </form>
  )
}

const FaqData: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleItemClick = (index: number) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleModalButtonClicked = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    handleModalOpen();
  };

  return (
    <div>
      <h2 className="border-b-2 border-grey-text-light py-2 text-2xl font-bold">目的から探す</h2>
      <div className="mt-3">
        <div className="py-3 flex items-center border-b">
          <img className="h-12 border" src={NoImage} alt="" />
          <h3 className="pl-3 text-xl sm:text-2xl lg:text-3xl font-semibold">会員登録について</h3>
        </div>
        <ul className="flex flex-col">
          <li className="flex flex-col border-b" onClick={() => handleItemClick(0)}>
            <div className="flex py-2">
              <span className="font-bold pr-2">Q.</span>
              <h4 className="text-lg font-normal">会員登録は無料ですか？</h4>
            </div>
            <div
              className={`transition delay-1000 duration-300 ease-in overflow-hidden ${activeIndex === 0 ? 'max-h-full' : 'max-h-0'
                }`}
            >
              <article className="py-5">
                <p>Answer to the first question goes here.</p>
              </article>
              <div className="flex py-5 flex-col justify-center align-center items-center border-t">
                <h5 className="text-base font-normal">解決しましたか？</h5>
                <div className="flex flex-row">
                  <button onClick={handleModalClose} className="px-3 py-1 w-40 m-3 bg-grey-text-light  text-center rounded text-white">はい</button>
                  <button onClick={handleModalButtonClicked} className="px-3 py-1 w-40 m-3 border text-center rounded text-grey-text-light" >いいえ</button>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <Popup isOpen={isModalOpen} onClose={handleModalClose} width="w-full sm:w-3/4 lg:w-1/2 xl:w-2/5">
          <div className=" bg-white p-10 w-full">
            <span>カスタマーサポートに問い合わせる</span>
            <ModelForm />
          </div>
        </Popup>
      </div>
    </div>
  );
};

export const Faq: React.FC = () => {
  return (
    <MainContainerLayout>
      <HeaderLayout
        title="よくある質問"
        subTitle="faq"
      />
      <Breadcrumb
        items={breadcrumbItems}
      />
      <ContainerLayout>
        <ContentLayout>
          <SearchBar />
        </ContentLayout>
        <ContentLayout>
          <FaqData />
        </ContentLayout>
      </ContainerLayout>
    </MainContainerLayout>
  );
};
