import {useCallback, useContext, useMemo} from "react";
import {SET_AUTH} from "../store/constants";
import {AuthContext} from "../contexts/auth";
import {AuthType} from "../store/auth";
import AuthService from "../infrastructure/api/users/auth";

export const useAuth = () => {
	const [state, dispatch] = useContext(AuthContext);
	const {accessToken, isAuthenticated} = state;
	const authService = new AuthService();

	if (!AuthContext) {
		throw new Error('useAuth should be use inside AuthProvider');
	}

	const setAuth = useCallback((data: AuthType) => {
		dispatch({
			type: SET_AUTH,
			payload: data
		})
	}, [dispatch])

	const refreshToken = useCallback(async () => {
		const data = await authService.refreshToken();
		setAuth(data);
		return data.accessToken;
	}, [dispatch])

	return {
		accessToken,
		isAuthenticated,
		setAuth,
		refreshToken
	}
}
