import {OutputBlockData, BlockTuneData} from "../store/editor";

const editorJSHTML = require("editorjs-html");


function alignment(block: BlockTuneData) {
  if (block.tunes.anyTuneName && block.tunes.anyTuneName.alignment) {
    return `text-align:${block.tunes.anyTuneName.alignment}`;
  }
  return "";
}

function headingParser(block: BlockTuneData) {
  const level = block.data.level;
  return (`
    <h${level} style="${alignment(block)}"> ${block.data.text}</h${level}>
  `)
}

function paragraphParser(block: BlockTuneData) {
  return (`
    <p style="${alignment(block)}">${block.data.text}</p>
  `)
}

interface ListType {
  content: string;
  items: ListType[];
}

function listConverter(data: ListType, tag: "ol" | "ul"): string {
  return `<li class="cdx-nested-list__item">
    <div class="cdx-nested-list__item-body">
    <div class="cdx-nested-list__item-content">
    ${data.content}
    </div>
    ${data.items && `<${tag} class="cdx-nested-list cdx-nested-list__item-children cdx-nested-list--${tag === 'ol' ? 'ordered' : 'unordered'}">
      ${data.items.map((obj) => listConverter(obj, tag)).join('')} </${tag}>`
  }
    </div>
</li>`
}

function listParser(blocks: BlockTuneData) {
  const list = blocks.data.style;
  const tag = list === 'ordered' ? 'ol' : 'ul';
  return (`
  <div class="cdx-text-variant--${blocks.tunes.textVariant}">
  <div class="ce-block__content">
    <${tag} class="cdx-nested-list cdx-block cdx-nested-list--${tag === 'ol' ? 'ordered' : 'unordered'}">${blocks.data.items.map((obj: ListType) => listConverter(obj, tag)).join("")}</${tag}>
    </div>
  </div>
  `);
}

function quoteParser(blocks: BlockTuneData) {
  const quote = blocks.type;
  const caption = blocks.data.caption
  const tag = quote === 'quote' ? 'blockquote' : '';
  return (` <${tag}> ${blocks.data.text} <div class="caption">${caption}<span></${tag}>`);
}

function linkToolParser(blocks: BlockTuneData) {
  const link = blocks.data.link
  const title = blocks.data.meta.title
  const meta = blocks.data.meta.description
  const image = blocks.data.meta.image.url
  return (`
  <div class="link-tool">
    <a class="link-tool__content link-tool__content--rendered" target="_blank" href="${link}"> 
      <div class="link-tool__image" style="background-image: url('${image}');"></div>
      <div class="link-tool__title">${title}</div>
      <p class="link-tool__description">${meta}</p>
      <span class="link-tool__anchor">${link}</span>
    </a>
  </div>
  `);
}

function attacheParser(blocks: BlockTuneData) {
  const link = blocks.data.file.url
  const title = blocks.data.title
  return (`
  <div class="cdx-attaches cdx-attaches--with-file">
  <div class="cdx-attaches__file-icon"><div class="cdx-attaches__file-icon-background"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.3236 8.43554L9.49533 12.1908C9.13119 12.5505 8.93118 13.043 8.9393 13.5598C8.94741 14.0767 9.163 14.5757 9.53862 14.947C9.91424 15.3182 10.4191 15.5314 10.9422 15.5397C11.4653 15.5479 11.9637 15.3504 12.3279 14.9908L16.1562 11.2355C16.8845 10.5161 17.2845 9.53123 17.2682 8.4975C17.252 7.46376 16.8208 6.46583 16.0696 5.72324C15.3184 4.98066 14.3086 4.55425 13.2624 4.53782C12.2162 4.52138 11.2193 4.91627 10.4911 5.63562L6.66277 9.39093C5.57035 10.4699 4.97032 11.9473 4.99467 13.4979C5.01903 15.0485 5.66578 16.5454 6.79264 17.6592C7.9195 18.7731 9.43417 19.4127 11.0034 19.4374C12.5727 19.462 14.068 18.8697 15.1604 17.7907L18.9887 14.0354"></path></svg></div></div>
  <div class="cdx-attaches__title" data-placeholder="File title">${title}</div>
  <a class="cdx-attaches__download-button" href="${link}" target="_blank" ><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24"><path stroke="currentColor" stroke-linecap="round" stroke-width="2" d="M7 10L11.8586 14.8586C11.9367 14.9367 12.0633 14.9367 12.1414 14.8586L17 10"></path></svg></a>
  </div>
      `);
}


function codeParser(blocks: BlockTuneData) {
  const code = blocks.data.code;
  return (` <div class="code"><pre><code> ${code}</code><pre></div>`);
}

function AnyButtonParser(blocks: BlockTuneData) {
  const link = blocks.data.link;
  const text = blocks.data.text;
  return (`<a class='px-10 py-2 border-2 border-accent-main text-accent-main rounded font-bold' href="${link}">${text}</a>`);
}

function warningParser(blocks: BlockTuneData) {
  const title = blocks.data.title;
  const message = blocks.data.message;
  return (`
  <div class="cdx-warning">
      <div class="warning-title">${title}</div>
      <p>${message}</p>
    </div>`);
}

function alertParser(blocks: BlockTuneData) {
  const type = blocks.data.type;
  const message = blocks.data.message;
  return (`
  <div class="ce-block__content">
    <div class="cdx-alert cdx-alert-${type}">
      <div class="cdx-alert__message">${message}</div>
    </div>
  </div>
`);
}

function delimiterParser(blocks: BlockTuneData) {
  const type = blocks.data;
  return (`
  <div class="ce-block__content">
    <div class="ce-delimiter cdx-block>
    </div>
  </div>
`);
}


function tableParser(block: OutputBlockData) {
  return (`
    <div class="tc-table__wrap" >
      <table class="tc-table" >
        <tbody>
            ${block.data.content.map(
            (val: string[]) => (
            `<tr>${val.map(
              (v: string) =>
                `<td class="tc-table__cell"><div class="tc-table__area">${v}</div></td>`
            ).join('')}</tr>`
          )).join('')
        }
        </tbody>
      </table>
    </div>`
  )
}


export const editorJSParser = editorJSHTML({table: tableParser, header: headingParser, list: listParser, quote: quoteParser, paragraph: paragraphParser, linkTool: linkToolParser, attaches: attacheParser, code: codeParser, AnyButton: AnyButtonParser, warning: warningParser, alert: alertParser, delimiter: delimiterParser});
