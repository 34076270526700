import React, {useEffect, useState} from 'react';
import Title from '../../../../components/layouts/Title';
import {TableData, TableHeader} from "../../../../components/layouts/TableLayout";
import {InformationType, OperatorType} from "../../../../store/fund";
import FundService from "../../../../infrastructure/api/funds/funds";
import {LinkIcon} from "../../../../components/icons/LinkIcon";

interface OperatorProps {
  data: OperatorType[] | null;
  fundService: FundService;
  setData: (data: OperatorType[]) => void;
  fundId: string;
}

export const Administrator: React.FC<OperatorProps> = ({data, fundService, setData, fundId}) => {
  useEffect(() => {
    if (!data) {
      fundService
        .getFundOperator(fundId)
        .then((responseData) => {
          setData(responseData);
        })
    } else if (data.length > 0) {
    }
  }, [data]);
  if (data === null) return <>loading</>;

  return (
    <div className="my-10">
      {data.map((item, index) => (
        <div className="mb-5">
        <TableHeader key={index}  title={item.name}>
          {item.body.map((bodyItem, bodyIndex) => (
            <TableData key={bodyIndex} name={bodyItem.label}>
              <p className={`${bodyIndex === 0 ? 'flex items-center' : ''}`}>{bodyItem.value}{bodyIndex === 0 && <a  href={item.url}><LinkIcon className="h-3 w-3 ml-2 text-accent-main" stroke="#EA547E" fill="#EA547E"/></a>}</p>
            </TableData>
          ))}
        </TableHeader>
          </div>
      ))}
    </div>
  );
};
