import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { HeaderLayout } from '../../components/ui/HeaderLayout';
import { Breadcrumb } from '../../components/ui/Breadcurmb';
import { ContentLayout } from "../../components/layouts/ContentLayout";
import { ContainerLayout } from "../../components/layouts/ContainerLayout";
import Pagination from '../../components/ui/pagination/Pagination';
import { Article } from '../../components/ui/blogs/ArticleList'
import { ReportSidebar } from './reportSidebar';
import { MainContainerLayout } from '../../components/layouts/MainContainerLayout';
import PostService from '../../infrastructure/api/posts/post';
import { PostPaginationType } from '../../store/post';
import { useSearchParams } from 'react-router-dom';
import useAxios from "../../hooks/useAxios";

const breadcrumbItems = [
  { url: '/', name: 'HOME', index: 0, isLast: false },
  { url: '/report', name: '運用レポート', index: 1, isLast: true },
];

const postsPerPage = 10;

export const Report: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [posts, setPosts] = useState<PostPaginationType>({ count: 0, data: [] });
  const page = useMemo(() => parseInt(searchParams.get('page') || '1'), [searchParams]);
  const postService = new PostService(useAxios());

  const fetchPosts = useCallback(() => {
    postService
      .getPosts("blog", page, postsPerPage)
      .then((data) => { setPosts(data) })
  }, [page])

  useEffect(() => {
    fetchPosts();
  }, [fetchPosts]);

  const setCurrentPage = (page: number) => {
    setSearchParams({ page: page.toString() });
  }

  return (
    <MainContainerLayout>
      <HeaderLayout title="投資に役立つ知識" subTitle="Blog" />
      <Breadcrumb items={breadcrumbItems} />
      <ContainerLayout>
        <ContentLayout className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 sm:gap-8 md:gap-12 lg:gap-18">
          <div className="col-span-2">
            <ul className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 border-y-4 border-grey-border">
              {posts.data.map((data, index) => (
                <Article key={index} index={index} totalPosts={posts.count} {...data} />
              ))}
            </ul>
            <Pagination
              total={posts.count}
              perPage={postsPerPage}
              currentPage={page}
              maxLength={7}
              setCurrentPage={setCurrentPage}
            />
          </div>
          <ReportSidebar />
        </ContentLayout>
      </ContainerLayout>
    </MainContainerLayout>
  )
}
