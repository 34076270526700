
import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import { ContentLayout } from "../components/layouts/ContentLayout";
import { ContainerLayout } from "../components/layouts/ContainerLayout";
import { Input } from '../components/ui/inputs/Input';
import { Button } from '../components/ui/Button';
import { Form } from '../components/ui/Form';
import Checkbox from '../components/ui/inputs/Checkbox';
import { PasswordField } from '../components/ui/inputs/PasswordField';
import { HeaderFooter } from '../components/layouts/HeaderFooter';


export const WithdrawApplication: React.FC = () => {
  const [checkboxes, setCheckboxes] = useState([
    { id: 1, label: '利回りが低い', checked: false },
    { id: 2, label: '魅力的な商品がない', checked: false },
    { id: 3, label: 'サイトが使いにくい', checked: false },
    { id: 4, label: '利用しなくなった', checked: false },
    { id: 5, label: '対応が良くない', checked: false },
    { id: 6, label: 'その他理由', checked: false },
  ]);
  const handleCheckboxChange = (checkboxId: number) => {
    setCheckboxes(prevCheckboxes =>
      prevCheckboxes.map(checkbox =>
        checkbox.id === checkboxId
          ? { ...checkbox, checked: !checkbox.checked }
          : checkbox
      )
    );
  };
  return (
    <HeaderFooter>
      <ContainerLayout>
        <ContentLayout className="w-1/2 py-5">
          <h1 className="text-center text-3xl font-bold pb-5">みつばちファンド退会申請</h1>
          <br />
          <p>ご本人さま確認のため、以下の入力項目を全てご入力ください。</p>
          <br />
          <Form >
            <Input label="| メールアドレス" name="" value="" type="email" placeholder="" />
            <PasswordField label="| パスワード" name="password" value="" />
            <Input label="| メールアドレス" name="" value="" type="date" placeholder="" />
            <div className="font-bold text-lg">退会理由<span className="font-normal pl-2 text-sm">*複数選択可能</span></div>
            <div className="grid grid-cols-3 gap-5">
              {checkboxes.map(checkbox => (
                <Checkbox
                  key={checkbox.id}
                  label={checkbox.label}
                  checked={checkbox.checked}
                  onChange={() => handleCheckboxChange(checkbox.id)}
                />
              ))}
            </div>
            <span className="text-sm -mb-4">「その他理由」を選択された場合、こちらに理由を入力してください。</span>
            <Input name="" value="" type="textarea" />
            <p className="text-center mt-10">本当に退会しますか？</p>
            <div className="flex items-center justify-center pb-5">
              <Link to="" className="grid items-center border-4 border-accent-main w-60 p-3 m-4 text-center text-accent-main rounded-md">いいえ</Link>
              <Button type="submit" className="w-60 self-center bg-accent-main text-primary-text p-4 text-xl">はい</Button>
            </div>

          </Form>
        </ContentLayout>
      </ContainerLayout>
    </HeaderFooter>
  );
};