import React from "react";
import { Link } from "react-router-dom";
import { HeaderLayout } from '../../components/ui/HeaderLayout';
import { Breadcrumb } from '../../components/ui/Breadcurmb';
import { ContainerLayout } from "../../components/layouts/ContainerLayout";
import { ContentLayout } from "../../components/layouts/ContentLayout";
import { TitleCenterLayout } from "../../components/layouts/TitleCenterLayout";
import { YoutubeIframe } from '../../components/ui/sns/YoutubeIframe';
import Image from '../../assets/noimage.jpg'
import BeginnerFTK from '../../assets/beginners/beginner-ftk.jpg'
import BF1 from '../../assets/beginners/beginner-feature02.png'
import BF2 from '../../assets/beginners/beginner-feature02.png'
import BF3 from '../../assets/beginners/beginner-feature03.jpg'
import MType1 from '../../assets/beginners/mitsubati-type01.png'
import MType2 from '../../assets/beginners/mitsubati-type02.png'
import MType3 from '../../assets/beginners/mitsubati-type03.png'
import MType4 from '../../assets/beginners/mitsubati-type04.png'
import Protect1 from '../../assets/beginners/beginner-protect01.jpg'
import Protect2 from '../../assets/beginners/beginner-protect02.jpg'
import Protect3 from '../../assets/beginners/beginner-protect03.jpg'
import Protect4 from '../../assets/beginners/beginner-protect03.jpg'
import Protect5 from '../../assets/beginners/beginner-protect03.jpg'
import { StepLayout } from "../../components/layouts/StepsLayout";
import { Ribbon } from '../../components/icons/Ribbon';
import { ImageLayout } from '../../components/layouts/ImageLayout';



const breadcrumbItems = [
  { url: '/', name: 'HOME', index: 0, isLast: false },
  { url: '/beginners', name: 'はじめての方へ', index: 1, isLast: true },
];

interface FundFeatureProp {
  subtitle?: string
  title?: string
  body?: string
  image?: string
}

interface KnowFundNameProp {
  title?: string
  text?: string
  image?: string
}
interface RealEstateCFProp {
  title?: string
  children: React.ReactNode;
  image?: string
  className?: string
}
const FundFeature: React.FC<FundFeatureProp> = ({
  subtitle,
  title,
  body,
  image
}) => {
  return (
    <li className="w-full mb-0 sm:w-1/2 sm:mb-5 lg:w-1/3">
      <div className=" border flex flex-col  h-full justify-between items-center m-0 sm:m-2">
        <div className=" items-center">
          <div className="mt-4 text-center">
            <span className="font-semibold">{subtitle}</span>
            <h3 className="font-semibold text-lg">{title}</h3>
          </div>
          <p className="py-3 px-4">
            {body}
          </p>
        </div>
        <div className="">
          <img className="w-52 h-52 my-4" src={image} alt="" />
        </div>
      </div>
    </li>
  )
}

const KnowFundName: React.FC<KnowFundNameProp> = ({
  title,
  text,
  image
}) => {
  return (
    <li className="w-full">
      <div className="flex flex-col items-center">
        <h5 className="font-bold">{title}</h5>
        <img className="w-52 h-52 my-3" src={image} alt="" />
        <p className="text-sm text-center">{text}</p>
      </div>
    </li>
  )
}


const RealEstateCF: React.FC<RealEstateCFProp> = ({ title, image, className = "flex-row", children }) => {
  return (
    <div className={`"${className} grid md:grid-cols-2 gap-10 items-start mb-10 "`}>
      <div className="flex justify-center flex-col w-full">
        <h3 className="font-bold text-xl p-2">{title}</h3>
        {children}
      </div>
      <div className="w-full">
        <img className="h-full w-full md:h-52 lg:object-contain xl:object-cover" src={image} alt="みつばちファンドとは？" />
      </div>
    </div >
  )
}


const ForBeginners: React.FC = () => {
  return (
    <div className="mt-20">
      <HeaderLayout title="はじめての方へ" subTitle="beginner" />
      <Breadcrumb items={breadcrumbItems} />

      {/* className, width="w-full"*/}
      <ContainerLayout>
        {/* title, className, fontSize */}
        <TitleCenterLayout title="不動産クラウドファンディングとは？" />
        {/* className  */}
        <ContentLayout>
          <div className="mx-auto w-full lg:w-[799px]">
            <p className="py-2">不動産クラウドファンディングは、「不動産特定共同事業法」の電子取引認可を受けた事業者だけが提供できるサービスです。これはダミーテキストですこれはダミーテキストですこれはダミーテキストです、これはダミーテキストですこれはダミーテキストですこれはダミーテキストですこれはダミーテキストですこれはダミーテキストですこれはダミーテキストです。</p>
            <img className="w-full h-full object-cover my-3 sm:h-48" src={BeginnerFTK} alt="みつばちファンドとは？" />
          </div>
        </ContentLayout>
      </ContainerLayout>

      <ContainerLayout>
        <TitleCenterLayout title="みつばちファンドの特徴" />
        <ContentLayout>
          <div>
            <ul className="flex flex-wrap justify-between gap-5 sm:justify-center sm:gap-0">
              <FundFeature
                subtitle="1口1万円から出資可能"
                title="少額からはじめられる"
                body="みつばちファンドの商品は全て1口1万円からはじめられるので、投資初心者の肩でも始めやすいです。これはダミーテキストですこれはダミーテキストですこれはダミーテキストですこれはダミーテキストですこれはダミーテキストですこれはダミーテキストです。"
                image={BF1} />
              <FundFeature
                subtitle="最短5分でかんたん"
                title="手軽に口座開設ができる"
                body="eKYC。口座の開設はスマホ1つで始められます。これはダミーテキストですこれはダミーテキストですこれはダミーテキストですこれはダミーテキストですこれはダミーテキストですこれはダミーテキストです。"
                image={BF2} />
              <FundFeature
                subtitle="1投資家の皆さまを守る"
                title="安全・保全性を高める取り組み"
                body="優先劣後、マスターリース、信託銀行について記載。これはダミーテキストですこれはダミーテキストですこれはダミーテキストですこれはダミーテキストですこれはダミーテキストですこれはダミーテキストです。"
                image={BF3} />
            </ul>
          </div>
          <div className="my-10 border py-7 px-3">
            <p className="text-center text-base font-bold">ファンドの名前でわかる</p>
            <h4 className="text-xl text-center font-bold">ファンドの特徴（？）</h4>
            <p className="py-10 text-center">みつばちファンドの商品名は、そのファンドの特徴に沿って名付けられています。のようなことを記載。</p>
            <div>
              <ul className="grid gap-10  sm:grid-cols-2 lg:grid-cols-4">
                <KnowFundName
                  title="みつばちファンド"
                  text="利回り5〜10％、運用期間が半年〜1年の安定型ファンド"
                  image={MType1} />
                <KnowFundName
                  title="アシナガバチファンド"
                  text="利回り5%〜x％前後、長期運用型のファンド"
                  image={MType2} />
                <KnowFundName
                  title="くまばちファンド"
                  text="利回り5%前後、短期〜中長期運用の大規模ファンド"
                  image={MType3} />
                <KnowFundName
                  title="スズメバチファンド"
                  text="利回り10〜15％の高配当・短期運用型ファンド"
                  image={MType4} />
              </ul>
            </div>
            {/* to, label, className */}
            <div className="flex flex-col items-center pt-10">
              <Link
                to=""
                className=" grid items-center border-4 border-secondary-main text-secondary-main font-bold w-72 h-12 mt-4 text-center rounded-md">
                ファンド一覧はこちら
              </Link>
            </div>
          </div>
        </ContentLayout>

        <TitleCenterLayout title="不動産クラウドファンディングとは？" />
        <ContentLayout>
          <div>
            <RealEstateCF title="1) 優先劣後システムを採用" image={Protect1} >
              <p>
                これはダミーテキストですこれはダミーテキストですこれはダミーテキストです、これはダミーテキストですこれはダ
                ミーテキストですこれはダミーテキストですこれはダミーテキストですこれはダミーテキストですこれはダミーテキストです。
              </p>
            </RealEstateCF>
            <RealEstateCF title="(2) 全てのファンドがマスターリース契約済" image={Protect2} >
              <p>
                これはダミーテキストですこれはダミーテキストですこれはダミーテキストです、これはダミーテキストですこれはダ
                ミーテキストですこれはダミーテキストですこれはダミーテキストですこれはダミーテキストですこれはダミーテキストです。
              </p>
            </RealEstateCF>
            <RealEstateCF title="(3) 信託銀行を活用した分別管理" image={Protect3} >
              <p>
                これはダミーテキストですこれはダミーテキストですこれはダミーテキストです、これはダミーテキストですこれはダ
                ミーテキストですこれはダミーテキストですこれはダミーテキストですこれはダミーテキストですこれはダミーテキストです。
              </p>
            </RealEstateCF>
            <div className="grid md:grid-cols-2 gap-10 w-full">
              <div className="grid justify-center">
                <h3 className="font-bold text-xl p-2">(4) 不正送金の防止</h3>
                <p>
                  これはダミーテキストですこれはダミーテキストですこれはダミーテキストです、これはダミーテキストですこれはダ
                  ミーテキストですこれはダミーテキストですこれはダミーテキストですこれはダミーテキストですこれはダミーテキストです。
                </p>
                <div className="w-full pt-4">
                  <img className="w-full md:h-52 object-cover" src={Protect4} alt="みつばちファンドとは？" />
                </div>
              </div>
              <div className="grid justify-center">
                <h3 className="font-bold text-xl p-2">(5) 個人情報の保護</h3>
                <p>
                  これはダミーテキストですこれはダミーテキストですこれはダミーテキストです、これはダミーテキストですこれはダ
                  ミーテキストですこれはダミーテキストですこれはダミーテキストですこれはダミーテキストですこれはダミーテキストです。
                </p>
                <div className="w-full pt-4">
                  <img className="w-full md:h-52 object-cover" src={Protect5} alt="みつばちファンドとは？" />
                </div>
              </div>
            </div>
          </div>
        </ContentLayout>
      </ContainerLayout>

      {/* TODO */}
      <ContainerLayout>
        <TitleCenterLayout title="1年運用のファンドに100万円出資した際のリターンイメージ" />
        <ContentLayout>
          <div className="grid md:grid-cols-2 gap-10 justify-between items-start">
            <div className="flex flex-col justify-center">
              <p className="font-bold text-lg">100万円を銀行預金に預けた場合と、みつばちファンドに100万円出資した場合の1年後のイメージを比べてみました。
                また、株式投資と比べた場合、ハイリターンではないものの非常に安全性が高いことが右のグラフでわかります。</p>
              <img className="h-full w-full md:hidden" src={Protect1} alt="みつばちファンドとは？" />
              <ul className="text-grey-text text-sm">
                <li className="pt-2">※シミュレーションは税引前の分配イメージです。</li>
                <li>※試算数値はあくまでもシミュレーションであり、将来の成果を約束するものではありません。</li>
                <li>※銀行定期はメガバンク数行の定期預金(1年)のデータをもとに比較しています。</li>
                <li>※シミュレーションの結果は概算値です。</li>
                <li>※2037年12月までの税金(源泉税)には、復興特別所得税が含まれます。</li>
              </ul>
            </div>
            <div className="w-full hidden md:block">
              <img className="h-full w-full " src={Protect1} alt="みつばちファンドとは？" />
            </div>
          </div>
        </ContentLayout>
      </ContainerLayout>

      <ContainerLayout>
        <TitleCenterLayout title="みつばちファンドとは？" />
        <ContentLayout>
          <YoutubeIframe
            embedId="_x7E38ULU-Y"
            width="100%" height="350"
          />
        </ContentLayout>
      </ContainerLayout>

      <ContainerLayout className="bg-grey-bg">
        <ContentLayout className="flex justify-center">
          <StepLayout />
        </ContentLayout>
      </ContainerLayout>

      <ContainerLayout >
        <ContentLayout className="pt-10">
          <div className="flex relative mt-10">
            <Ribbon step="01" width={60} borderTop={1} bottom={1} />
            <div className="w-full pl-10 mb-12">
              <h2 className="font-bold text-2xl mb-4">会員登録</h2>
              <div className="column sm:flex-row sm:flex">
                <ImageLayout className="h-44 w-44 mb-2 sm:mr-4 sm:mb-0" src={Image} alt="test" />
                <div>
                  <p className="mb-5 test-sm">
                    サイト上部の「会員登録」ボタンから会員登録をお済ませください。<br /><br />
                    これはダミーテキストですこれはダミーテキストですこれはダミーテキストですこれはダミーテキストです、これはダミーテキストですこれはダミーテキストですこれはダミーテキストです
                    これはダミーテキストですこれはダミーテキストですこれはダミーテキストですこれはダミーテキストですこれはダミーテキストですこれはダミーテキストです。
                  </p>
                  <Link to="" className="bg-accent-main p-3 pr-10 rounded text-white text-center" >会員はこちら</Link>
                </div>
              </div>
            </div>
          </div>
          <div className="flex relative mt-3">
            <Ribbon step="02" width={60} borderTop={0} top={1} bottom={1} />
            <div className="w-full pl-10 mb-12">
              <h2 className="font-bold text-2xl mb-4">事前入金＆出資申込</h2>
              <div className="column sm:flex-row sm:flex">
                <ImageLayout className="h-44 w-44 mb-2 sm:mr-4 sm:mb-0" src={Image} alt="test" />
                <p className="test-sm">
                  出資金の事前入金、出資希望ファンドへの申込の流れについて記載。<br /><br />
                  これはダミーテキストですこれはダミーテキストですこれはダミーテキストですこれはダミーテキストです、これはダミーテキストですこれはダミーテキストですこれはダミーテキストです
                  これはダミーテキストですこれはダミーテキストですこれはダミーテキストですこれはダミーテキストですこれはダミーテキストですこれはダミーテキストです。
                </p>
              </div>
            </div>
          </div>
          <div className="flex relative mt-3">
            <Ribbon step="03" width={60} borderTop={0} top={1} borderBottom={1} />
            <div className="w-full pl-10 mt-10">
              <h2 className="font-bold text-2xl mb-4">会員登録</h2>
              <div className="column sm:flex-row sm:flex">
                <ImageLayout className="h-44 w-44 mb-2 sm:mr-4 sm:mb-0" src={Image} alt="test" />
                <p className="test-sm">
                  契約成立時書面などが郵送で届くことをここに記載します。<br /><br />
                  これはダミーテキストですこれはダミーテキストですこれはダミーテキストですこれはダミーテキストです、これはダミーテキストですこれはダミーテキストですこれはダミーテキストです
                  これはダミーテキストですこれはダミーテキストですこれはダミーテキストですこれはダミーテキストですこれはダミーテキストですこれはダミーテキストです。
                </p>
              </div>
            </div>
          </div>
        </ContentLayout>
      </ContainerLayout>

      <ContainerLayout className="bg-grey-bg">
        {/* title, className, fontSize */}
        <TitleCenterLayout title="CTA" className="text-center" />
        <ContentLayout className="flex justify-center">
          <Link to="" className=" grid items-center w-60 h-12 mt-4 text-center bg-accent-main text-primary-text rounded-md" >新規会員登録はこちら</Link>
        </ContentLayout>
      </ContainerLayout>



    </div >
  );
};
export default ForBeginners;
