import React from "react";
import {Link} from "react-router-dom";
import {useAuth} from "../../hooks/useAuth";

export const Entry: React.FC = () => {
	const {isAuthenticated} = useAuth();
	return (
		<ul className="flex items-end h-full gap-4">
			{
				isAuthenticated
					?
					<>
						<li>
							<a
								href="/"
								className="bg-accent-main border-accent-main border-2 text-primary-text p-2 rounded"
							>マイページ</a>
						</li>
						<li>
							<Link
								to="/signout"
								className="p-2 border-2 border-accent-main text-accent-main rounded font-bold"
							>ログアウト</Link>
						</li>
					</>
					:
					<>
						<li>
							<Link to="/signin"
							      className="p-2 border-2 border-accent-main text-accent-main rounded font-bold">ログイン</Link>
						</li>
						<li>
							<Link to="/signup"
							      className="bg-accent-main border-accent-main border-2 text-primary-text p-2 rounded">会員登録</Link>
						</li>
					</>
			}
		</ul>
	)
}
