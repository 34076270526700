import React from 'react';

type TabProps = {
  src: string;
  alt?: string;
  className?: string;
  children?: React.ReactNode;
};

export const ImageLayout: React.FC<TabProps> = ({
  src,
  alt,
  className = '',
  children
}) => {
  return (
    <React.Fragment>
      <img className={`${className} border object-cover`} src={src} alt={alt} />
      {children}
    </React.Fragment>
  );
};
