import {
  PromotionPaginationType,
  PromotionDetailType,
  FundDetailsType, InformationType, OperatorType, DistributionType, FundPaginationType
} from "../../../store/fund";
import BaseService from "../base";

const API_URL = process.env.REACT_APP_GATEWAY_URL;

class FundService extends BaseService{
  getFundList(page: number | string, limit: number) {
    return this.axios
      .get<FundPaginationType>(API_URL + "/api/v1/user/funds/fund/" ,{ params: { page: page, limit: limit } })
      .then<FundPaginationType>((res) => {
        return res.data;
      })
      .catch((res) => {
        throw JSON.parse(res.request.response);
      });
  }

  getFundDetails(slug: string,) {
    return this.axios.get<FundDetailsType>(API_URL + `/api/v1/user/funds/fund/${slug}/`)
      .then<FundDetailsType>((res) => res.data)
      .catch((res) => { throw res; });
  }

   getFundObjectInformation(fund_slug: string,) {
    return this.axios.get<InformationType[]>(API_URL + `/api/v1/user/funds/object-information/${fund_slug}/`)
      .then<InformationType[]>((res) => res.data)
      .catch((res) => { throw res; });
  }

  getFundOperator(fund_slug: string,) {
    return this.axios.get<OperatorType[]>(API_URL + `/api/v1/user/funds/operator/${fund_slug}/`)
      .then<OperatorType[]>((res) => res.data)
      .catch((res) => { throw res; });
  }

  getFundDistribution(fund_slug: string,) {
    return this.axios.get<DistributionType[]>(API_URL + `/api/v1/user/funds/distribution/${fund_slug}/`)
      .then<DistributionType[]>((res) => res.data)
      .catch((res) => { throw res; });
  }


  getPromotions(page: number, limit: number) {
    return this.axios.get<PromotionPaginationType>(API_URL + `/api/v1/user/funds/promotion/`, { params: { page: page, limit: limit } })
      .then<PromotionPaginationType>((res) => res.data)
      .catch((res) => { throw res; });
  }


  getPromotion(pk: number,) {
    return this.axios.get<PromotionDetailType>(API_URL + `/api/v1/user/funds/promotion/${pk}/`)
      .then<PromotionDetailType>((res) => res.data)
      .catch((res) => { throw res; });
  }
}

export default FundService;
