import React, { useReducer } from 'react';
import {defaultLoading, LoadingActionType, loadingReducer, LoadingType} from '../store/loading';

type LoadinContextType = [
  LoadingType,
  React.Dispatch<LoadingActionType>
]

export const LoadingContext = React.createContext<LoadinContextType>(null!);

interface LoadingProviderProps {
  children: React.ReactNode;
}

export const LoadingProvider: React.FC<LoadingProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(loadingReducer, defaultLoading);
  return (
    <LoadingContext.Provider value={[state, dispatch]}>
	    { state.loading &&
		    <LoadingComponent />
			}
      {children}
    </LoadingContext.Provider>
  );
};


const LoadingComponent: React.FC = () => {
	return (
		<div className='fixed top-0 left-0 h-screen w-screen flex items-center justify-center z-50 bg-black/90'>

		</div>
	)
}
