import React, {useState} from 'react';
import Title from '../../../../components/layouts/Title';
import Image from '../../../../assets/noimage.jpg'
import {ImageLayout} from '../../../../components/layouts/ImageLayout';
import {TableData} from '../../../../components/layouts/TableLayout';
import {Information} from '../../../../components/icons/Information';
import Popup from '../../../../components/layouts/Popup';
import {durationChoice, FundDataListProps, recruitmentTypeChoice} from "../../../../store/fund";
import {editorJSParser} from "../../../../utils/editor";
import {convertToJapaneseDate} from "../../../../utils/date";
import {numberWithCommas} from "../../../../utils/numbers";

interface FundWorkProp {
  title: string;
  image: string;
  subTitle?: string;
  children: React.ReactNode;
}

const FundWork: React.FC<FundWorkProp> = ({title, image, subTitle, children}) => {
  return (
    <div className="py-10">
      <Title name={title}/>
      <div className="grid sm:grid-cols-2 gap-10">
        <div className="w-full py-2">
          <ImageLayout src={image} className="h-52 w-full" alt=""/>
        </div>
        <div className="w-full ">
          {subTitle ? (<h3 className="text-lg font-semibold mb-3">{subTitle}</h3>) : null}
          {children}
        </div>
      </div>
    </div>
  )
}

export const FundOverview: React.FC<FundDataListProps> = ({fund}) => {
  const [isOpen, setIsOpen] = useState(false);
const htmlArray: string[] = editorJSParser.parse(fund.fund_overview);
  const handleOpen = () => {
    setIsOpen(true);
  };
  const handleClose = () => {
    setIsOpen(false);
  };
  const totalInterest = fund
    ? (fund.capital_interest || 0) + (fund.income_interest || 0)
    : 0;
  return (
    <div>
      <div className="py-10">
        <Title name="ファンド概要"/>
         {
        htmlArray.map((val, index) => (
          <div key={index} dangerouslySetInnerHTML={{ __html: val }} className="my-7" />
        ))
      }

      </div>
      <div className="py-10">
        <Title name="募集概要"/>
        <div className="grid md:grid-cols-2 gap-10">
          <table className='w-full bg-white'>
            <tbody>
            <TableData name="想定利回り">
              <p>{totalInterest}%（税引前）</p>
            </TableData>
            <TableData name="募集金額">
              <p>{numberWithCommas(fund.amount_raised)}万円</p>
            </TableData>
            <TableData name="出資金額">
              <p>{numberWithCommas(fund.total_investment)}万円</p>
            </TableData>
            <TableData name="募集口数">
              <p>{numberWithCommas(fund.number_of_units)}口</p>
            </TableData>
            <TableData name="出資額">
              <p>1口{numberWithCommas((fund.investment_amount || 0) / 10000)}万円</p>
              <p className="text-xs">*{fund.min_num_units}口から{fund.max_num_units}口まで出資可能</p>
            </TableData>
            <TableData name="想定運用期間">
              <p>{fund.duration}{durationChoice[fund.duration_type]}</p>
              <p className="text-xs">{convertToJapaneseDate(fund.operation_start)}〜{convertToJapaneseDate(fund.operation_end)}</p>
            </TableData>
            <TableData name="総分配回数">
              <p>{fund.total_distributions}回</p>
              <p className="text-xs">*償還時配当</p>
            </TableData>
            </tbody>
          </table>
          <div className="w-full mb-5 lg:pl-14">
            <table className='w-full bg-white'>
              <tbody>
              <TableData name="取引種別">
                <button onClick={handleOpen} className="text-primary-main flex items-center">電子取引 <Information
                  color="#836334"/></button>
              </TableData>
              <TableData name="募集期間">
                {fund.recruitment?.map((obj, index) => (
                  <div key={index}>
                    <div>
                      <p className="pb-5">
                        【<p>{recruitmentTypeChoice[obj.recruitment_type]}</p>】<br/>
                        {convertToJapaneseDate(obj.start)} <br/> 〜{convertToJapaneseDate(obj.end)}
                      </p>
                      {obj.recruitment_type === 'drawing' && <p>抽選結果発表｜{convertToJapaneseDate(obj.result_date)}</p>}
                    </div>
                  </div>
                ))}
              </TableData>
              </tbody>
            </table>
            <div>
              <p className="text-sm py-2">※
                先着募集開始時刻はサイトへのアクセスがしづらい場合がございます。<br/>&nbsp; &nbsp;募集開始前にマイページよりデポジット口座への入金をおすすめします。
              </p>
              <p className="text-sm">※ 出資には投資家登録が必要となります。事前に投資家登録をお済ませください。</p>
              <Popup isOpen={isOpen} onClose={handleClose} width="w-1/2">
                <div className=" bg-white p-10">
                  <h2>電子取引とは</h2>
                  <p>電子取引についての説明を明記。
                    契約に関すること、各種帳票の確認に関すること、分配金がマイページ反映され、自分の好きなタイミングで払戻、次のファンドへの出資金に追加することも可能であること、など。</p>
                </div>
              </Popup>
            </div>
          </div>
        </div>
      </div>
      <div className="py-10">
        <Title name="ファンドの仕組み"/>
        <ImageLayout src={Image} className="h-52 w-full" alt=""/>
        <p className="py-3">投資家の皆様から出資により集めた資金を元に、ファンド組成会社（不動産特定共同事業第1号事業者）が不動産を購入または開発・運用します。
          運用期間中に不動産を賃貸することにより賃借人から得られる賃料および売却代金を基にして、投資家の皆様への配当および元本償還を行います。</p>
        <p className="py-3"> お客様への配当は、賃貸借契約者の賃料を原資としているため、空室が続くと配当の原資が損なわれてしまいます。
          トモタクでは、出資元本の保全性を高めると同時にお客様への配当をより確実なものとするために、運用期間における第三者とのマスターリース(サブリース)契約を締結しています。<br/>
          これにより、空室による無配当のリスクを抑制し、お客様に安心して投資していただけるような商品を提供することができます。
        </p>
      </div>
      <FundWork title="ファンドの仕組み" image={Image} subTitle="優先劣後システムを採用">
        <p>ファンド組成会社が共同出資することにより、対象不動産の売却時に損失が発生した場合のリスクを軽減しております。
          対象不動産に売却損が発生した場合、ファンド組成会社が先に損失を負担し、損失がファンド組成会社の出資額を越えた際に、はじめて投資家の皆様の元本が毀損致します。
          本ファンドの劣後出資の割合はリターンタブ をご確認ください。</p>
      </FundWork>
      <FundWork title="無配当リスクを回避する仕組み" image={Image} subTitle="マスターリース制度導入">
        <p>マスターリースについての説明。これはダミーテキストですこれはダミーテキストですこれはダミーテキストです。これはダミーテキストですこれはダミーテキストですこれはダミーテキストですこれはダミーテキストですこれはダミーテキストです。<br/>
          これはダミーテキストですこれはダミーテキストです。</p>
      </FundWork>
      <FundWork title="未出資金を保護する取り組み" image={Image} subTitle="信託銀行を活用した分別管理">
        <p>信託銀行についての説明。これはダミーテキストですこれはダミーテキストですこれはダミーテキストです。これはダミーテキストですこれはダミーテキストですこれはダミーテキストですこれはダミーテキストですこれはダミーテキストです。<br/>
          これはダミーテキストですこれはダミーテキストです。</p>
      </FundWork>
    </div>
  )
}
