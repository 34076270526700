import React, {createContext, useMemo, useState} from "react";
import {ToastType} from "../store/toast";
import {createPortal} from "react-dom";
import {Toast} from "../components/ui/Toast";

type ToastContextType = { open: (content: string, type: 'success' | 'error') => void };

export const ToastContext = createContext<ToastContextType>(null!);

export function generateUEID() {
  let first: number | string = (Math.random() * 46656) | 0;
  let second: number | string = (Math.random() * 46656) | 0;
  first = ('000' + first.toString(36)).slice(-3);
  second = ('000' + second.toString(36)).slice(-3);

  return first + second;
}

interface ToastProviderProps {
  children: React.ReactNode;
}

export const ToastProvider: React.FC<ToastProviderProps> = ({children}) => {
  const [toasts, setToasts] = useState<ToastType[]>([]);

  const open = (content: string, type: 'success' | 'error') =>
    setToasts((prev) => [
      ...prev,
      { id: generateUEID(), content: content, type: type}
    ]);

  const close = (id: string) =>
    setToasts((currentToasts) =>
      currentToasts.filter((toast) => toast.id !== id)
    );

  const contextValue = useMemo(() => ({ open }), []);

  return (
    <ToastContext.Provider value={contextValue}>
      {children}
      {
        createPortal(
          <div className="absolute top-5 left-5">
          {toasts.map((toast) => (
            <Toast key={toast.id} close={() => close(toast.id)} type={toast.type}>
              {toast.content}
            </Toast>
          ))}
        </div>,
        document.body
        )
      }
    </ToastContext.Provider>
  )
}
