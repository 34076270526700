export const SET_STEPS = 'SET_STEPS';

export const INCREMENT_CURRENT_STEP = 'INCREMENT_CURRENT_STEP';

export const DECREMENT_CURRENT_STEP = 'DECREMENT_CURRENT_STEP';

export const SET_AUTH = 'SET_AUTH';

export const START_LOADING = 'START_LOADING';

export const STOP_LOADING = 'STOP_LOADING';

export const INCREMENT_ACTIVE_LOADING = 'INCREMENT_ACTIVE_LOADING';

export const DECREMENT_ACTIVE_LOADING = 'DECREMENT_ACTIVE_LOADING';

export const BACKEND_URL = process.env.REACT_APP_GATEWAY_URL;
