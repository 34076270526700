import React from "react";
import { IconProps } from ".";


export const FundImageLabel: React.FC<IconProps> = ({
  fill = '#836334',
  stroke = '#836334',
  strokeWidth = 0,
  height = '70px',
  width = '60px',
  className,
}) => (
  <svg
    fill={fill}
    stroke={stroke}
    strokeWidth={strokeWidth}
    height={height}
    width={width}
    viewBox="0 0 184.751 184.751"
    className={className}
  >
    <path d="M0,92.375l46.188-80h92.378l46.185,80l-46.185,80H46.188L0,92.375z" />
  </svg>
);