import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import noImage from "../../../../assets/noimage.jpg";

interface ReportProps {
  title?: string;
  content?: string;
  slug?: string;
  date?: string;
  index?: number;
  totalPosts?: number;
}

interface ReportPost {
  id: number;
  title: string;
  content: string;
  date: string;
}

const Report: React.FC<ReportProps> = ({
  title = "test",
  content = "抜粋文をここに表示これはダミーテキストです。はダミーテキストですはダミーテキストです、はダミーテキストですダミーテキストです。ダミーテキストですダミーテキストですダミー。…",
  slug = "test",
  date = "2023.10.12",
  index = 0,
  totalPosts = 1
}) => {
  const isLastPost = index !== null && index >= totalPosts - 2;
  return (
    <li className={`bg-white py-3 lg:pr-10 ${isLastPost ? '' : 'border-b-2'}`}>
      <div className="flex flex-row sm:flex-row w-full">
        <figure className="w-48 lg:h-44 border">
          <img className="w-full h-auto object-fill" src={noImage} alt="" />
        </figure>
        <Link to={slug} className="flex flex-col ml-3 w-3/4">
          <span className="text-left text-[10px] text-grey-text-light">{date}</span>
          <h3 className="font-semibold">{title}</h3>
          <p className="w-full py-2 text-grey-text-light text-sm">{content}</p>
        </Link>
      </div>
    </li >
  )
}


export const OperationalReport: React.FC<ReportProps> = () => {
  const [currentPage] = useState(1);
  const [postsPerPage] = useState(10);
  const [posts, setPosts] = useState<ReportPost[]>([]);
  const totalPages = Math.ceil(posts.length / postsPerPage);
  useEffect(() => {
    fetch('https://jsonplaceholder.typicode.com/posts')
      .then(response => response.json())
      .then(data => setPosts(data));
  }, []);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);
  return (
    <ul className="grid grid-cols-1 lg:grid-cols-2 w-full">
      {currentPosts.map((data, index) => (
        <Report key={index} index={index} title={data.title} content={data.content} totalPosts={totalPages} />
      ))
      }
    </ul>
  )
}
