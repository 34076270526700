
interface YoutubeEmbed {
  embedId?: string;
  width?: string;
  height?: string;
  className?: string;
};

export const YoutubeIframe: React.FC<YoutubeEmbed> = ({
  embedId,
  width = "",
  height = "",
  className,

}) => {
  return (
    <div className="flex items-center justify-center">
      <div className={`${className} m-auto w-full md:w-full lg:w-3/4 xl:w-2/4 pb-5`}>
        <iframe
          className="w-full aspect-video"
          height={height}
          width={width}
          src={`https://www.youtube.com/embed/${embedId}?autoplay=1&mute=1&playlist=${embedId}&loop=1?controls=0`}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Youtube"
        />
      </div>
    </div >
  )
}