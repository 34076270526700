import React from 'react';


interface MainContainerLayoutProps {
  className?: string;
  children: React.ReactNode;
}

export const MainContainerLayout: React.FC<MainContainerLayoutProps> = ({
  className = "w-full ",
  children
}) => {
  return (
    <div className={`${className} mt-20`}>
      {children}
    </div >
  )
}