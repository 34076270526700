import React from 'react';
import { StepArrow } from "../../components/icons/StepArrow";
import Step01 from '../../assets/beginners/step-cf01.png'
import Step02 from '../../assets/beginners/step-cf02.png'
import Step03 from '../../assets/beginners/step-cf03.png'

interface FundStepProps {
  image: string,
  title: string,
  children?: React.ReactNode
}

const FundStep: React.FC<FundStepProps> = ({ image, title, children }) => {
  return (
    <div className="w-full flex flex-col xs:flex-row justify-around items-center">
      <div className="flex flex-col  justify-around items-center ">
        <img className="w-44 h-44 xs:w-36 sm:h-36" src={image} alt="title" />
        <p className="text-center font-bold">{title}</p>
      </div>
      {children ? (<div className="my-5 xs:my-0">{children}</div>) : null}
    </div>
  );
};


export const StepLayout: React.FC = () => {
  return (
    <div className="w-full flex flex-col  xs:flex-row sm:w-full md:w-3/4 xl:w-1/2">
      <FundStep title="会員登録" image={Step01}>
        <StepArrow />
      </FundStep>
      <FundStep title="事前入金＆出資申込" image={Step02}>
        <StepArrow />
      </FundStep>
      <FundStep title="契約成立" image={Step03}>

      </FundStep>
    </div>
  )
}
