import React from "react";
import { IconProps } from ".";


export const RightArrowRound: React.FC<IconProps> = ({
  className = "w-3 h-3",
  strokeWidth = "1.5",
  stroke = "#000",
  fill = "#000"
}) => (
  <svg xmlns="http://www.w3.org/2000/svg"
    fill={`${fill}`}
    viewBox="0 0 24 24"
    strokeWidth={`${strokeWidth}`}
    stroke={`${stroke}`}
    className={`${className}`}>
    <path strokeLinecap="round" strokeLinejoin="round" d="M12.75 15l3-3m0 0l-3-3m3 3h-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
  </svg>

)
