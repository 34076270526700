import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { HeaderLayout } from '../../components/ui/HeaderLayout';
import { Breadcrumb } from '../../components/ui/Breadcurmb';
import { ContentLayout } from "../../components/layouts/ContentLayout";
import { ContainerLayout } from "../../components/layouts/ContainerLayout";
import PostService from '../../infrastructure/api/posts/post';
import { PostPaginationType } from '../../store/post';
import { NewsList } from '../../components/layouts/NewsLayout';
import { MainContainerLayout } from '../../components/layouts/MainContainerLayout';
import Pagination from '../../components/ui/pagination/Pagination';
import useAxios from "../../hooks/useAxios";


const breadcrumbItems = [
  { url: '/', name: 'HOME'},
  { url: '/news', name: 'お知らせ'},
];
const postsPerPage = 1;
export const NewsListing: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [posts, setPosts] = useState<PostPaginationType>({ count: 0, data: [] });
  const page = useMemo(() => parseInt(searchParams.get('page') || '1'), [searchParams]);
  const postService = new PostService(useAxios());

  const fetchPosts = useCallback(() => {
    postService
      .getPosts("news", page, postsPerPage)
      .then((data) => { setPosts(data) })
  }, [page])

  useEffect(() => {
    fetchPosts();
  }, [fetchPosts]);

  const setCurrentPage = (page: number) => {
    setSearchParams({ page: page.toString() });
  }
  return (
    <MainContainerLayout>
      <HeaderLayout title="お知らせ" subTitle="News" />
      <Breadcrumb items={breadcrumbItems} />
      <ContainerLayout>
        <ContentLayout className="w-full lg:w-2/3">
          <ul>
            {posts?.data.map((data, index) => (
              <NewsList
                key={index}
                className="flex-col"
                {...data} />
            ))}
          </ul>
          <Pagination
            total={posts.count}
            perPage={postsPerPage}
            currentPage={page}
            maxLength={6}
            setCurrentPage={setCurrentPage}
          />
        </ContentLayout>
      </ContainerLayout>
    </MainContainerLayout>
  )
}
