import React, { useReducer } from "react";
import {
	ActionType,
	defaultStepperState,
	stepperReducer,
	StepperState
} from "../store/stepper";

type StepperContextType = [
	StepperState,
	React.Dispatch<ActionType>
];

export const StepperContext = React.createContext<StepperContextType>([defaultStepperState, () => { }]);


export const StepperProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const [state, dispatch] = useReducer(stepperReducer, defaultStepperState);

	return (
		<StepperContext.Provider value={[state, dispatch]}>
			{children}
		</StepperContext.Provider>
	);
};
