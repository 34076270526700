import React from "react";

interface ButtonType {
	className?: string,
	type?: "button" | "submit" | "reset";
	onClick?: () => void;
	children?: React.ReactNode;
}

export const Button: React.FC<ButtonType> = ({
	children,
	className = "bg-black text-white w-full",
	type = 'button',
	onClick = () => { },
	...props
}) => {
	return (
		<button
			type={type}
			onClick={onClick}
			className={`border rounded ${className}`}
			{...props}
		>
			{children}
		</button>
	)
}