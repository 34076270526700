import { ContentLayout } from "../components/layouts/ContentLayout";
import { ContainerLayout } from "../components/layouts/ContainerLayout";
import { HeaderFooter } from '../components/layouts/HeaderFooter';

export const WithDrawCompleted: React.FC = () => {
  return (
    <HeaderFooter>
      <ContainerLayout>
        <ContentLayout className="w-1/2 py-5">
          <h1 className="text-center text-3xl font-bold pb-5">みつばちファンド退会完了</h1>
          <div className="p-5 mt-3">
            <p>退会が完了しました。<br />
              これまでみつばちファンドをご利用いただきまして、誠にありがとうございました。</p>
            <p className="mt-5">お客様の登録情報を削除いたしました。<br /> *システムの都合上、数回メールが配信される場合がございます。ご了承ください。</p>
          </div>
        </ContentLayout>
      </ContainerLayout>
    </HeaderFooter>
  );
};