import React from 'react';
import {Header} from "./components/ui/Header";
import {Footer} from "./components/ui/Footer";
import {Route, Routes} from "react-router-dom";
import {StepperProvider} from "./contexts/stepper";
import Home from "./pages/home";
import ForBeginners from "./pages/beginner/beginners";
import Signin from "./pages/entry/signin";
import SSOAuth from "./pages/entry/ssoAuth";
import EmailSignup from "./pages/entry/emailSignup";
import EmailAuth from "./pages/entry/emailAuth";
import {Blog} from './pages/blog/blog';
import {BlogDetail} from './pages/blog/blogDetails';
import {FundList} from './pages/fund/fundList';
import FundDetails from './pages/fund/fundDetails';
import {PrivacyPolicy} from './pages/privacyPolicy';
import {Company} from './pages/company';
import {BusinessManager} from './pages/businessManagers';
import {Faq} from './pages/faq';
import {CustomerSupport} from './pages/customerSupport';
import {Campaign} from './pages/campaign/campaign';
import {CampaignDetail} from './pages/campaign/campaignDetails';
import {Report} from './pages/report/report';
import {ReportDetail} from './pages/report/reportDetails';
import {Application} from './pages/application';
import {WithDrawCompleted} from './pages/withdrawaCompleted';
import {WithDrawInformation} from './pages/withdrawaInformation';
import {WithDrawForm} from './pages/withdrawFrom';
import {WithdrawApplication} from './pages/withdrawApplication';
import {BlogCategory} from './pages/blog/blogCategory';
import {NewsDetails} from './pages/news/newsDetails';
import {NewsListing} from './pages/news/newsList';
import {AuthProvider} from "./contexts/auth";
import {LoadingProvider} from "./contexts/loading";
import {ToastProvider} from "./contexts/toast";

function App() {
	return (
		<ToastProvider>
			<LoadingProvider>
				<AuthProvider>
					<StepperProvider>
						<Routes>
							<Route path="*">
								<Route path="signin" element={<Signin/>}/>
								<Route path="signup">
									<Route index element={<EmailSignup/>}/>
									<Route path=":token" element={<EmailAuth/>}/>
								</Route>
								<Route path="auth" element={<SSOAuth/>}/>
								<Route path="application" element={<Application/>}/>
								<Route path="withdraw" element={<WithDrawCompleted/>}/>
								<Route path="withdraw-info" element={<WithDrawInformation/>}/>
								<Route path="withdraw-form" element={<WithDrawForm/>}/>
								<Route path="withdraw-application" element={<WithdrawApplication/>}/>
								<Route element={<Header/>}>
									<Route element={<Footer/>}>
										<Route index={true} element={<Home/>}/>
										<Route path="beginners" element={<ForBeginners/>}/>
										<Route path="fund" element={< FundList/>}/>
										<Route path="fund/:slug" element={<FundDetails/>}/>
										<Route path="blog" element={<Blog/>}/>
										<Route path="blog/:slug" element={<BlogDetail/>}/>
										<Route path="blog/category/:slug" element={<BlogCategory/>}/>
										<Route path="news" element={<NewsListing/>}/>
										<Route path="news/:slug" element={<NewsDetails/>}/>
										<Route path="faq" element={<Faq/>}/>
										<Route path="business-manager" element={<BusinessManager/>}/>
										<Route path="company" element={<Company/>}/>
										<Route path="campaign" element={<Campaign/>}/>
										<Route path="campaign/detail" element={<CampaignDetail/>}/>
										<Route path="report" element={<Report/>}/>
										<Route path="report/detail" element={<ReportDetail/>}/>
										<Route path="contact" element={<CustomerSupport/>}/>
										<Route path="privacy-policy" element={<PrivacyPolicy/>}/>
									</Route>
								</Route>
							</Route>
							<Route path="*" element={<>Not Found</>}/>
						</Routes>
					</StepperProvider>
				</AuthProvider>
			</LoadingProvider>
		</ToastProvider>
	);
}

export default App;
