export type EmailSignupT = {
	email: string;
	password: string;
	password2: string;
  account_type: string;
  agree: string[];
}

export const defaultEmailSignUp: EmailSignupT = {
	email: "",
	password: "",
	password2: "",
  account_type: "",
  agree: []
}

export const accountTypeChoice = [
  {label: "個人", value: "individual"},
  {label: "法人", value: "corporation"},
]
