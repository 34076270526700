import { Link } from 'react-router-dom'
import { ContentLayout } from "../components/layouts/ContentLayout";
import { ContainerLayout } from "../components/layouts/ContainerLayout";
import { HeaderFooter } from '../components/layouts/HeaderFooter';


export const WithDrawForm: React.FC = () => {
  return (
    <HeaderFooter>
      <ContainerLayout>
        <ContentLayout className="w-1/2 py-5">
          <h1 className="text-center text-3xl font-bold pb-5">みつばちファンド退会のご案内</h1>
          <div className="border p-5 text-center mt-3">
            <h2 className="font-semibold text-xl">このページは契約を解除するものではありません。</h2>
            <p className="text-sm text-grey-text opacity-80 pt-2">契約に関するお問い合わせは<Link to="" className="text-accent-main underline">こちら</Link>から承っております。</p>
          </div>
          <div className="mt-10">
            <p>いつもみつばちファンドをご利用いただき誠にありがとうございます。</p>
            <p className="mt-5">山田太郎さまは、現在以下ファンドに出資中のため退会出来かねます。</p>
            <p>※ファンド運用終了後(?)毎年12月頃(?)、マイページに発行される「財産管理報告書」をご確認後、退会可能となります。</p>
          </div>
          <table className="border w-full text-center my-5">
            <thead>
              <tr className="bg-grey-bg">
                <th className="border p-2">ファンド名</th>
                <th className="border p-2">出資額</th>
                <th className="border p-2">運用終了予定日</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border p-2">みつばち3号ファンド</td>
                <td className="border p-2">10,000円</td>
                <td className="border p-2">2023年12月31日</td>
              </tr>
              <tr>
                <td className="border p-2">スズメバチ1号ファンド</td>
                <td className="border p-2">1,000,000円</td>
                <td className="border p-2">2023年12月31日</td>
              </tr>
              <tr>
                <td className="border p-2">みつばち1号ファンド</td>
                <td className="border p-2">10,000円</td>
                <td className="border p-2">2023年10月31日</td>
              </tr>
            </tbody>
          </table>
          <p>メルマガ会員解除のみをご希望の方は<Link to="" className="text-accent-main underline">こちら</Link>から申請できます。</p>
        </ContentLayout>
      </ContainerLayout>
    </HeaderFooter>
  );
};