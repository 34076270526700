import React from "react";

interface ContentLayoutProps {
  className?: string;
  children: React.ReactNode;
}

export const ContentLayout: React.FC<ContentLayoutProps> = ({
  className = '',
  children
}) => {
  return (
    <div className={`${className} mx-auto text-grey-text leading-7 py-5`}>
      {children}
    </div >
  )
}