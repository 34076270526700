
import { Link } from 'react-router-dom';
import logo from '../../assets/logo.png';


export const HeaderFooter: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const currentYear = new Date().getFullYear();
  return (
    <div className="container mx-auto xl:w-[1280px]">
      <header className="z-50  bg-white h-20 py-2">
        <div className="flex justify-start">
          <Link className="h-16 relative" to="/"><img className="h-16" src={logo} alt="logo" /></Link>
        </div>
      </header>
      {children}
      <footer className="py-4 text-grey-text opacity-50">
        <p className="text-xs"> © {currentYear} MitsubachiFund All Rights Reserved.</p>
      </footer>
    </div >
  );
};
