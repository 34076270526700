import React, {useState} from 'react';
import {Link} from 'react-router-dom'
import {FundImageLabel} from '../../icons/FundImageLabel';
import {durationChoice, FundListType, RecruitmentType, recruitmentTypeChoice} from "../../../store/fund";
import {convertToJapaneseDate} from "../../../utils/date";
import {numberWithCommas} from "../../../utils/numbers";


interface ProgressProps {
  label?: string;
  progress?: number;
};

const Progress: React.FC<ProgressProps> = ({
                                             progress = '',
                                             label,
                                           }) => {
  const progressBar = progress > 100 ? 100 : progress;
  return (
    <div>
      {progress && <span className="text-[10px] text-grey-text-light">応募率: <span
        className="text-primary-main">{progress}%</span></span> }
      <div className="w-full h-5 bg-grey-bg rounded-full">
        <div className="h-5 bg-primary-light rounded-full" style={{width: `${progressBar}%`}}></div>
      </div>
    </div>
  );
};

interface FundImageLabelProps {
  fundStatus: string;
  currentRecruitment?: RecruitmentType;
  nextRecruitment?: RecruitmentType;
};

export const FundImageLabelComponent: React.FC<FundImageLabelProps> = ({
                                                                         fundStatus,
                                                                         currentRecruitment,
                                                                         nextRecruitment
                                                                       }) => {
  const textClass = fundStatus === '募集中' ? 'text-white' : '';
  const fillProp = fundStatus === '募集中' ? undefined : '#ffffff';

  return (
    <div className="absolute flex flex-col justify-center items-center ml-2 mt-2">
      <div className="flex justify-center items-center">
        <FundImageLabel fill={fillProp} strokeWidth={8}/>
        <span className={`absolute text-xs font-bold text-center ${textClass}`}>{fundStatus}</span>
      </div>
      {fundStatus == "募集中" && currentRecruitment && <span
          className="grid items-center text-center text-xs bg-primary-text w-14 h-5 rounded-sm mt-1">{recruitmentTypeChoice[currentRecruitment.recruitment_type]}</span>}
      {fundStatus == "予告" && nextRecruitment && <span
          className="grid items-center text-center text-xs bg-primary-text w-14 h-5 rounded-sm mt-1">{recruitmentTypeChoice[nextRecruitment.recruitment_type]}</span>}
    </div>
  );
};


interface FundListItemProps {
  fund: FundListType
}

const FundListItem: React.FC<FundListItemProps> = ({fund}) => {
  const [progress] = useState(78);
  const totalInterest = fund
    ? (fund.capital_interest || 0) + (fund.income_interest || 0)
    : 0;
  const textClass = fund.current_status === '募集中' ? 'text-white' : '';
  const fillProp = fund.current_status === '募集中' ? undefined : '#ffffff';
  return (
    <div className="border border-grey-boarder">
      <Link to={`/fund/${fund.slug}`}>

        <div className='f-image relative'>
          <div className="absolute flex flex-col justify-center items-center ml-2 mt-2">
            <div className="flex justify-center items-center">
              <FundImageLabel fill={fillProp} strokeWidth={8}/>
              <span className={`absolute text-xs font-bold text-center ${textClass}`}>{fund.current_status}</span>
            </div>
            {fund.current_status === "予告" && fund.next_recruitment &&
                <span
                    className="grid items-center text-center text-xs bg-primary-text w-14 h-5 rounded-sm mt-1">{recruitmentTypeChoice[fund.next_recruitment.recruitment_type]}</span>
            }
            {fund.current_status === "募集中" && fund.current_recruitment &&
                <span
                    className="grid items-center text-center text-xs bg-primary-text w-14 h-5 rounded-sm mt-1">{recruitmentTypeChoice[fund.current_recruitment?.recruitment_type]}</span>
            }
          </div>
          <figure className="w-full h-70 xs:h-80 md:h-64 xl:h-72 overflow-hidden">
            <img className="h-full w-full object-center object-cover" src={fund.thumbnail} alt=""/>
          </figure>
        </div>
        <div className=" f-data w-full">
          <div className="alternate-data p-2">
            <h3 className="text-lg text-secondary-main">{fund.name}</h3>
            <div className="py-2">
              {/* TODO:recruitment */}

              <Progress progress={progress} label="78%"/>

            </div>
            <div className="">
              <table className="w-full">
                <tbody>
                <tr className="flex align-center justify-between py-1">
                  <th className="text-sm font-normal">募集金額</th>
                  <td>
                    <p className="text-sm font-bold">{numberWithCommas(fund.amount_raised)}<span>円</span></p>
                  </td>
                </tr>
                <tr className="flex items-center text-center justify-between py-1">
                  <th className="text-sm font-normal">想定運用期間</th>
                  <td className="flex flex-col text-right">
                    <p className="text-sm font-bold">{fund.duration}<span>{durationChoice[fund.duration_type]}</span>
                    </p>
                    <time
                      className="text-[10px] text-grey-text-light">{convertToJapaneseDate(fund.operation_start)}~{convertToJapaneseDate(fund.operation_end)}</time>
                  </td>
                </tr>
                <tr className="flex align-center justify-between py-1">
                  <th className="text-sm font-normal">想定利回り
                    <p>（年利）</p>
                  </th>
                  <td>
                    <p className="text-3xl text-primary-main font-bold">{totalInterest}<span
                      className="text-base">%</span></p>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

interface FundListComponentProps {
  funds: FundListType[]
}

const FundListComponent: React.FC<FundListComponentProps> = ({funds}) => {
  const useAlternateLayout = funds.length < 3;
  return (
    <div>
      <div
        className={useAlternateLayout ? "alternate grid grid-cols-1 gap-10" : "default-layout grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10"}
      >
        {funds.map((fund, index) => (
          <FundListItem key={index} fund={fund}/>
        ))}
      </div>
    </div>
  );
};

export default FundListComponent;
