import React from "react";
import {StepperState} from "../../../store/stepper";

type StepperHeaderItemType = {
	index: number,
	name: string,
	completed: boolean,
	length: number;
}


const StepperHeaderItem:React.FC<StepperHeaderItemType> = (props) => {
	const {
		index,
		name,
		completed,
	} = props;
	return (
		<li
			className="relative w-full flex flex-col items-center justify-center gap-2"
		>
			<span>{name}</span>
			<span className="flex items-center">
				<span className={`h-5 w-5 rounded-full z-10 ${ index === 0 || completed ? "bg-blue-500" : "bg-gray-300"}`}/>
				<span className={`absolute w-full h-1 ${ index > 0 && "left-[-50%]"} ${completed ? "bg-blue-500" : "bg-gray-300"}`}  />
			</span>
		</li>
	)
}

export const StepperHeader:React.FC<StepperState> = ({steps, currentStep}) => {
	const length = steps.length;
	return (
		<nav >
			<ol className="flex w-full m-auto relative items-center">
				{
					steps.map((step, index) => (
						<StepperHeaderItem key={step.id} index={index} name={step.name} completed={currentStep >= index} length={length}/>
					))
				}
			</ol>
		</nav>
	)
}