import React from "react";
import { Link } from "react-router-dom"
import { RightArrowRound } from "../icons/RightArrowRound";

interface LinkType {
  to: string;
  label?: string;
  className?: string;
}

interface TitleLayoutProps {
  title?: string;
  subTitle?: string;
  link?: LinkType
  className?: string;
}

export const TitleLayout: React.FC<TitleLayoutProps> = ({
  title,
  subTitle,
  link,
  className = "w-full",
}) => {
  return (
    <div className={`${className} mx-auto pt-5`}>
      <div className="flex justify-between w-full items-end">
        <div>
          <span className="text-sm text-grey-boarder select-none">{subTitle}</span>
          <h2 className="text-2xl sm:text-3xl font-bold">{title}</h2>
        </div>
        {
          link && (
            <Link to={link.to} className={link.className || "flex justify-end sm:justify-center items-center sm:border sm:border-grey-boarder text-lg font-semibold w-auto sm:w-52 h-12 rounded text-center"} >
              {link.label}
              <RightArrowRound className="w-7 h-7 block sm:hidden" stroke="#ffffff" fill="#FCD21D" />
            </Link>
          )
        }
      </div>
    </div >

  )
}
