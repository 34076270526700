import React from "react";
import axios from "axios";
import {
    REGISTRATION_INITIAL_STATE,
    RegistrationT,
} from "../../components/ui/RegistrationForm";
import AuthService from "../../infrastructure/api/users/auth";
import BaseAuth from "../../components/ui/BaseAuth";
import { useParams } from "react-router-dom";


const EmailAuth: React.FC = () => {
	const { token } = useParams();
	const authService = new AuthService();

    const initialCallback = (handleInitial: (data: RegistrationT) => void) => {
        axios.get(
            `${process.env.REACT_APP_GATEWAY_URL}/api/users/signup/email/${token}`,
            {
                withCredentials: true
            })
            .then((res) => {
                handleInitial({...REGISTRATION_INITIAL_STATE, email: res.data});
            })
            .catch((error) => {
                console.error(error);
            });
    };

	return (
		<BaseAuth service={authService.userRegistrationByEmail} callback={initialCallback} token={token} />
	)
}

export default EmailAuth;
