import React, {useEffect, useState} from 'react';
import {Breadcrumb} from '../../../components/ui/Breadcurmb';
import {ContainerLayout} from '../../../components/layouts/ContainerLayout';
import {ContentLayout} from '../../../components/layouts/ContentLayout';
import {ImageSlider} from './ImageSlider';
import {FundSidebar} from './FundSidebar';
import Tab from '../../../components/layouts/TabLayout';
import {FundOverview} from './TabData/FundOverview';
import {PropertyInformation} from './TabData/PropertyInformation';
import {ReturnValue} from './TabData/ReturnValue';
import {Risk} from './TabData/Risk';
import {InvestmentFlow} from './TabData/InvestmentFlow';
import {OperationalReport} from './TabData/OperationalReport';
import {Administrator} from './TabData/Administrator';
import {MainContainerLayout} from '../../../components/layouts/MainContainerLayout';
import FundService from "../../../infrastructure/api/funds/funds";
import {useParams} from "react-router-dom";
import {DistributionType, FundDetailsType, InformationType, OperatorType} from "../../../store/fund";
import useAxios from "../../../hooks/useAxios";

const breadcrumbItems = [
  {url: '/', name: 'HOME'},
  {url: '/fund', name: 'ファンド一覧'},
];

const Tabs = [
  '概要',
  '物件情報',
  'リターン',
  'リスク',
  '出資の流れ',
  '運営者',
  '運用レポート'
]

const FundDetails: React.FC = () => {
  const {slug} = useParams();
  const [fundData, setFundData] = useState<FundDetailsType | undefined>();
  const [objectInformationList, setObjectInformationList] = useState<InformationType[] | null>(null);
  const [operator, setOperator] = useState<OperatorType[] | null>(null);
  const [distribution, setDistribution] = useState<DistributionType[] | null>(null);
  const fundService = new FundService(useAxios());

  useEffect(() => {
    if (slug) {
      fundService
        .getFundDetails(slug)
        .then((data) => {
          setFundData(data);
        })
        .catch(() => {
          // TODO: redirect to 404 page
        });
    }
  }, [slug]);

  if (!slug || !fundData) return <></>;

  return (
    <MainContainerLayout>
      <Breadcrumb items={[...breadcrumbItems, {url: '', name: fundData.name}]}/>
      <ContainerLayout>
        <div>
          <small className="text-accent-main font-semibold text-lg">{fundData?.current_status}</small>
          <h1 className="text-secondary-main font-bold text-3xl">{fundData?.name}</h1>
        </div>
        <ContentLayout className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 w-full justify-between">
          {fundData &&
              <ImageSlider
                  images={fundData?.images} fundStatus={fundData?.current_status || ``} currentRecruitment={fundData?.current_recruitment} nextRecruitment={fundData?.next_recruitment}/>
          }
          {fundData && <FundSidebar fund={fundData}/>}
        </ContentLayout>
      </ContainerLayout>
      <Tab tabs={Tabs}>
        {fundData && <FundOverview fund={fundData}/>}
        {fundData &&
            <PropertyInformation
                data={objectInformationList}
                fundService={fundService}
                setData={(data) => {
                  setObjectInformationList(data)
                }}
                fundId={fundData.id}
            />
        }
        {fundData &&
            <ReturnValue
                data={distribution}
                fundService={fundService}
                setData={(data) => {
                  setDistribution(data);
                }}
                fundId={fundData.id}
                durations ={fundData.duration}
                durationTypes ={fundData.duration_type}
                operationStart ={fundData.operation_start}
                operationEnd = {fundData.operation_end}
                percentage = {(fundData.capital_interest || 0) + (fundData.income_interest || 0)}

            />
        }
        <Risk/>
        <InvestmentFlow/>
        {fundData &&
            <Administrator
                data={operator}
                fundService={fundService}
                setData={(data) => {
                  setOperator(data);
                }}
                fundId={fundData.id}
            />}
        <OperationalReport/>
      </Tab>
    </MainContainerLayout>
  )
}

export default FundDetails;
