import React, {useEffect, useReducer} from "react";
import {AuthActionType, authReducer, AuthType, initialAuthType} from "../store/auth";
import {SET_AUTH} from "../store/constants";
import {getCookie} from "../utils/cookies";
import {authAxios} from "../infrastructure/api/axios";
import {useLoading} from "../hooks/useLoading";

type AuthContextType = [
	AuthType,
	React.Dispatch<AuthActionType>
]

export const AuthContext = React.createContext<AuthContextType>(null!);

interface AuthProviderProps {
	children: React.ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
	const [state, dispatch] = useReducer(authReducer, initialAuthType);
	const {startLoading, stopLoading, incrementActiveLoading, decrementActiveLoading} = useLoading();
	useEffect(() => {
		startLoading();
		incrementActiveLoading();
		authAxios.post(`/api/v1/user/users/session/refresh`,
			{},
			{
				headers: {
					'X-CSRF-Token': getCookie('csrf_refresh_token')
				}
			}
			)
			.then((res) => {
				dispatch({type: SET_AUTH, payload: res.data});
			})
			.finally(() => {
				decrementActiveLoading();
				stopLoading();
			})
	}, []);

	return (
		<AuthContext.Provider value={[state, dispatch]}>
			{children}
		</AuthContext.Provider>
	)
}
