import React from 'react';
import { Link } from 'react-router-dom';
import { LeftArrow } from '../../../components/icons/LeftArrow';
import { RightArrow } from '../../../components/icons/RightArrow';
import { PostTitle } from '../../../store/post';



interface PrevNextProps {
  previousPost: PostTitle | undefined;
  nextPost: PostTitle | undefined;
}

export const PrevNext: React.FC<PrevNextProps> = ({ previousPost, nextPost }) => {
  return (
    <div className="grid grid-cols-2 mt-5 content-center">
      {previousPost && (
        <div className="flex justify-center border-r pr-4 content-center items-center">
          <Link to={`/blog/${previousPost.slug}`}>
            <LeftArrow className="h-10 w-10" strokeWidth={3} color="grey" />
          </Link>
          <p className="px-3 font-semibold">{previousPost.title}</p>
          <img src={previousPost.media_url} alt="" className="h-24 w-24 object-cover" />
        </div>
      )}
      {nextPost && (
        <div className="flex justify-center pl-4 content-center items-center">
          <img src={nextPost.media_url} alt="" className="h-24 w-24" />
          <p className="px-3 font-bold text-truncate">{nextPost.title}</p>
          <Link to={`/blog/${nextPost.slug}`}>
            <RightArrow className="h-10 w-10" strokeWidth={3} color="grey" />
          </Link>
        </div>
      )}
    </div>
  );
};
