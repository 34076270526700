import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom'
import { HeaderLayout } from '../../components/ui/HeaderLayout';
import { Breadcrumb } from '../../components/ui/Breadcurmb';
import { ContentLayout } from "../../components/layouts/ContentLayout";
import { ContainerLayout } from "../../components/layouts/ContainerLayout";
import { PromotionPaginationType, PromotionListType } from '../../store/fund';
import FundService from '../../infrastructure/api/funds/funds';
import useAxios from "../../hooks/useAxios";


const breadcrumbItems = [
  { url: '/', name: 'HOME', index: 0, isLast: false },
  { url: '/campaign', name: '里帰りインフォメーション', index: 1, isLast: true },
];


const CampaignCard: React.FC<PromotionListType> = ({
  id,
  status,
  start,
  end,
  media_url = '',
  title,

}) => {
  return (
    <Link to={`/campaign/${id}`} className="my-5 sm:my-0 w-full">
      <p className="text-sm mb-2">
        <span className="font-semibold">{status}</span>
        <span>{start}~{end}</span>
      </p >
      <figure className="h-80 w-full p-2 border border-grey-boarder">
        <img src={media_url} alt={title} className="h-full w-full object-cover" />
      </figure>
      <h2 className="text-lg font-semibold py-3">{title}</h2>
    </Link >
  );
}



export const Campaign: React.FC = () => {
  const postsPerPage = 10;
  const [searchParams, setSearchParams] = useSearchParams();
  const [promotion, setPromotion] = useState<PromotionPaginationType>({ count: 0, data: [] });
  const page = useMemo(() => parseInt(searchParams.get('page') || '1'), [searchParams]);
  const fundService = new FundService(useAxios());
  const fetchPosts = useCallback(() => {
    fundService
      .getPromotions(page, postsPerPage)
      .then((data) => { setPromotion(data) })
  }, [page])

  useEffect(() => {
    fetchPosts();
  }, [fetchPosts]);
  const setCurrentPage = (page: number) => {
    setSearchParams({ page: page.toString() });
  }
  return (
    <div className="mt-20">
      <HeaderLayout title="里帰りインフォメーション" subTitle="campaign" />
      <Breadcrumb items={breadcrumbItems} />
      <ContainerLayout>
        <ContentLayout className="py-10 grid grid-cols-1 sm:grid-cols-2 sm:gap-8 md:gap-12 lg:grid-cols-3 lg:gap-20 ">
          {promotion.data.map((data, key) => (
            <CampaignCard key={key} {...data} />
          ))}
          {/* <Pagination
            total={promotion.count}
            perPage={postsPerPage}
            currentPage={page}
            maxLength={7}
            setCurrentPage={setCurrentPage}
          /> */}
        </ContentLayout>
      </ContainerLayout>
    </div>
  );
}
