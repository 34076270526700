import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/logo.png";
import boxImage from "../../assets/loginImage.jpg";

const ImageBox: React.FC = () => {
	return (
		<div className="h-full pt-20 px-20 bg-grey-bg hidden md:block sticky top-0 w-1/2">
			<div className="max-w-2xl">
				<div>
					<h1 className="text-right text-4xl font-bold pb-10">みつばちファンドで<br /> 里帰り投資</h1>
					<p>みつばちファンドにかけた思いをここに表示します。これはダミーテキストですこれはダミーテキストです。</p><br />
					<p> これはダミーテキストですこれはダミーテキストです。</p>
				</div>
				<div className="flex justify-end mt-20">
					<img src={boxImage} className="h-full w-80" alt="" />
				</div>
			</div>
		</div>
	)
}

export const SigninLayout: React.FC<{ children: React.ReactNode, title?: string }> = ({ children, title }) => {
	return (
		<div className="flex w-full h-full overflow-scroll">
			<div className="w-full md:w-1/2 h-full py-10 mb-10 container mx-auto xl:w-[1280px]">
				<div className="w-full">
					<div className="relative mt-4 mb-4 mx-2 item-center flex justify-center">
						<Link to="/"><img src={logo} className="h-full w-32" alt="logo" /></Link>
					</div>
					<div className="sm:w-4/5 md:w-3/5 m-auto border px-5 py-5">
						<h1 className="mb-4 text-2xl text-center font-bold">{title}</h1>
						{children}
					</div>
				</div>
			</div>
			<ImageBox />
		</div>
	)
}
