import { HomePostsType, PostPaginationType, PostType } from "../../../store/post";
import BaseService from "../base";

const API_URL = process.env.REACT_APP_GATEWAY_URL;

class PostService extends BaseService{
  getPosts(postType: "blog" | "news", page: number, limit: number) {
    return this.axios.get<PostPaginationType>(API_URL + `/api/v1/user/posts/post/${postType}/`, { params: { page: page, limit: limit } })
      .then<PostPaginationType>((res) => res.data)
      .catch((res) => { throw res; });
  }

  getPost(postType: "blog" | "news", slug: string) {
    return this.axios.get<PostType>(API_URL + `/api/v1/user/posts/post/${postType}/${slug}/`)
      .then<PostType>((res) => res.data)
      .catch((res) => { throw res; });
  }
  getHomePost() {
    return this.axios.get<HomePostsType>(API_URL + `/api/v1/user/posts/post/`)
      .then<HomePostsType>((res) => res.data)
      .catch((res) => { throw res; });
  }
}

export default PostService;
