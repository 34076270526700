import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { HeaderLayout } from '../../components/ui/HeaderLayout';
import { Breadcrumb } from '../../components/ui/Breadcurmb';
import { ContentLayout } from "../../components/layouts/ContentLayout";
import { ContainerLayout } from "../../components/layouts/ContainerLayout";
import { Article } from '../../components/ui/blogs/ArticleList'
import Pagination from '../../components/ui/pagination/Pagination';
import { BlogSidebar } from './blogSidebar';
import CategoryService from '../../infrastructure/api/posts/category';
import { PostListType } from '../../store/post';
import { MainContainerLayout } from '../../components/layouts/MainContainerLayout';
import useAxios from "../../hooks/useAxios";



export const BlogCategory: React.FC = () => {
  const { slug } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);
  const [currentPost] = useState<PostListType | undefined>();
  const [posts, setPosts] = useState<PostListType[]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const categoryService = new CategoryService(useAxios());

  useEffect(() => {
    if (slug) {
      categoryService
        .getCategoryDetail("blog", slug)
        .then((data) => {
          setPosts(data);
        })
        .catch(() => {
          // TODO: handle error
        });
    };
  }, [slug]);

  //the breadcrumb items
  const breadcrumbItems = [
    { url: '/', name: 'HOME', index: 0, isLast: false },
    { url: '/blog', name: 'ブログ', index: 1, isLast: false },
    {
      url: '',
      name: slug || '', // post title
      index: 2,
      isLast: true,
    },
  ];

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);


  return (
    <MainContainerLayout>
      <HeaderLayout title="投資に役立つ知識" subTitle="Blog" />
      <Breadcrumb items={breadcrumbItems} />
      <ContainerLayout>
        <ContentLayout className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 sm:gap-8 md:gap-12 lg:gap-18">
          <div className="col-span-2">
            <ul className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 border-y-4 border-grey-border">
              {currentPosts.map((data, index) => (
                <Article key={index} index={index} totalPosts={totalPages} {...data} />
              ))}
            </ul>
            <Pagination
              total={1}
              perPage={10}
              currentPage={currentPage}
              maxLength={7}
              setCurrentPage={setCurrentPage}
            />
          </div>
          <BlogSidebar />
        </ContentLayout>
      </ContainerLayout>
    </MainContainerLayout>
  );
};
