import React from 'react';
import { Link } from 'react-router-dom'
import NoImage from '../../assets/noimage.jpg'
import { SidebarLatestFund } from '../../components/ui/sidebar/SidebarLayout';

export const CampaignSidebar: React.FC = () => {
  return (
    <aside className="w-80">
      <Link to="" className="my-5 sm:my-0">
        <SidebarLatestFund title="最新ファンド情報" image={NoImage} />
      </Link>
    </aside>
  )
}