import React from 'react';
import {InputLayout} from "../../layouts/InputLayout";

interface Radio {
  label: string;
  value: string;
}

interface RadioListProps {
  name: string;
  label: string;
  checkboxes: Radio[];
  value: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  errors?: string[],
  required?: boolean
}

const RadioList: React.FC<RadioListProps> = ({ checkboxes, value, handleChange, name, label, errors = [], required = true }) => {
  return (
    <InputLayout errors={errors} label={label} required={required}>
      <div className="flex gap-10 bg-grey-bg p-2">
      {checkboxes.map(checkbox => (
        <label key={checkbox.value}>
          <input
            name={name}
            type="radio"
            value={checkbox.value}
            checked={checkbox.value === value}
            onChange={handleChange}
            required={required}
          />
          <span className="ml-2">{checkbox.label}</span>
        </label>
      ))}
    </div>
    </InputLayout>
  );
};

export default RadioList;
