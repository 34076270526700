import React, {useEffect, useState} from 'react';
import {HeaderLayout} from '../../components/ui/HeaderLayout';
import {Breadcrumb} from '../../components/ui/Breadcurmb';
import {ContentLayout} from "../../components/layouts/ContentLayout";
import {ContainerLayout} from "../../components/layouts/ContainerLayout";
import PostService from '../../infrastructure/api/posts/post';
import {NewsType} from '../../store/post';
import {useParams} from 'react-router-dom';
import {MainContainerLayout} from '../../components/layouts/MainContainerLayout';
import {editorJSParser} from '../../utils/editor';
import useAxios from "../../hooks/useAxios";
import {convertToJapaneseDate, parseJapaneseDateTime} from "../../utils/date";


const Details: React.FC<NewsType> = ({
                                       title,
                                       category_name,
                                       media_url,
                                       content,
                                       created_date='',
                                     }) => {
  const htmlArray: string[] = editorJSParser.parse(content);
  return (
    <div className="py-5 border-b-4 border-grey-boarder">
      <div>
        <span className="bg-amber-200 px-3 text-sm py-1 mr-2 rounded">{category_name}</span>
        <time className="text-grey-text text-sm text-opacity-60">{convertToJapaneseDate(created_date)}</time>
        <h2 className="border-b-2 pb-2">{title}</h2>
        <figure className="w-full my-5">
          <img className="w-full h-full" src={media_url} alt={title}/>
        </figure>
      </div>

      {
        htmlArray.map((val, index) => (
          <div key={index} dangerouslySetInnerHTML={{__html: val}} className="my-7"/>
        ))
      }
    </div>
  );
};


export const NewsDetails: React.FC = () => {
  const {slug} = useParams();
  const [currentPost, setCurrentPost] = useState<NewsType | undefined>();
  const [loading, setLoading] = useState<boolean>(true);
  const postService = new PostService(useAxios());

  useEffect(() => {
    if (slug) {
      postService
        .getPost("news", slug)
        .then((data) => {
          setCurrentPost(data);
        })
        .catch(() => {
          // TODO: redirect to 404 page
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [slug]);

  const breadcrumbItems = [
    {url: '/', name: 'HOME'},
    {url: '/news', name: 'お知らせ'},
    {url: '', name: currentPost?.title || ''},
  ];

  if (loading) return <>Loading</>;


  return (
    <MainContainerLayout>
      <HeaderLayout title="お知らせ" subTitle="News"/>
      <Breadcrumb items={breadcrumbItems}/>
      <ContainerLayout>
        <ContentLayout className="w-full lg:w-2/3">

          <Details {...currentPost} />
        </ContentLayout>
      </ContainerLayout>
    </MainContainerLayout>
  );
};
