import React, {useState, useEffect} from 'react';
import {RightArrow} from '../../../components/icons/RightArrow';
import {LeftArrow} from '../../../components/icons/LeftArrow';
import {FundImageLabelComponent} from '../../../components/ui/funds/FundListItem';
import {ImagesType, RecruitmentType} from "../../../store/fund";


interface ImageSliderProps {
  images: ImagesType[];
  slideInterval?: number;
  fundStatus: string;
  currentRecruitment?: RecruitmentType;
  nextRecruitment?: RecruitmentType;
}

export const ImageSlider: React.FC<ImageSliderProps> = ({
                                                          images,
                                                          slideInterval = 5000,
                                                          fundStatus,
                                                          currentRecruitment,
                                                          nextRecruitment

                                                        }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex(currentImageIndex => (currentImageIndex + 1) % images.length);
    }, slideInterval);

    return () => clearInterval(intervalId);
  }, [images.length, slideInterval]);

  const handleImageClick = (index: number) => {
    setCurrentImageIndex(index);
  };

  return (
    <div className="md:mr-10 w-full col-span-1 md:col-span-1 lg:col-span-2">
      <div className="relative">
        <div className="absolute left-0 top-0 bottom-0 flex items-center z-10">
          <button
            onClick={() => setCurrentImageIndex((currentImageIndex - 1 + images.length) % images.length)}
            className="p-4 rounded-full"
          >
            <LeftArrow className="w-6 h-6" strokeWidth={4}/>
          </button>
        </div>
        <div className="h-72 xs:h-80 md:h-96 w-full bg-grey-bg">
          {images.map((image, index) => (
            <div
              className={`w-full h-full absolute transition-opacity ease-in duration-1000 flex justify-center ${index === currentImageIndex ? 'opacity-100' : 'opacity-0'}`}
              key={index}>
              <div className="relative">
                <FundImageLabelComponent fundStatus={fundStatus} currentRecruitment={currentRecruitment}
                                         nextRecruitment={nextRecruitment}/>
                <figure>
                  <img
                    src={image.media_url}
                    alt={`${index + 1}`}
                    className="h-72 xs:h-80 md:h-96 w-[600px] object-cover"
                  />
                </figure>
              </div>
            </div>
          ))}
        </div>
        <div className="absolute right-0 top-0 bottom-0 flex items-center">
          <button
            onClick={() => setCurrentImageIndex((currentImageIndex + 1) % images.length)}
            className="p-4 rounded-full"
          >
            <RightArrow className="w-6 h-6" strokeWidth={4}/>
          </button>
        </div>
      </div>
      <div className="flex justify-center mt-2 overflow-x-scroll pb-5 no-scrollbar scrolling-touch">
        {images.map((image, index) => (
          <img key={index} src={image.media_url} alt={`${index + 1}`}
               className={`mx-1 cursor-pointer w-48 h-36 ${index === currentImageIndex ? 'border-2 border-primary-main' : ''}`}
               onClick={() => handleImageClick(index)}/>
        ))}
      </div>
    </div>
  );
};
