import React from 'react';

type TabProps = {
  name: string;
  className?: string;
  children?: React.ReactNode;
};

const Title = ({ name, className = '', children }: TabProps) => {
  return (
    <React.Fragment>
      <h2 className={`${className} font-bold text-2xl mb-4`}>{name}</h2>
      {children}
    </React.Fragment>
  );
};

export default Title;