import React from "react";
import { Link } from "react-router-dom";

interface LinkButtonType {
	name: string;
	url: string;
}

const navLinks: LinkButtonType[] = [
	{ name: "はじめての方へ", url: "/beginners" },
	{ name: "ファンド一覧", url: "/fund" },
	{ name: "よくある質問", url: "/faq" },
	{ name: "運営会社", url: "/company" },
]

const LinkButton: React.FC<LinkButtonType> = ({ name, url }) => {
	return (
		<Link className="group transition-all duration-300 ease-in-out" to={url}>
			<span
				className='p-2 bg-left-bottom bg-gradient-to-r from-main to-main bg-[length:0%_2px] bg-no-repeat group-hover:bg-[length:100%_2px] transition-all duration-500 ease-out'>
				{name}
			</span>
		</Link>
	)
}


export const MainLinks: React.FC = () => {
	return (
		<ul className="flex  gap-4 items-center flex-col w-full lg:flex-row lg:items-end lg:w-auto">
			{navLinks.map((value, index) => (
				<li key={index} className="border-b text-center w-full font-bold text-lg p-1.5 lg:border-0 lg:text-start lg:w-auto lg:font-normal lg:text-lg lg:p-0"><LinkButton key={index} {...value} /></li>
			))}
		</ul>
	)
}
