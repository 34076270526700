import React from 'react';
import { Link } from 'react-router-dom'
import { YoutubeIframe } from "../../../../components/ui/sns/YoutubeIframe";
import { StepLayout } from '../../../../components/layouts/StepsLayout';
import { ContainerLayout } from "../../../../components/layouts/ContainerLayout";
import { ContentLayout } from "../../../../components/layouts/ContentLayout";
import { ImageLayout } from '../../../../components/layouts/ImageLayout';
import Image from '../../../../assets/loginImage.jpg'
import { Ribbon } from '../../../../components/icons/Ribbon';

export const InvestmentFlow: React.FC = () => {

  return (
    <div>
      {/* embedId, width, height, className */}
      <YoutubeIframe
        embedId="_x7E38ULU-Y"
        width="100%" height="350"
        className="w-2/4 pb-5 mt-10"
      />

      <ContainerLayout className="bg-grey-bg">
        <ContentLayout className="flex justify-center">
          <StepLayout />
        </ContentLayout>
      </ContainerLayout>

      <ContentLayout className="pt-10">
        <div className="flex relative mt-10">
          <Ribbon step="01" width={60} borderTop={1} bottom={1} />
          <div className="w-full pl-10 mb-12">
            <h2 className="font-bold text-2xl mb-4">会員登録</h2>
            <div className="column sm:flex-row sm:flex">
              <ImageLayout className="h-44 w-44 mb-2 sm:mr-4 sm:mb-0" src={Image} alt="test" />
              <div>
                <p className="mb-5 test-sm">
                  サイト上部の「会員登録」ボタンから会員登録をお済ませください。<br /><br />
                  これはダミーテキストですこれはダミーテキストですこれはダミーテキストですこれはダミーテキストです、これはダミーテキストですこれはダミーテキストですこれはダミーテキストです
                  これはダミーテキストですこれはダミーテキストですこれはダミーテキストですこれはダミーテキストですこれはダミーテキストですこれはダミーテキストです。
                </p>
                <Link to="" className="bg-accent-main p-3 pr-10 rounded text-white text-center" >会員はこちら</Link>
              </div>
            </div>
          </div>
        </div>
        <div className="flex relative mt-3">
          <Ribbon step="02" width={60} borderTop={0} top={1} bottom={1} />
          <div className="w-full pl-10 mb-12">
            <h2 className="font-bold text-2xl mb-4">事前入金＆出資申込</h2>
            <div className="column sm:flex-row sm:flex">
              <ImageLayout className="h-44 w-44 mb-2 sm:mr-4 sm:mb-0" src={Image} alt="test" />
              <p className="test-sm">
                出資金の事前入金、出資希望ファンドへの申込の流れについて記載。<br /><br />
                これはダミーテキストですこれはダミーテキストですこれはダミーテキストですこれはダミーテキストです、これはダミーテキストですこれはダミーテキストですこれはダミーテキストです
                これはダミーテキストですこれはダミーテキストですこれはダミーテキストですこれはダミーテキストですこれはダミーテキストですこれはダミーテキストです。
              </p>
            </div>
          </div>
        </div>
        <div className="flex relative mt-3">
          <Ribbon step="03" width={60} borderTop={0} top={1} borderBottom={1} />
          <div className="w-full pl-10 mt-10">
            <h2 className="font-bold text-2xl mb-4">会員登録</h2>
            <div className="column sm:flex-row sm:flex">
              <ImageLayout className="h-44 w-44 mb-2 sm:mr-4 sm:mb-0" src={Image} alt="test" />
              <p className="test-sm">
                契約成立時書面などが郵送で届くことをここに記載します。<br /><br />
                これはダミーテキストですこれはダミーテキストですこれはダミーテキストですこれはダミーテキストです、これはダミーテキストですこれはダミーテキストですこれはダミーテキストです
                これはダミーテキストですこれはダミーテキストですこれはダミーテキストですこれはダミーテキストですこれはダミーテキストですこれはダミーテキストです。
              </p>
            </div>
          </div>
        </div>
      </ContentLayout>
    </div>
  );
};
