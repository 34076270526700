import { Link } from 'react-router-dom'
import { ContentLayout } from "../components/layouts/ContentLayout";
import { ContainerLayout } from "../components/layouts/ContainerLayout";
import { HeaderFooter } from '../components/layouts/HeaderFooter';


export const WithDrawInformation: React.FC = () => {
  return (
    <HeaderFooter>
      <ContainerLayout>
        <ContentLayout className="w-1/2 py-5">
          <h1 className="text-center text-3xl font-bold pb-5">みつばちファンド退会のご案内</h1>
          <div className="border p-5 text-center mt-3">
            <h2 className="font-semibold text-xl">このページは契約を解除するものではありません。</h2>
            <p className="text-sm text-grey-text opacity-80 pt-2">契約に関するお問い合わせは<Link to="" className="text-accent-main underline">こちら</Link>から承っております。</p>
          </div>
          <div className="mt-10">
            <p>いつもみつばちファンドをご利用いただき誠にありがとうございます。</p>
            <p className="mt-5">山田太郎さまは、現在デポジット口座に<span className="text-accent-main">xx,xxx円</span>の預け金(?)未出資金(?)がございます。</p>
            <p> 退会をご希望の場合は、マイページの「払い戻し申請」より預け金を全てお受け取りになってから退会可能となります。</p>
          </div>

          <div className="flex items-center justify-center pt-10 pb-5">
            <Link to="" className="grid items-center border-2 border-accent-main w-44 h-12 m-4 text-center text-accent-main rounded-md">退会しない</Link>
            <Link to="" className="grid items-center bg-accent-main w-44 h-12 m-4 text-center text-primary-text rounded-md">払戻申請はこちら</Link>
          </div>
          <p>メルマガ会員解除のみをご希望の方は<Link to="" className="text-accent-main underline">こちら</Link>から申請できます。</p>
        </ContentLayout>
      </ContainerLayout>
    </HeaderFooter>
  );
};