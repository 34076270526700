import React, { useRef } from "react";
import { Link } from "react-router-dom"
import { PostTitle } from '../../../store/post';
import noImage from '../../../assets/noimage.jpg'


interface BlogDataProps {
  title?: string;
  slug?: string;
  media_url?: string;
}
export function truncate(str: string | undefined, maxLength: number): string {
  if (str && str.length > maxLength) {
    return str.substring(0, maxLength - 3) + '...';
  }
  return str || '';
}

const BlogData: React.FC<BlogDataProps> = ({
  title,
  media_url = noImage,
  slug = ''
}) => (
  <div className="w-72 h-auto p-4">
    <div className="bg-white">
      <Link to={slug}>
        <img className="w-72 h-40 pb-3" src={media_url} alt={title} />
        <p className="font-semibold text-normal">{truncate(title, 45)}</p>
      </Link>
    </div>
  </div>
);

export const BlogListItem: React.FC<{ posts: (PostTitle & { excerpt: string })[] }> = ({ posts }) => {
  const isDownRef = useRef<boolean>(false);
  const startXRef = useRef<number>(0);
  const scrollLeftRef = useRef<number>(0);
  const sliderRef = useRef<HTMLDivElement>(null);


  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    isDownRef.current = true;
    sliderRef.current?.classList.add('active');
    startXRef.current = e.pageX - sliderRef.current!.offsetLeft;
    scrollLeftRef.current = sliderRef.current!.scrollLeft;
  };

  const handleMouseLeave = () => {
    isDownRef.current = false;
    sliderRef.current?.classList.remove('active');
  };

  const handleMouseUp = () => {
    isDownRef.current = false;
    sliderRef.current?.classList.remove('active');
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!isDownRef.current) return;
    e.preventDefault();
    const x = e.pageX - sliderRef.current!.offsetLeft;
    const walk = x - startXRef.current;
    sliderRef.current!.scrollLeft = scrollLeftRef.current - walk;
  };
  return (
    <div
      className="flex overflow-x-scroll pb-5 no-scrollbar scrolling-touch items"
      ref={sliderRef}
      onMouseDown={handleMouseDown}
      onMouseLeave={handleMouseLeave}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove}
    >
      <div className="flex flex-nowrap ml-0">
        {posts?.map((data, key) => (
          <BlogData key={key} {...data} />
        ))}
      </div>
    </div>
  );
}
