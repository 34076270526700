import {useEffect} from 'react';
import {AxiosRequestHeaders} from 'axios';
import {authAxios} from '../infrastructure/api/axios';
import {useLoading} from './useLoading';
import {useAuth} from "./useAuth";
import {getCookie} from "../utils/cookies";

const useAxios = () => {
	const {stopLoading, startLoading, incrementActiveLoading, decrementActiveLoading} = useLoading();
	const {accessToken, refreshToken} = useAuth()

	useEffect(() => {
		// Interceptor to add headers before sending a request
		const requestIntercept = authAxios.interceptors.request.use(
			(config) => {
				// Attach the access token to the Authorization header
				if (!config.headers?.Authorization) {
					config.headers = {
						...config.headers,
						Authorization: `Bearer ${accessToken}`,
					} as AxiosRequestHeaders;
				}
				// Attach the CSRF token header if missing
				if (config.headers && !config.headers['X-CSRF-Token']) {
					config.headers = {
						...config.headers,
						'X-CSRF-Token': getCookie('csrf_access_token')
					} as unknown as AxiosRequestHeaders;
				}
				// Start loading and update active loading count
				startLoading();
				incrementActiveLoading();
				return config;
			},
			(error) => Promise.reject(error)
		);

		// Interceptor to handle responses
		const responseIntercept = authAxios.interceptors.response.use(
			(response) => {
				// Decrement active loading count and stop loading
				decrementActiveLoading()
				stopLoading();
				return response;
			},
			(error) => {
				// Handle 401 (Unauthorized) responses by refreshing the access token
				const prevRequest = error?.config;
				decrementActiveLoading();
				stopLoading();
				console.log(prevRequest.url)
				if ((error?.response?.status === 401 && prevRequest.url !== '/api/v1/user/users/session/refresh' && !prevRequest.sent && refreshToken)) {
					prevRequest.sent = true;
					const newAccessToken = refreshToken();
					prevRequest.headers = {
						...prevRequest.headers,
						authorization: `Bearer ${newAccessToken}`,
						'X-CSRF-Token': getCookie('csrf_refresh_token')
					};
					return authAxios(prevRequest);
				}
				return Promise.reject(error);
			},
		);

		// Clean up interceptors when the component unmounts
		return () => {
			authAxios.interceptors.request.eject(requestIntercept);
			authAxios.interceptors.response.eject(responseIntercept);
		};
	}, []);

	return authAxios;
};

export default useAxios;
