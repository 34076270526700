import React from "react";

interface ContentLayoutProps {
  title: string;
  className?: string;
  fontSize?: string;
}

export const TitleCenterLayout: React.FC<ContentLayoutProps> = ({
  title,
  className = "w-full text-center",
  fontSize = "text-3xl",
}) => {
  return (
    <div className={`${className} mx-auto pt-5`}>
      <h2 className={`${fontSize} font-bold text-grey-text`}>{title}</h2>
    </div>
  )
}
