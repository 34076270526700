import { Link } from 'react-router-dom'
import { ContentLayout } from "../components/layouts/ContentLayout";
import { ContainerLayout } from "../components/layouts/ContainerLayout";
import { HeaderFooter } from '../components/layouts/HeaderFooter';



export const Application: React.FC = () => {
  return (
    <HeaderFooter>
      <ContainerLayout>
        <ContentLayout className="w-1/2 py-5">
          <h1 className="text-center text-3xl font-bold pb-5">みつばちファンド退会申請</h1>
          <div className="border p-5 text-center mt-3">
            <h2 className="font-semibold text-xl">このページは契約を解除するものではありません。</h2>
            <p className="text-sm text-grey-text opacity-80 pt-2">契約に関するお問い合わせは<Link to="" className="text-accent-main underline">こちら</Link>から承っております。</p>
          </div>
          <div className="mt-10">
            <p>退会すると…</p>
            <ul className="font-semibold">
              <li>・会員様先行公開のファンド情報が受け取れなくなる。</li>
              <li>・会員様限定のキャンペーンが適応されなくなる。</li>
              <li>・マイページで過去の配当金額、各種書面の確認ができなくなる。</li>
            </ul>
          </div>
          <div className="mt-10 mb-10 bg-grey-bg p-5">
            <p>山田太郎さまは、現在<span className="text-accent-main">5,000pt</span>ご利用可能です。</p>
            <p>退会すると全てのポイントが失効し、再取得不可となってしまいます。</p>
          </div>
          <p>退会せずメルマガ会員解除のみをご希望の方は<Link to="" className="text-accent-main underline">こちら</Link>から申請できます。</p>

          <p className="text-sm text-center mt-10">それでも退会しますか？</p>
          <div className="flex items-center justify-center ">
            <Link to="" className="grid items-center border-2 border-accent-main w-40 h-10 m-4 text-center text-accent-main rounded-md">いいえ</Link>
            <Link to="" className="grid items-center bg-accent-main w-40 h-10 m-4 text-center text-primary-text rounded-md">退会申請に進む</Link>
          </div>
        </ContentLayout>
      </ContainerLayout>
    </HeaderFooter>
  );
};