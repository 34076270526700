import React from 'react';
import BannerImage from '../../assets/bk-subpage.png'

interface HeaderProps {
  subTitle: string;
  title: string;
}

export const HeaderLayout: React.FC<HeaderProps> = ({
  subTitle,
  title
}) => {
  return (
    <div className="h-40 w-full relative">
      <img className="absolute h-40 w-full object-cover" src={BannerImage} alt="top banner" />
      <div className="relative container mx-auto px-2 h-full flex flex-col justify-center xl:w-[1280px]">
        <span className="text-primary-light text-lg">{subTitle}</span>
        <h2 className="text-3xl font-bold text-secondary-main">{title}</h2>
      </div>
    </div>
  )
}
