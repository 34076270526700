import React from 'react';
import { Clock } from '../../icons/Clock';
import { Pencil } from '../../icons/Pencil';
import { ArticleSingleProps } from './article';
import noImage from '../../../assets/noimage.jpg'



export const ArticleTitle: React.FC<ArticleSingleProps> = ({
  title,
  category_name,
  tags,
  media_url = noImage,
  created_date,
  updated_date,
  children
}) => {
  return (
    <div className="my-5 sm:my-0">
      {category_name ? (<span className=" bg-grey-bg px-2 py-1 rounded text-sm">{category_name}</span>) : null}
      {tags ? (<span className=" ml-5 text-sm">#{tags}</span>) : null}
      <div className="flex border-b-4 border-grey-boarder pb-5 mt-1">
        <img src={media_url} alt={title} className="h-28 w-28 object-cover" />
        <div className="w-full ml-4 flex flex-col justify-between">
          <h2 className="mb-1 text-2xl font-bold">{title}</h2>
          <time className="flex flex-cols justify-end items-center  text-sm text-right">
            {updated_date ? (
              <>
                <Pencil className="mx-2 w-4 h-4" />
                {updated_date}
              </>
            ) : null}
            {created_date ? (
              <span className="flex items-center">
                <Clock className="mx-2 w-4 h-4" />
                {created_date}
              </span>
            ) : null}
          </time>
        </div>
      </div>
      {children}
    </div>
  );
}