import { CategoryListType, PostListType } from "../../../store/post";
import BaseService from "../base";

const API_URL = process.env.REACT_APP_GATEWAY_URL;

class CategoryService extends BaseService{
  getCategoryList(postType: "blog" | "news") {
    return this.axios.get<CategoryListType[]>(API_URL + `/api/v1/user/posts/category/${postType}/`)
      .then<CategoryListType[]>((res) => res.data)
      .catch((res) => { throw res; });
  }
  getCategoryDetail(postType: "blog" | "news", slug: string) {
    return this.axios.get<PostListType[]>(API_URL + `/api/v1/user/posts/category/${postType}/${slug}`)
      .then<PostListType[]>((res) => res.data)
      .catch((res) => { throw res; });
  }
}
export default CategoryService;
