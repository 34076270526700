import React from "react";
import {DECREMENT_CURRENT_STEP, INCREMENT_CURRENT_STEP, SET_STEPS} from "./constants";

export interface StepperStepsProps {
  id: string | number;
  name: string;
  children: React.ReactNode;
}

export type StepperState = {
	steps: StepperStepsProps[];
	currentStep: number;
}

export type ActionType =
  | { type: typeof SET_STEPS; payload: { steps: StepperStepsProps[] }; }
  | { type: typeof INCREMENT_CURRENT_STEP}
  | { type: typeof DECREMENT_CURRENT_STEP};

export const defaultStepperState:StepperState = {
	steps: [],
	currentStep: 0
};


export const stepperReducer = (state:StepperState = defaultStepperState, action:ActionType) => {
  const { currentStep, steps } = state;
	switch (action.type) {
		case SET_STEPS:
			return {
				...state,
				steps: action.payload.steps
			};
		case INCREMENT_CURRENT_STEP:
			return {
				...state,
				currentStep:
					currentStep < steps.length - 1
						? currentStep + 1
						: currentStep
			};
		case DECREMENT_CURRENT_STEP:
			return {
				...state,
				currentStep:
					currentStep > 0
						? currentStep - 1
						: currentStep
			};
		default:
			return state;
	}
};
