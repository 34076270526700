type FastapiErrorType = {
	loc: string[],
	msg: string,
	type: string
}

export type FastapiErrorT = {
	detail: FastapiErrorType[] | string | undefined;
};

export const errorConverter = (errors: FastapiErrorType[] | string | undefined, field: string) => {
	/*
	This function will convert fastapi errors of field to string array.
	from:
		[
			"loc": ["body", "email"],
			"msg": "Field required",
			"type": "value_error.missing"
		]
	to:
		["Field required"]
	*/
	let errorList:string[] = [];
	if (!Array.isArray(errors)){
		return []
	}
	try {
		errors.forEach(value => {
		if (value.loc.includes(field)){
				errorList.push(value.msg);
			}
		});
	} catch {
		return []
	}
	return errorList;
}