import React from 'react';
import { Link } from 'react-router-dom'
import NoImage from '../../../assets/noimage.jpg'
import { RightArrow } from '../../icons/RightArrow';
import { PostTitle } from '../../../store/post';


interface SidebarCart {
  title: string
  children: React.ReactNode;
}

export function truncate(str: string | undefined, maxLength: number): string {
  if (str && str.length > maxLength) {
    return str.substring(0, maxLength - 3) + '...';
  }
  return str || '';
}

export const SidebarLatestFund: React.FC<{ image: string; title?: string; }> = ({ image, title }) => {
  return (
    <div>
      <h3 className="bg-white text-2xl font-bold mb-2">{title}</h3>
      <figure className=" relative w-full border border-grey-boarder">
        <img className="relative h-72 object-cover w-full" src={image} alt="" />
        <div className="absolute top-0 left-0 ml-3 mt-3 text-xs">
          <span className="bg-accent-light px-2 py-1 mr-2 text-accent-main rounded-sm">募集前</span>
          <span className="px-2 py-1 rounded-sm bg-white">抽選</span>
        </div>
      </figure>
    </div>
  );
};

export const SidebarTitle: React.FC<SidebarCart> = ({
  title,
  children
}) => {
  return (
    <div className="flex flex-col mt-4">
      <h4 className="font-semibold text-lg bg-grey-boarder p-2 mt-3 rounded">
        {title}
      </h4>
      {children}
    </div>
  );
};

export const SidebarBlogList: React.FC<PostTitle> = ({
  title = '',
  slug = '',
  media_url = NoImage,
}) => {
  return (
    <ul>
      <li className="border-b-2 py-3">
        <Link to={slug}>
          <article className="flex justify-between align-top">
            <p className="text-norma">{truncate(title, 50)}</p>
            <img className="h-24 w-24 border object-cover" src={media_url} alt="" />
          </article>
        </Link>
      </li>
    </ul>
  );
};

export const SidebarCategoryList: React.FC<{ name?: string | '', slug?: string | '' }> = ({
  name,
  slug
}) => {
  return (
    <div className="border-b-2 py-3 w-full">
      <a href={`/blog/category/${slug}`} className="flex content-center w-full items-center justify-between">
        {name} <RightArrow className="font-bold h-5 w-5 justify-self-center" />
      </a>
    </div>
  );
};

export const SidebarFundLink: React.FC<{ title: string, slug: string }> = ({
  title,
  slug
}) => {
  return (
    <Link to={slug}>
      <div className="p-2 mt-2 w-full font-semibold bg-grey-bg rounded ">
        {title}
      </div>
    </Link>
  );
};

export const SidebarFundRecord: React.FC<{ title: string, slug: string }> = ({
  title,
  slug
}) => {
  return (
    <div className="border-b-2 py-3 w-full">
      <Link to={slug} className="flex content-center w-full items-center justify-between">
        {title} <RightArrow className="font-bold h-5 w-5 justify-self-center" />
      </Link>
    </div>
  );
};
