
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Breadcrumb } from '../../components/ui/Breadcurmb';
import { ContentLayout } from "../../components/layouts/ContentLayout";
import { ContainerLayout } from "../../components/layouts/ContainerLayout";
import NoImage from '../../assets/noimage.jpg'
import { ArticleContain } from '../../components/ui/blogs/ArticleContain';
import { PrevNext } from '../../components/ui/pagination/PrevNext'
import { ReportSidebar } from './reportSidebar';
import { MainContainerLayout } from '../../components/layouts/MainContainerLayout';
import PostService from '../../infrastructure/api/posts/post';
import { PostType } from '../../store/post';
import useAxios from "../../hooks/useAxios";

const breadcrumbItems = [
  { url: '/', name: 'HOME'},
  { url: '/report', name: '里帰りインフォメーション' },
  { url: '', name: 'みつばちファンド1周年記念！100万円以上の出資で《Amazonギフト券5, 000円分》プレゼント' },
];

const BlogData = [
  { status: "ファンド名", rDate: "2024.1.1", title: "みつばちファンド1周年記念！100万円以上の出資で《Amazonギフト券5,000円分》プレゼント", image: NoImage, slug: '' },
  { status: "ファンド名", rDate: "2024.1.1", title: "みつばちファンド1周年記念！100万円以上の出資で《Amazonギフト券5,000円分》プレゼント", image: NoImage, slug: '' },
  { status: "ファンド名", rDate: "2024.1.1", title: "みつばちファンド1周年記念！100万円以上の出資で《Amazonギフト券5,000円分》プレゼント", image: NoImage, slug: '' }
]
export const ReportDetail: React.FC = () => {
  const { slug } = useParams();
  const currentIndex = 1;
  const [currentPost, setCurrentPost] = useState<PostType>();
  const previousPost = BlogData[currentIndex - 1];
  const nextPost = BlogData[currentIndex + 1];
  const postService = new PostService(useAxios());

  useEffect(() => {
    if (slug) {
      postService
        .getPost("blog", slug)
        .then((data) => { setCurrentPost(data) })
        .catch(() => {
          // TODO: redirect to 404 page
        });
    }
  }, [slug]);
  
  console.log(currentPost);
  return (
    <MainContainerLayout>
      <Breadcrumb items={breadcrumbItems} />
      <ContainerLayout>
        <ContentLayout className="grid grid-cols-1 auto-cols-max sm:grid-cols-2 sm:gap-8 md:gap-12 md:grid-flow-col lg:gap-18">
          <div className="col-span-10">
            <ArticleContain key={currentIndex} {...currentPost} />
            <PrevNext previousPost={previousPost} nextPost={nextPost} />
          </div>
          <ReportSidebar />
        </ContentLayout>
      </ContainerLayout>
    </MainContainerLayout>
  );
};
